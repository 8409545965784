@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsSettings.scss";

.general {
  @include settingsSDS;
}

.wrapInfoBtn {
  @include wrapInfoBtnSDS;
}

.warnignMsg {
  @include warnignMsgSDS;
}

.infoBtnGroup {
  @include infoBtnGroupSDS;
}

.settings {
  @include sectionsSDS;
}

.notAvailable {
  opacity: 0.7;
  pointer-events: none;
}

.disabledMsg {
  width: 100%;
  height: 1.5rem;
  font-size: var(--font-sm);
  line-height: 1.5rem;
  color: var(--accent-d30);
  background: var(--accent-l35);
  padding: 0 0.5rem;
  margin: 0.5rem 0 0 0;
  display: flex;
  align-items: center;
}

.paidOptions {
  width: 100%;
  height: 1.5rem;
  font-size: var(--font-sm);
  line-height: 1.5rem;
  color: var(--accent-d30);
  background: var(--accent-l35);
  padding: 0 0.5rem;
  margin: 0.5rem 0 0 0;
  display: flex;
  align-items: center;
  &:before {
    content: "\2217";
    font-size: var(--font-xxl);
    font-weight: var(--bold);
    color: var(--accent);
    margin: 0 0.25rem 0.125rem 0;
  }
}
