@import "app/assets/stylesheets/_mixins.scss";

.postmaster {
  justify-content: center;
}

.wrapNamePostmaster {
  width: 100%;
}

.wrapQuotaPostmaster {
  width: 100%;
}

.wrapData {
  height: 2rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 0.5rem;
  align-items: center;
}

.label {
  font-size: var(--font-sm);
  line-height: 2rem;
  color: var(--text-a40);
}

.data {
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  line-height: 2rem;
  color: var(--text);
}

.quota {
  display: flex;
  align-items: center;
}

.percentage {
  margin: 0 0.5rem 0 0;

  &::after {
    content: "\0025";
    font-size: var(--font-xs);
  }
}

//====

// .description {
//   padding-top: 20px;
// }
//
// .info {
//   display: inline-block;
//   margin-right: 60px;
//   width: 50%;
//
//   &:last-child {
//     margin-right: 0px;
//   }
//
//   & > span {
//     display: block;
//
//     &:first-child {
//       text-transform: uppercase;
//       font-size: 11px;
//       letter-spacing: 1px;
//       color: var(--text-a40);
//       margin-bottom: 7px;
//       text-align: right;
//     }
//
//     &:last-child {
//       font-size: 11px;
//       font-weight: 900;
//       letter-spacing: 1px;
//       color: var(--text-a60);
//       text-align: right;
//     }
//   }
// }
//
// .infoContainer {
//   // float: right;
//   padding-top: 40px;
//
//   .info {
//     display: inline-block;
//     margin-right: 0px;
//   }
// }
