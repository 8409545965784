@import "app/assets/stylesheets/_mixins.scss";

.wrapBadge {
  display: flex;
  justify-content: center;
  position: relative;
}

.badge {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.alert:before {
    background: var(--yellow);
    border-radius: 50px;
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    right: -2px;
    width: 10px;
    height: 10px;
  }

  &.yellow {
    background: var(--yellow);
  }

  &.green {
    background: var(--green);
  }

  &.red {
    background: var(--red);
  }

  &.blue {
    background: var(--accent);
  }
}

.syncIcon {
  animation: rotating 1.5s linear infinite;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg * {
    stroke: var(--white);
  }
}

.warningIcon {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background: var(--yellow);
  position: absolute;
  right: -0.125rem;
  top: -0.125rem;

  .preview & {
    width: 0.4375rem;
    height: 0.4375rem;
    right: -1px;
    top: -1px;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.label {
  margin-left: 20px;
}
