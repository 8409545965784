@import "app/assets/stylesheets/_mixins.scss";

.folderMapper {
  border: 1px solid var(--input-border);
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: [destinations]1.5rem [foldersList]12rem;

  .withErrors & {
    border-color: var(--red);
  }
}

.row {
  padding: 0 0 0 0.5rem;
  display: grid;
  grid-template-columns: 1fr 3.5rem 3.5rem 3.5rem;
  align-items: center;
}

.destinations {
  grid-row: destinations;
  font-size: var(--font-xs);
  font-weight: var(--semi-bold);
  border-bottom: 1px solid var(--bg-d5);
  border-radius: 0.25rem 0.25rem 0 0;
  box-sizing: border-box;
  background: var(--bg);
}

.labelDestination {
  justify-self: center;
}

.foldersList {
  grid-row: foldersList;
  overflow-y: auto;
}

.folder {
  font-size: var(--font-sm);
  line-height: 2rem;
}

.firstLine {
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
}

.radioInput {
  justify-self: center;
  width: 1.25rem;
  height: 1.25rem;
  background: var(--white);
  border: 1px solid var(--input-border-hover);
  border-radius: 50%;
  position: relative;
  outline: none;
  cursor: pointer;
  appearance: none;
  .disabled & {
    cursor: default;
  }
}

.radioInput:checked {
  border: 1px solid var(--accent);

  &::after {
    content: "";
    width: 0.75rem;
    height: 0.75rem;
    background: var(--accent);
    border-radius: 100%;
    position: absolute;
    top: 3px;
    left: 3px;
    animation: checking 0.15s linear;
  }
}

.errorMsg {
  @include inputErrorMsg;
}

.loaderIcon {
  grid-row: 1 / 3;
  justify-self: center;
  align-self: center;
}
