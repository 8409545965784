@import "app/assets/stylesheets/_mixins.scss";

.card {
  height: 8rem;
  //border: 1px solid var(--bg-d5);
  border-radius: 0.5rem;
  box-sizing: border-box;
  box-shadow: var(--shadow-dp4);
  background: var(--white);
  padding: 0.75rem 0.5rem;
  display: grid;
  grid-template-columns: 1.5rem 1fr 2rem;
  grid-template-rows: 1.5rem auto;
  grid-template-areas: "status name ddMenu" ". info info";
  grid-column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}

.statusCircle {
  grid-area: status;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: var(--bg-d10);
  display: flex;
  justify-content: center;
  align-items: center;

  &.enabled {
    background: var(--green);
  }
  &.disabled {
    background: var(--yellow);
  }
}

.iconUser {
  svg * {
    stroke: var(--white);
  }
}

.name {
  grid-area: name;
  font-size: var(--font-lg);
  font-weight: var(--semi-bold);
  color: var(--text);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ddMenu {
  grid-area: ddMenu;
}

.info {
  grid-area: info;
  align-self: start;
  padding: 0.25rem 0 0 0;
  display: flex;
  flex-direction: column;
}

.detailInfo {
  grid-template-columns: 3.5rem auto;
}
