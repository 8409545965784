@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsList.scss";

.noFoundMsg {
  @include noFoundMsg;
  align-items: flex-start;
}

.wrapIllustration {
  @include wrapIllustration;
}

.msgIllustration {
  @include msgIllustration;
}

.descIllustration {
  @include descIllustration;
}