@import "app/assets/stylesheets/_mixins.scss";

.ownershipCheckPage {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  overflow-y: auto;

  @include media-sm {
    grid-template-rows: minmax(0px, 1fr);
  }
}

.ownershipCheck {
  justify-self: center;
  padding: 1rem 1rem 0 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "title" "content";
  justify-content: center;
  @include media-sm {
    width: 30rem;
    grid-template-rows: auto minmax(0px, 1fr);
  }
}

.title {
  grid-area: title;
  padding: 0 0 1rem 0;
}

.content {
  grid-area: content;
}

.tabs {
  & > div:first-child {
    margin: 0;
    div {
      padding: 0;
    }
  }
}
