@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsShow.scss";

.downloadFileLink {
  font-size: 0.875rem !important;
  text-align: right;
  cursor: pointer;
}

.resourceMigrations {
  @include listDS;
}

// navigation
.navigation {
  @include navigationDS;
}

.viewSectionLabel {
  @include viewSection;
}

//header
.header {
  @include headerDS;
}

.wrapTitle {
  @include wrapTitleDS;
}

.info {
  @include infoDS;
}

.detailInfo {
  margin: 0 0 0 1rem;
}

.warningBanner {
  padding: 1rem;

  @include media-xxl {
    padding: 0 4rem;
  }
}

.settings {
  @include settingsDS;
  display: flex;
  flex-direction: column;
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-template-rows: auto minmax(0px, 1fr);
  // grid-template-areas: "filters" "table";

  // @include media-sm {
  //   grid-template-rows: 4rem minmax(0px, 1fr);
  //   overflow-y: hidden;
  // }

  // @include media-lg {
  //   grid-template-rows: 4rem minmax(0px, 1fr);
  // }

  overflow-y: scroll;
}

.filters {
  padding: 1rem;
  margin-bottom: 1rem;

  @include media-xxl {
    padding: 0 4rem;
  }
}

.table {
  padding: 0 1rem;

  @include media-sm {
    @include overflowY;
  }

  @include media-xxl {
    padding: 0 4rem;
  }
}

.commandsBox {
  background-color: var(--bg);
}

.commandsBoxContent {
  padding: 0.5rem 0.5rem;
}

.commandsBoxLabel {
  font-size: var(--font-sm);
  margin-bottom: 1rem;
  font-weight: var(--semi-bold);
}

// .runRow {
//   width: 100%;
//   display: flex;
//   gap: 2rem;
//   // justify-content: space-between;
//   align-items: center;
//   padding: 1rem 0;
// }

/* new layout */
.migrationList {
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
  gap: 0.5rem;
}

.resource {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
}

.runs {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.runsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.label {
  font-size: var(--font-sm);
  color: var(--text-a40);
}

.value {
  font-weight: var(--semi-bold);
  color: var(--text);
  font-size: var(--font-sm);
}

.arrow {
  color: var(--accent);
}

.users {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.testLoginTag,
.runningTag {
  font-size: var(--font-sm);

  border-radius: var(--radius-4);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  // padding: 0.5rem 0.75rem;
  width: 100%;
}

.tagSuccess {
  color: var(--green);
}

.tagError {
  color: var(--red);
}

.tagProgress {
  color: var(--accent);
}

.tagScheduled {
  // background-color: var(--bg);
  color: var(--text-a60);
}

.runDetailsBtn {
  padding: 2px 4px;
  font-size: var(--font-xs);
  border-radius: 2px;

  background-color: var(--bg);
  color: var(--text-a60);

  cursor: pointer;
}

.syncIcon {
  animation: rotating 1.5s linear infinite;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg * {
    stroke: var(--accent);
  }
}

.runLog {
  cursor: pointer;
  flex-shrink: 1;
}

.runStatus {
  display: flex;
  justify-content: flex-start;

  text-transform: uppercase;
  font-size: var(--font-xs);
  font-weight: var(--semi-bold);
}

.accentArrow {
  color: var(--accent);
}

.host {
  color: var(--text-a40);
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
