@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsSettings.scss";

.general {
	@include settingsSDS;
}

.wrapInfoBtn {
	@include wrapInfoBtnSDS;
}

.warnignMsg {
	@include warnignMsgSDS;
}

.infoBtnGroup {
	@include infoBtnGroupSDS;
}

.settings {
	@include sectionsSDS;
}
