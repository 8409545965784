@import "app/assets/stylesheets/_mixins.scss";

.domainOrderBox {
  padding: 1rem;
  background: var(--bg);
  display: flex;
  flex-direction: column;
}

.nameDomain {
  margin: 0 0 1rem 0;
}

.planAnnual {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 2rem 0;
}

.wrapLabelPlaneAnnual {
  @include inputWrapLabel;
}

.labelPlaneAnnual {
  @include inputLabel;
}

.helpTooltip {
  @include inputHelpTooltip;
}

.planSelector {
  border: 1px solid var(--input-border);
  border-radius: 2px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 2.5rem;
  align-items: center;

  @include media-md {
    grid-template-rows: 2rem;
  }

  & > div {
    height: 100%;
    font-size: var(--font-sm);
    color: var(--text);
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.selected {
      color: var(--white);
      background: var(--accent);
    }

    &.disabled {
      background: var(--bg-d5);
      opacity: 0.5;
      pointer-events: none;
    }
  }

  & > div:nth-child(2) {
    border-left: 1px solid var(--input-border);
    border-right: 1px solid var(--input-border);
  }
}

// counter box
.wrapCounter {
  margin: 0 0 1rem 0;
  display: grid;
  grid-template-columns: 1fr 8.5rem;
  grid-template-rows: minmax(2rem, auto);
  grid-template-areas: "wrapLabelCounter wrapInputCounter";
  align-items: center;
}

.wrapLabelCounter {
  display: flex;
  flex-direction: column;
}

.labelCounter {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--text);
}

.hintLabelCounter {
  font-size: var(--font-xs);
  font-weight: var(--regular);
  line-height: 1.125rem;
  color: var(--text-a40);
}

.wrapInputCounter {
  justify-self: end;
}

.dataCounter {
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  color: var(--text);
  text-transform: capitalize;
}

.resourceCounter {
  @include input;
  box-sizing: border-box;
  padding: 0;
  display: grid;
  grid-template-columns: 2.5rem 3.5rem 2.5rem;
  align-items: center;

  input {
    width: 100%;
    height: 100%;
    text-align: center;
    border: none;
    background: none;
    appearance: none;
    outline: none;
  }
}

.btnResourceCounter {
  justify-self: center;
}

// paid options
.paidOptions {
  margin: 0.5rem 0 1rem 0;
}

.labelPaidOptions {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.125rem;
  color: var(--text-a40);
}

// estimate total prize
.estimate {
  font-size: var(--font-lg);
  font-weight: var(--semi-bold);
  line-height: 1.5rem;
  border-top: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: 0.5rem 0 0 0;
  margin: 1rem 0 0.5rem 0;
  display: grid;
  grid-template-columns: 1fr 8.5rem;
  align-items: center;
}

.totalEstimate {
  justify-self: end;
  font-size: var(--font-lg);
}

.infoEstimate {
  font-size: var(--font-sm);
  color: var(--text-a40);
  margin: 0 0 0.5rem 0;

  b {
    font-weight: var(--semi-bold);
  }

  &:before {
    content: "\002A";
    margin: 0 0.125rem 0 0;
  }
}

.deleteDomainBtn {
  align-self: flex-end;
  font-size: var(--font-base);
  color: var(--red);
  line-height: 1.5rem;
  text-decoration: underline;
  transition: color 0.2s ease-in-out;
  cursor: pointer;

  @include media-sm {
    font-size: var(--font-sm);
  }

  &:hover {
    color: var(--red-d10);
  }
}

.wrapAddDomainBtn {
  display: flex;
  align-items: center;
}

.addDomainBtn {
  min-width: 100%;
  @include media-sm {
    min-width: 6rem;
  }
}

.infoAddDomina {
  font-size: var(--font-sm);
  color: var(--text-a40);
  margin: 0 0 0 1rem;
}
