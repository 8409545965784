@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsShow.scss";

.resourceImport {
  @include listDS;
}

// navigation
.navigation {
  @include navigationDS;
}

.viewSectionLabel {
  @include viewSection;
}

//header
.header {
  @include headerDS;
}

.wrapTitle {
  @include wrapTitleDS;
}

.info {
  @include infoDS;
}

.detailInfo {
  margin: 0 0 0 1rem;
}

.settings {
  @include settingsDS;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto minmax(0px, 1fr);
  grid-template-areas: "filters" "table";
  @include overflowY;

  @include media-sm {
    grid-template-rows: 4rem minmax(0px, 1fr);
    overflow-y: hidden;
  }

  @include media-lg {
    grid-template-rows: 4rem minmax(0px, 1fr);
  }
}

.filters {
  grid-area: filters;
  padding: 1rem;

  @include media-xxl {
    padding: 1rem 4rem;
  }
}

.table {
  grid-area: table;
  align-content: flex-start;
  padding: 0 1rem;

  @include media-sm {
    @include overflowY;
  }

  @include media-xxl {
    padding: 0 4rem;
  }
}
