@import "app/assets/stylesheets/_mixins.scss";

:global {
  .Toastify__toast {
    min-height: auto !important;
    padding: 0 !important;
    margin: 0 0 0.25rem 0 !important;
    box-shadow: var(--shadow-dp8) !important;
    background: none !important;
  }

  .Toastify__toast-container {
    background: none !important;
    margin: 0 !important;
    padding: 0 1rem !important;
    z-index: var(--z-index-top) !important;

    @include media-sm {
      padding: 0 !important;
    }
  }

  .Toastify__toast-container--top-right {
    top: 1rem !important;
    right: 0 !important;
    @include media-sm {
      top: 3.5rem !important;
      right: 1rem !important;
    }
  }

  .Toastify__toast-container--bottom-left {
    bottom: 1em !important;
    left: 0 !important;

    @include media-sm {
      bottom: 1em !important;
      left: 1em !important;
    }
  }

  .Toastify__toast--default {
    background: none !important;
    border-radius: 1px !important;
    margin: 0 0 0.5rem 0 !important;
    padding: 0 !important;
  }

  .Toastify__progress-bar {
    height: 0 !important;
  }

  .Toastify__progress-bar--default {
    background: none !important;
    // opacity: 0.35 !important;
  }
}

.toastCustom {
  background: var(--white);
  margin: 0;

  &.success {
    background: var(--green);
  }

  &.error {
    background: var(--red);
  }

  &.info {
    background: var(--accent);
  }
}

.wrapToast {
  min-height: 2.5rem;
  box-sizing: border-box;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

.toastIcon {
  margin: 0 0.5rem 0 0;

  svg * {
    fill: var(--white);
  }
}

.label {
  flex: 1;
  font-family: var(--font-family);
  font-size: var(--font-sm);
  font-weight: var(--regular);
  // color: var(--text);
  color: var(--white) !important;
  line-height: 1.25;
}

.closeIcon {
  margin: 1px 0 0 0;
}
