@import "app/assets/stylesheets/_mixins.scss";

$formsWhidth: 30rem;

.formsPage {
  padding: 1rem 0 0 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto minmax(2rem, auto) minmax(0px, 1fr);
  grid-template-areas: "stepsFP" "titleFP" "wrapScrollFP";
  justify-content: center;
  @include overflowY;
}

// user for order step pages
.orderPage {
  padding: 1rem 0 0 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto minmax(2rem, auto) minmax(0px, 1fr);
  grid-template-areas: "stepsFP" "titleFP" "wrapScrollFP";
  justify-content: center;
  @include overflowY;

  @include media-lg {
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: ". stepsFP supportBox" ". titleFP supportBox" "wrapScrollFP wrapScrollFP wrapScrollFP";
  }
}

.stepsFP {
  grid-area: stepsFP;
  justify-self: center;

  @include media-sm {
    width: $formsWhidth;
  }
}

.titleFP {
  grid-area: titleFP;
  align-self: center;
  padding: 0 1rem;

  @include media-sm {
    justify-self: center;
    width: $formsWhidth;
    padding: 0;
  }
}

.wrapScrollFP {
  grid-area: wrapScrollFP;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto minmax(0px, 1fr);
  grid-template-areas: "infoFP" "wrapFormsFP";

  @include media-sm {
    @include overflowY;
  }
}

.infoFP {
  grid-area: infoFP;
  padding: 0 1rem;
  margin: 0 0 2rem 0;
  display: flex;
  flex-direction: column;
  @include media-sm {
    justify-self: center;
    width: $formsWhidth;
    padding: 0;
  }
}

.wrapBtnFP {
  display: flex;
  align-items: flex-end;
}

.btnDocsFP {
  margin: 1rem 1rem 0 0;
}

.btnFileFP {
  height: 1.75rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  text-decoration: underline;
  color: var(--accent);
  line-height: 1.75rem;
  margin: 1rem 0 0 0;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    color: var(--accent-d30);
  }
}

.wrapFormsFP {
  grid-area: wrapFormsFP;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr minmax(2rem, auto) 5.5rem;
  grid-template-areas: "formsFP" "warningMsgFP" "footerFP";
  justify-content: center;
  grid-row-gap: 0.5rem;

  @include media-sm {
    padding: 0;
    grid-template-columns: $formsWhidth;
  }

  @include media-md {
    grid-template-rows: 1fr minmax(3rem, auto) 4.5rem;
  }
}

.formsFP {
  grid-area: formsFP;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  align-content: flex-start;

  @include media-sm {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
  }
}

.warningMsgFP {
  grid-area: warningMsgFP;
  align-self: center;
  width: 100%;
  // padding: 0 1rem;
  // margin: 0.5rem 0;

  @include media-sm {
    width: $formsWhidth;
    padding: 0;
  }
}

.footerFP {
  margin-top: 20px;
  grid-area: footerFP;
  height: 5.5rem;
  border-top: 1px solid var(--bg-d5);
  padding: 0 0 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  button {
    min-width: calc(50% - 0.5rem);
  }

  @include media-sm {
    width: $formsWhidth;
    padding: 0 0 2rem 0;
    justify-content: flex-end;

    button {
      min-width: 5rem;

      &:last-of-type {
        margin: 0 0 0 0.5rem;
      }
    }
  }

  @include media-md {
    height: 4.5rem;
  }
}

.supportBox {
  grid-area: supportBox / supportBox / wrapFormsFP / wrapFormsFP;
  justify-self: end;
  margin: 0 1rem 0 0;
}
