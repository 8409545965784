@import "app/assets/stylesheets/_mixins.scss";

.sidebarItem {
  width: 100%;
  height: 3rem;
  font-size: var(--font-base);
  color: var(--text);
  border-left: 0.25rem solid transparent;
  box-sizing: border-box;
  padding: 0 0.5rem 0 0.75rem;
  display: grid;
  grid-template-columns: [iconItem]1rem [label]max-content [newTag]1fr;
  grid-column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;

  @include media-md {
    height: 2.25rem;
    font-size: var(--font-sm);
  }

  &.compact {
    padding: 0 0 0 0.75rem;
    grid-template-columns: [iconItem]1rem;
    grid-column-gap: 0;

    .iconNew {
      display: none;
    }
  }

  &.selected {
    border-left-color: var(--accent);
    background: var(--accent-l35);
  }
  &:hover {
    background: var(--bg-hover);
  }
}

.iconItem {
  grid-column: iconItem;
}

.iconNew {
  justify-self: start;
  grid-column: newTag;
  font-size: var(--font-xs);
  font-weight: var(--semi-bold);
  color: var(--white);
  padding-left: 2px;
  padding-right: 2px;
  background-color: var(--green);
  border-radius: 5px;
  width: max-content;
}
