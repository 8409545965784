@import "../input/input.scss";

.inputContainer {
  @include inputContainer;
}

.wrapLabel {
  @include inputWrapLabel;
}

.label {
  @include inputLabel;
}

.hint {
  @include inputHint;
}

.input {
  @include input;
  min-height: 10rem;
  padding: 0.375rem;
  line-height: 1.25rem !important;
  resize: vertical;
  overflow-y: auto;
  .onlyRead & {
    cursor: default;
    &:hover {
      border: 1px solid var(--input-border);
    }
    &:focus {
      border: 1px solid var(--input-border);
    }
  }
}
