.bill {
  display: flex;
  flex-direction: column;
}

.headerService {
  background: var(--bg);
}

.rowService {
  height: 2rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--text);
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: 0 0.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: "nameService dataService";
  grid-column-gap: 0.5rem;
  align-items: center;
}

.nameService {
  grid-area: nameService;

  .headerService & {
    font-weight: var(--semi-bold);
  }
}

.dataService {
  grid-area: dataService;
  display: flex;
  justify-content: flex-end;
}

.serviceValue {
  font-weight: var(--semi-bold);
  text-transform: capitalize;
}

.priceService {
  min-width: 4rem;
  color: var(--text-a40);
  text-align: right;
  &:before {
    content: "x";
    margin: 0 0.25rem;
  }

  &:after {
    content: "€";
    margin: 0 0 0 0.25rem;
  }
}