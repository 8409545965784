@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsShow.scss";

.showArchive {
  @include listDS;
  height: calc(100vh - 3rem - #{var(--safe-bottom)}); // fallback
  height: calc(#{var(--fullVh)} - 3rem - #{var(--safe-bottom)});
  overflow: hidden;
}

// navigation
.navigation {
  @include navigationDS;
}

.viewSectionLabel {
  @include viewSection;
}

// header
.header {
  @include headerDS;
}

.wrapTitle {
  @include wrapTitleDS;
}

.subtitle {
  @include subtitleDS;
}

.info {
  @include infoDS;
}

.settings {
  @include settingsDS;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem 1fr;
  grid-template-areas: "tabs" "content";

  @include media-sm {
    grid-template-rows: 2.5rem minmax(0px, 1fr);
  }
}

.scrollTabs {
  grid-area: tabs;
  margin: 0 1rem;
  overflow-x: auto;
  overflow-x: overlay;
  -webkit-overflow-scrolling: touch;

  @include media-xxl {
    margin: 0 4rem;
  }
}

.tabs {
  height: 3rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .hideHeader & {
    display: none;
  }

  @include media-sm {
    height: 2.5rem;
    border-bottom: 1px solid var(--bg-d5);
    justify-content: flex-start;
  }
}

.itemTabs {
  flex: 1;
  height: 3rem;
  font-size: var(--font-base);
  line-height: 3rem;
  color: var(--text);
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-sm {
    flex: initial;
    height: 2.5rem;
    font-size: var(--font-sm);
    line-height: 2.5rem;
    border-bottom: 2px solid transparent;
    margin: 0 1rem 0 0;
    justify-content: flex-start;
    cursor: pointer;
    &:hover {
      color: var(--accent);
      .iconTabs svg * {
        stroke: var(--accent);
      }
    }
  }

  &.active {
    color: var(--accent);
    border-bottom-color: var(--accent);
  }
}

.iconTabs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0.25rem 0 0;
  .active & {
    svg * {
      stroke: var(--accent);
    }
  }
}

.settings > div:nth-of-type(2) {
  grid-area: content;
  justify-self: stretch;
  display: grid;
  @include overflowY;

  & > div:first-of-type {
    justify-self: stretch;
    @include overflowY;
  }
}
