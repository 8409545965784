@import "app/assets/stylesheets/_mixins.scss";

.webmailVersion {
  opacity: 0.7;
}

// single compontent style
.detailInfo {
  @include media-sm {
    grid-template-columns: 7.5rem auto;
  }
}

.detailInfoFull {
  @include media-md {
    grid-column: 1 / 3;
  }
}

// log structure
.wrapLog {
  border: 1px solid var(--bg-d5);
  box-sizing: border-box;
  margin: 0 0 2rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2rem auto auto;
  grid-template-areas: "headerLog" "contentLog" "deliveries";
  align-items: center;

  &.mailIn {
    grid-template-rows: 2rem auto auto auto;
    grid-template-areas: "headerLog" "contentLog" "attachLog" "deliveries";
    align-items: center;
  }

  &.mailOut {
    grid-template-rows: 2rem auto auto auto;
    grid-template-areas: "headerLog" "contentLog" "notificationLog" "deliveries";
    align-items: center;
  }
}

.btnWhere {
  grid-area: btnWhere;
  justify-self: end;
  font-size: var(--font-sm);
}

// header log
.headerLog {
  grid-area: headerLog;
  background: var(--bg);
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: 0 0.5rem;
  display: grid;
  grid-template-columns: 1rem 1fr 4rem 4rem;
  grid-template-areas: "statusCircle titleHeader btnWhere btnExport btnMore";
  grid-template-rows: 2rem;
  grid-column-gap: 0.5rem;
  align-items: center;

  @include media-sm {
    grid-template-columns: 1rem 1fr 10rem 6rem 6rem;
  }
}

.statusCircle {
  grid-area: statusCircle;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--green);

  .failed & {
    background: var(--red);
  }

  .warning & {
    background: var(--yellow);
  }
}

.titleHeader {
  grid-area: titleHeader;
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
}

.btnExport {
  grid-area: btnExport;
  justify-self: end;
  font-size: var(--font-sm);

  &.exporting {
    opacity: 0.6;
    pointer-events: none;
  }
}

.btnMore {
  grid-area: btnMore;
  justify-self: end;
  font-size: var(--font-sm);
}

// content log
.contentLog {
  grid-area: contentLog;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0.5rem;

  @include media-md {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.5rem;
  }
}

// attachemnts log
.attachLog {
  grid-area: attachLog;
}

// notification log
.notificationLog {
  grid-area: notificationLog;
}

// deliveries log
.deliveries {
  grid-area: deliveries;
}

// accordion
.accordion {
  border-top: 1px solid var(--bg-d5);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2rem auto;
  grid-template-areas: "headerAccordione" "contentAccordion";

  &.openAccordion {
    background: var(--bg);
  }
}

.headerAccordione {
  grid-area: headerAccordione;
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  background: var(--bg);
  padding: 0 0.5rem;
  display: grid;
  grid-template-columns: 1rem 1fr 1rem;
  grid-template-rows: 2rem;
  grid-column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: var(--bg-hover);
  }
}

.contentAccordion {
  grid-area: contentAccordion;
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 0.5rem;
}

// single attach
.singleAttachments {
  border-top: 1px solid var(--bg-d5);
  margin: 0 0.5rem 0 1.5rem;
  padding: 0.5rem 0;
  display: grid;
  grid-template-columns: 1fr 12rem;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;

  &:first-of-type {
    border-top: none;
  }
}

// single delivery
.singleDelivery {
  border-top: 1px solid var(--bg-d5);
  padding: 0.5rem 0;
  display: grid;
  grid-template-rows: 1.5rem 1fr;
  grid-template-areas: "statusDelivery" "infoDelivery";

  @include media-sm {
    grid-template-columns: 4rem 1fr;
    grid-template-rows: auto;
    grid-template-areas: "statusDelivery infoDelivery";
    grid-column-gap: 0.5rem;
  }

  &:first-of-type {
    border-top: none;
  }
}

.statusDelivery {
  grid-area: statusDelivery;
  height: 1rem;
  font-size: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.125em;
  color: var(--white);
  background: var(--green);
  border-radius: 2rem;
  margin: 0.25rem 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.red,
  &.failed {
    background: var(--red);
  }
  &.yellow,
  &.warning {
    color: var(--text);
    background: var(--yellow);
  }
}

.infoDelivery {
  grid-area: infoDelivery;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0.5rem;
  align-items: center;

  @include media-md {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.5rem;
  }

  .detailInfoFull {
    @include media-sm {
      grid-column: 1 / 2;
    }
  }
}

.infoFull2 {
  @include media-md {
    grid-column: 1 / 3;
  }
}

// single notification
.singleNotification {
  border-top: 1px solid var(--bg-d5);
  padding: 0.5rem 0;
  display: grid;
  grid-template-rows: 1.5rem 1fr;
  grid-template-areas: "statusNotification" "infoNotification";

  @include media-sm {
    grid-template-columns: 4rem 1fr;
    grid-template-rows: auto;
    grid-template-areas: "statusNotification infoNotification";
    grid-column-gap: 0.5rem;
  }

  &:first-of-type {
    border-top: none;
  }
}

.statusNotification {
  grid-area: statusNotification;
  height: 1rem;
  font-size: var(--font-xxs);
  text-transform: uppercase;
  letter-spacing: 0.125em;
  color: var(--text);
  background: var(--yellow);
  border-radius: 2rem;
  margin: 0.25rem 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.red {
    color: var(--white);
    background: var(--red);
  }
}

.infoNotification {
  grid-area: infoNotification;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0.5rem;
  align-items: center;

  @include media-md {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.5rem;
  }

  .singleDelivery {
    margin: 0;
  }
}

.titleSection {
  line-height: 2rem;
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  border-top: 1px solid var(--bg-d5);
}

.folderList {
  padding-left: 30px;
  list-style: disc;
}