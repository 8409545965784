@import "app/assets/stylesheets/_mixins.scss";

.justifyCenter {
  text-align: center !important;
}

.disabled td {
  opacity: 0.5;
  pointer-events: none;
}

tr td:last-child {
  opacity: 1;
  pointer-events: all;
}

// account quote bar
.quote {
  display: flex;
  align-items: center;
}

.percentage {
  width: 3rem;
  @include media-sm {
    width: 2rem;
    margin: 0 0.5rem 0 0;
  }

  &::after {
    content: "\0025";
    font-size: var(--font-xs);
  }
}

.giga {
  width: 5.25rem;
  margin: 0 0 0 0.5rem;
}
