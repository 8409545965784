.copyableItem {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.copyableItemInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
