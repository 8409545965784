@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsList.scss";

.listAdmins {
	@include listMSS;
}

// header
.headerList {
	@include headerListMSS;
}

.titleList {
	@include titleListMSS;
}

.descList {
	@include descListMSS;
}

.actionsList {
	@include actionsListMSS;
}

// content
.contentList {
	@include contentList;
}

// filters
.filters {
	@include filtersMSS;
}

// table
.table {
	@include tableCardMSS;
}

.loader {
	@include tableMSS;
}
