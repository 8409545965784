.backLink {
  color: var(--text-a60);
  height: 35px;
  font-size: var(--font-xs);
  line-height: 10px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }

  svg {
    top: -1px;
    position: relative;
  }

  :global {
    .icon {
      margin-right: 5px;
    }
  }
}
