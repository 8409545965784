@import "app/assets/stylesheets/_mixins.scss";

.repeater {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto;
  grid-template-areas: "wrapInput" "hint" "errorMsg" "wrapList";
}

.wrapInput {
  grid-area: wrapInput;
  margin: 0 0 1rem 0;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: "input addBtn";
  grid-column-gap: 0.5rem;
}

.input {
  grid-area: input;
}

.addBtn {
  margin: 1.5rem 0 0 0;
  grid-area: addBtn;
  justify-self: end;
}

.wrapList {
  grid-area: wrapList;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1.5rem minmax(0px, 1fr);
  grid-template-areas: "headerList" "contentList";
}

.headerList {
  grid-area: headerList;
  align-self: center;
  height: 1.5rem;
  font-size: var(--font-xs);
  font-weight: var(--semi-bold);
  color: var(--text);
  text-transform: uppercase;
  letter-spacing: 0.065em;
  line-height: 1.5rem;
  background: var(--bg);
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: 0 0 0 0.5rem;
}

.contentList {
  grid-area: contentList;
  display: flex;
  flex-direction: column;
}

.contentRow {
  height: 2rem;
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: 0 0 0 0.5rem;
  display: grid;
  grid-template-columns: [infoRow]1fr [actionsRow]auto;
  align-items: center;
}

.infoRow {
  grid-column: infoRow;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 2rem;
  color: var(--text);
}

.actionsRow {
  grid-column: actionsRow;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.emptyList {
  height: 2rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 2rem;
  color: var(--text-a40);
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: 0 0 0 0.5rem;
}
