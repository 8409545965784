@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsShow.scss";

.showAdmin {
	@include listDS;
}

// navigation
.navigation {
	@include navigationDS;
}

.viewSectionLabel {
	@include viewSection;
}

//header
.header {
	@include headerDS;
}

.wrapTitle {
	@include wrapTitleDS;
}

.subtitle {
	@include subtitleDS;
}

.info {
	@include infoDS;
}

.detailInfo {
	margin: 0 0 0 1rem;
}

.settings {
	@include settingsDS;
}
