@import "app/assets/stylesheets/_mixins.scss";

// dropzone
.wrapLabelDZ {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: 1.5rem auto;
  grid-template-areas: "labelDZ helpTooltipDZ" "hintDZ hintDZ";
  grid-column-gap: 0.25rem;
}

.labelDZ {
  grid-area: labelDZ;
  width: 100%;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text);
}

.helpTooltipDZ {
  grid-area: helpTooltipDZ;
}

.hintDZ {
  grid-area: hintDZ;
  font-size: var(--font-xs);
  font-weight: var(--regular);
  line-height: 1.125rem;
  color: var(--text-a40);
  padding: 0 0 0.25rem 0;
  @include codeAndBold;
}

// preview
.wrapPreview {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.labelPreview {
  height: 1.75rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text);
}

.wrapIllustration {
  @include wrapIllustration;
  grid-area: wrapFormsFP;
  align-self: start;
  padding: 0 1rem;
}

.linksImg {
  padding: 40px 0 80px;
  width: 100%;
}

.msgIllustration {
  @include msgIllustration;
}

.descIllustration {
  @include descIllustration;
}

.btnIllustrationMsg {
  margin: 2rem 0 0 0;
}
