@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsList.scss";

.reportList {
	@include listMSS;
}

.headerList {
	@include headerListMSS;

	@include media-md {
		grid-template-columns: 56rem 1fr !important;
	}
}

.titleList {
	@include titleListMSS;
}

.descList {
	display: none;

	@include media-sm {
		display: initial;
		@include descListMSS;
	}
}

.contentList {
	@include contentList;
}

.filters {
	@include filtersMSS;
}

.table {
	@include tableCardMSS;
}
