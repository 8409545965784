@import "app/assets/stylesheets/_mixins.scss";

.sectionTitle {
  font-size: var(--font-xl);
  font-weight: var(--semi-bold);
  line-height: 2rem;
  color: var(--text);
}

.sectionDesc {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text-a40);
  max-width: 120ch;
  @include codeAndBold;
}

.sectionInfo {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text);
}

.settingTitle {
  font-size: var(--font-lg);
  font-weight: var(--semi-bold);
  line-height: 1.25rem;
  color: var(--text);
  box-sizing: border-box;
  padding: 0 0 0.75rem 0;
}

.settingSubTitle {
  margin: 0 0 0.5rem 0;
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  line-height: 1.25rem;
  color: var(--text);
  box-sizing: border-box;

  &.disabled {
    opacity: 0.5;
  }
}

.settingText {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text-a60);
  @include codeAndBold;

  &.disabled {
    opacity: 0.5;
  }
}

.internalLink {
  font-size: var(--font-base);
  color: var(--accent);
  text-decoration: underline;
  transition: color 0.2s ease-in-out;
  cursor: pointer;

  @include media-sm {
    font-size: var(--font-sm);
  }
  &:hover {
    color: var(--accent-d30);
  }
}

.noUnderlineLink {
  font-size: var(--font-base);
  color: var(--accent);
  cursor: pointer;
}
