@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsSettings.scss";

.container {
  background-color: var(--accent-l35);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.25rem;
  padding: 1.25rem;
}

.horizontalContainer {
  flex-direction: row;
}

.customTierMsg {
  font-size: var(--font-sm);
}

.tierMinMsg {
  margin-top: 0.75rem;
  opacity: 0.6;
}

.stars {
  margin-bottom: 0.5rem;
}

.msg {
  text-align: center;
  color: #666666;
}

.lockedProgress {
  width: 6rem;
  height: 6rem;
}

.isCompleted {
  width: 6rem;
  height: 6rem;
}

.focusedProgress {
  width: 9rem;
  height: 9rem;
}

.rangeLabel {
  margin-top: 0.25rem;
  color: #666666;
  font-size: var(--font-sm);
}

.progressContainer {
  margin-top: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 25rem;
  width: 100%;
}

.missingMsg {
  border: 1px solid var(--accent);
  background-color: white;
  color: var(--accent);
  border-radius: 4px;
  font-size: var(--font-xs);
  font-weight: bold;
  text-align: center;
  position: absolute;
  width: max-content;
  padding: 0.25rem 1rem;
  bottom: 1rem;
}

:global {
  .missingBig {
    font-size: 36px;
  }
}

.contactUsCTA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-sm);

  margin-top: 2rem;
}
