@import "app/assets/stylesheets/_mixins.scss";

.congratulations {
  padding: 3rem 0 0 0 !important;
}

.wrapInfo {
  border-bottom: none !important;
}

.subtitle {
  font-size: var(--font-xl);
  font-weight: var(--regular);
  line-height: 2rem;
  color: var(--text);
  margin: 0 0 1rem 0;
}

.text {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text);
  margin: 0 0 1rem 0;
  @include codeAndBold;

  &:last-of-type {
    margin: 0 0 2rem;
  }
}
