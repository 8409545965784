@import "app/assets/stylesheets/_mixins.scss";

.inputContainer {
  @include inputContainer;

  &.labelInRow {
    @include inputLabelinrow;
  }
}

.labelContainer {
  @include inputWrapLabel;

  .checkboxContainer & {
    margin: 0;
  }
}

.wrapLabel {
  @include inputWrapLabel;
}

.label {
  @include inputLabel;
}

.helpTooltip {
  @include inputHelpTooltip;
}

.hint {
  @include inputHint;
}

.wrapInput {
  @include inputWrap;

  .withBlockedTextEnd &,
  .withBlockedTextStart & {
    min-height: 2.5rem;
    box-sizing: border-box;
    flex-direction: row;
    align-items: stretch;

    @include media-md {
      min-height: 2rem;
    }
  }
}

.input {
  @include input;
  position: relative;

  .withBlockedTextStart & {
    border-radius: 0 0.25rem 0.25rem 0;
    height: auto;
    line-height: calc(2.5rem - 2px);

    @include media-md {
      line-height: calc(2rem - 2px);
    }
  }

  .withBlockedTextEnd & {
    height: auto;
    line-height: calc(2.5rem - 2px);
    border-radius: 0.25rem 0 0 0.25rem;

    @include media-md {
      line-height: calc(2rem - 2px);
    }
  }
}

.errorMsg {
  @include inputErrorMsg;
}

.input[name="fiscalcode"] {
  text-transform: uppercase;
}

.wrapBlockedTextEnd,
.wrapBlockedTextStart {
  max-width: 50%;
  min-height: 2.5rem;
  border-top: 1px solid var(--input-border);
  border-right: 1px solid var(--input-border);
  border-bottom: 1px solid var(--input-border);
  border-radius: 0 0.25rem 0.25rem 0;
  box-sizing: border-box;
  background: var(--bg);
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-wrap: break-word;

  @include media-md {
    min-height: 2rem;
  }
}

.wrapBlockedTextStart {
  border-left: 1px solid var(--input-border);
  border-right: none;
  border-radius: 0.25rem 0 0 0.25rem;
}

.blockedTextEnd,
.blockedTextStart {
  max-width: 100%;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--text);
  line-height: 1.25;
  overflow-wrap: break-word;
}

// checkbox
.checkboxContainer {
  flex-direction: row-reverse;
}

.checkbox {
  margin: 0 0.5rem 0 0 !important;
}

.small {
  width: auto;
}

// Password score check
.passwordScore {
  display: grid;
  grid-template-columns: 1fr 9rem;
  grid-column-gap: 0.5rem;
  align-items: center;
  margin: 0.375rem 0 0 0;
}

.progress {
  width: 100%;
  height: 0.25rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 0.25rem;
  border-radius: var(--radius-4);
  background: repeating-linear-gradient(
    to right,
    var(--bg-d3) 0,
    var(--bg-d3) calc(100% / 4.93 - 0.25rem),
    var(--white) calc(100% / 4.93 - 0.25rem),
    var(--white) calc(100% / 4.93)
  );
  box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.progressLabel {
  font-weight: var(--regular);
  font-size: var(--font-xs);
  color: var(--text-a40);
  line-height: 1.25;
}

.vianovaInput {
  height: 2.5rem;
  font-family: var(--roboto);

  @include media-md {
    height: 2.25rem;
  }
}

.vianovaLabel {
  font-family: var(--roboto);
  font-size: 14px;
}
