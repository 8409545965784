@import "app/assets/stylesheets/_mixins.scss";

.wrapBadge {
  display: flex;
  justify-content: center;
  position: relative;
}

.badge {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.enabled,
  &.success,
  &.completed_with_errors,
  &.valid_with_skipped,
  &.completed,
  &.valid {
    background: var(--green);

    &.wait_pay {
      background: var(--yellow);
    }
  }

  &.disabled {
    background: var(--yellow);

    &.wait_delete {
      border: 8px solid var(--red);
      background: var(--white);
    }

    &.vetting,
    &.system,
    &.admin {
      background: var(--red);
    }
  }

  &.waiting {
    border: 2px solid var(--yellow);
    background: var(--white);
  }

  &.ownership_check,
  &.scheduled {
    border: 4px solid var(--green);

    &.preview {
      border: 3px solid var(--green);
      background-color: var(--white);
    }
  }

  &.failure,
  &.failed,
  &.error,
  &.crashed,
  &.invalid {
    background-color: var(--red);
  }

  &.deleting,
  &.deletion,
  &.testing,
  &.updating.deleting,
  &.updating.deletion {
    border: 1px solid var(--red);
    background: var(--red);
  }

  &.updating,
  &.enqueued,
  &.validating,
  &.running,
  &.activating.archive,
  &.to_schedule,
  &.validating_resources {
    background: var(--accent);
    border: 1px solid var(--accent);
  }
}

.syncIcon {
  animation: rotating 1.5s linear infinite;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg * {
    stroke: var(--white);
  }
}

.warningIcon {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background: var(--yellow);
  position: absolute;
  right: -0.125rem;
  top: -0.125rem;

  .preview & {
    width: 0.4375rem;
    height: 0.4375rem;
    right: -1px;
    top: -1px;
  }
}

.preview {
  width: 0.75rem;
  height: 0.75rem;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
