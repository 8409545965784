@import "app/assets/stylesheets/_mixins.scss";

// div wrapper inside content area
.contentWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(0px, 1fr);
  justify-content: center;
  overflow-y: scroll;
}

// override svg-inline package
.SVGInline {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none !important;
  }
}

// input full width in section with two columns
.inputFull2 {
  @include media-sm {
    grid-column: 1 / 3;
  }
}

.inputAddMargin {
  margin: 0 0 2rem 0;
}

// dropDown menu
.dd-menu {
  position: inherit !important;

  @include media-sm {
    position: relative !important;
    cursor: pointer;
  }

  .dd-menu-items {
    margin: 0 !important;
    box-shadow: none !important;
    background: none !important;
    top: var(--safe-top);
    left: 0;
    pointer-events: all;
    z-index: var(--z-index-top) !important;

    @include media-sm {
      height: initial;
      top: initial;
      left: initial;
    }

    ul,
    ol {
      min-width: 10rem;
      height: calc(100vh - #{var(--safe-top)}); // fallback
      height: calc(#{var(--fullVh)} - #{var(--safe-top)});
      background: var(--overlay) !important;
      box-shadow: none !important; // remove default box-shadow
      padding: 2rem 1rem !important;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-family: var(--font-family) !important;

      @include media-sm {
        height: initial;
        border-radius: 0.25rem;
        background: none !important; // remove default background
        box-shadow: var(--shadow-dp8) !important; // remove default box-shadow
        padding: 0 !important;
      }

      li {
        background: var(--white);
        font-family: var(--font-family) !important;

        a {
          height: 3rem;
          padding: 0 0.75rem 0 0.5rem !important;
          border-bottom: 1px solid var(--bg-d3);
          box-sizing: border-box;
          display: grid !important;
          grid-template-columns: auto 1fr;
          grid-template-areas: "icon label";
          grid-column-gap: 0.5rem;
          align-items: center;

          @include media-sm {
            height: 2.5rem;
            border-bottom: none;
          }

          .SVGInline {
            grid-area: icon;
            width: 1rem;
            height: 1rem;
          }

          div {
            grid-area: label;
            font-size: var(--font-base) !important;
            line-height: 2rem;
            color: var(--text) !important;

            @include media-md {
              font-size: var(--font-sm) !important;
            }
          }
        }

        &:first-of-type {
          border-radius: 0.25rem 0.25rem 0 0;
        }

        &:nth-last-child(2) {
          border-radius: 0 0 0.25rem 0.25rem;

          a {
            border-bottom: none;
          }
        }

        &.cancelBtn {
          border-radius: 0.25rem;
          margin: 1rem 0 0 0;

          a {
            border-bottom: none;
            grid-template-columns: 1fr;
            grid-template-areas: "label";
            div {
              text-align: center;
            }
          }
        }

        &.account {
          background: var(--bg);
          a {
            height: auto;
            border-bottom: 1px solid var(--bg-d5);
            box-sizing: border-box;
            padding: 0 1rem 0 0.5rem !important;
            align-items: start;

            .SVGInline {
              margin: 0.5rem 0 0 0;
            }

            div {
              line-height: 1.125rem;
              padding: 0.4375rem 0;
              display: flex;
              flex-direction: column;

              span:last-child {
                font-family: var(--font-family) !important;
                font-size: var(--font-xs) !important;
                color: var(--text-a40) !important;
              }
            }
          }
        }

        & > :not(.dd-item-ignore) {
          font-family: var(--font-family) !important;
        }
      }
    }
  }

  &.navbar-ddMenu {
    .dd-menu-items {
      top: 0;
    }
  }
}

.dd-menu .dd-menu-items ul.dd-items-right li > *,
.dd-menu .dd-menu-items ol.dd-items-right li > * {
  text-align: left !important;
}

.dd-menu .dd-menu-items ul li:hover:not([role="separator"]):not(.separator),
.dd-menu .dd-menu-items ul li > *:focus,
.dd-menu .dd-menu-items ol li:hover:not([role="separator"]):not(.separator),
.dd-menu .dd-menu-items ol li > *:focus {
  color: var(--text) !important;
  background-color: var(--bg-hover) !important;
}

.slimSelect {
  :global {
    .select__control {
      // height: 1rem !important;
      // width: 60px;
    }
  }
}

// react-select
.wrapSelect {
  :global {
    .select__control {
      min-height: 2.5rem !important;
      max-height: 2.5rem !important;
      font-size: var(--font-base) !important;
      color: var(--text);
      border-width: 1px !important;
      border-style: solid !important;
      border-color: var(--input-border);
      border-radius: 0.25rem;
      box-sizing: border-box;
      background: transparent;
      box-shadow: 0 0 0 0 #ffffff !important;
      padding: 0 0.375rem !important;
      outline: none !important;
      &:hover {
        border-color: var(--input-border-hover);
      }

      @include media-md {
        min-height: 2rem !important;
        max-height: 2rem !important;
        font-size: var(--font-sm) !important;
      }
    }

    .select__control--is-focused {
      border-color: var(--accent) !important;
    }

    .select__value-container {
      height: calc(2.5rem - 2px);
      // line-height: 1 !important;
      padding: 0;
      align-items: center;
      outline: none !important;
      div {
        margin: 0;
      }

      @include media-md {
        height: calc(2rem - 2px);
      }
    }

    .select__single-value {
      max-width: content;
      color: var(--text);
      padding: 0;
      margin: 0;
    }

    .select__indicator-separator {
      display: none;
    }

    .select__indicator {
      background: transparent;
      padding: 0 !important;
      cursor: pointer;
    }

    .select__dropdown-indicator {
      span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .select__menu {
      max-width: 540px;
      background: var(--white);
      border-radius: 0 !important;
      box-shadow: var(--shadow-dp8);
      margin: 0.25rem 0;
      z-index: var(--z-index-3);
    }

    .select__menu-list {
      padding: 0;
    }

    .select__option {
      height: 2rem;
      font-size: var(--font-sm);
      line-height: 2rem;
      color: var(--text);
      padding: 0 0.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
    }

    .select__option--is-focused {
      background: var(--bg-hover);
    }

    .select__option--is-selected {
      background: var(--accent-l35);
    }

    .select__option--is-disabled {
      opacity: 0.5;
      cursor: default;
    }

    .select__menu-notice {
      height: 2rem;
      font-size: var(--font-sm);
      line-height: 2rem;
      color: var(--text);
      padding: 0 0.5rem;
      cursor: pointer;
    }

    .select__menu-notice--no-options {
      color: var(--text-a40);
      text-align: left;
    }

    .select__placeholder {
      color: var(--text-a40);
      margin: 0;
    }
  }
  &.selectSizeSmall {
    // add this class to make selct height small
    :global {
      .select__control {
        min-height: 1.5rem !important;
        max-height: 1.5rem !important;
      }

      .select__value-container {
        height: calc(1.5rem - 2px);
      }
    }
  }

  &.selectWithError {
    :global {
      .select__control {
        border-color: var(--red) !important;
      }
    }
  }
}

// select expiration password

.selectExpiration {
  :global {
    .wrapSelect {
      width: 5rem;
    }
  }
}

// react-dropzone
.wrapDropzone {
  // width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.labelDZ {
  width: 100%;
  height: 1.5rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  // line-height: 1.25rem;
  color: var(--text);
}

.contentDZ {
  border-radius: 2px;
  max-height: 20rem;
  background: var(--bg);
  border-radius: 0.5rem;
  margin: 0 0 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.errorDZ {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  width: 100%;
  padding-bottom: 2rem;
  overflow-y: auto;

  max-height: 12rem;
  white-space: pre;
  line-height: 125%;

  svg {
    opacity: 0.3;
  }

  .sectionDesc {
    color: var(--text-a60);
  }

  b {
    padding-bottom: 0.5rem;
    display: block;
  }
}

.contentDZ.active {
  border: 1px solid #29c369;
  background-color: #29c369;

  .iconDZ,
  .textDZ {
    fill: white;
    color: white;
  }
}

.contentDZ.reject {
  border: 1px solid #e45548;
  background-color: #e45548;

  .textDZ {
    color: white;
  }
}

.iconDZ {
  margin: 0 0.5rem 0 0;
}

.textDZ {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--text-a60);
  word-break: break-all;
}

.btnDZ {
  margin: 0 0 0 1rem;
}

// drop zone file preview
.filePrewDZ {
  width: 100%;
  min-height: 7rem;
  border-radius: 2px;
  background: var(--accent-l35);
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .contentDZ {
    background: none;
    // height: auto;
  }

  &.withError {
    background: var(--red-l35);
    color: var(--red-d10);
  }

  @include media-sm {
    min-height: 6rem;
  }
}

.contentDZ {
  &.withError {
    background: var(--red-l35);
    color: var(--red-d10);
  }
}

.dropZoneError {
  font-size: var(--font-sm);
  margin-top: 4px;
  margin-bottom: 4px;
}

.invalidFileLabel {
  font-weight: var(--semi-bold);
  font-size: var(--font-xs);
  text-transform: uppercase;
  line-height: 1.5rem;
  color: var(--red-d10);
  text-align: left;
}

// .filePrewDZ {
//   width: 100%;
//   min-height: 7rem;
//   border-radius: 8px;
//   background: var(--accent-l35);
//   padding: 0.5rem;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
//   align-items: center;

//   .contentDZ {
//     background: none;
//     height: auto;
//   }

//   &.withError {
//     background: var(--red-l35);
//     color: var(--red-d10);
//   }

//   @include media-sm {
//     min-height: 6rem;
//   }
// }
