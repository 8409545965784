@import "app/assets/stylesheets/_mixins.scss";

.otp {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  align-content: flex-start;
}

.btn {
  min-width: 9rem;
}
