@import "app/assets/stylesheets/_mixins.scss";

.ownershipCheckEmail {
  padding: 1rem 0;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 2rem;
}

.text {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.5;
  color: var(--text);
  @include codeAndBold;
}

.form {
  display: grid;
  grid-template-rows: auto 3rem;
  grid-row-gap: 1rem;
}

.footer {
  display: grid;
  grid-template-columns: 1fr [btnRight]2fr;
  align-items: end;
}

.btnRight {
  grid-column: btnRight;
  justify-self: end;
  display: flex;
  justify-content: flex-end;

  button {
    min-width: calc(50% - 0.5rem);
  }

  @include media-sm {
    button {
      min-width: 5rem;

      &:last-of-type {
        margin: 0 0 0 0.5rem;
      }
    }
  }
}
