@import "app/assets/stylesheets/_mixins.scss";

.disabledAlert {
  height: 100vh; // fallback
  height: var(--fullVh);
  background: var(--bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.disabledMsg {
  max-width: 30rem;
  padding: 0 1rem;
  margin: -10vh 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media-sm {
  }
}

.title {
  align-self: flex-start;
  font-size: var(--font-xl);
  font-weight: var(--semi-bold);
  line-height: 2rem;
  color: var(--text);
}

.info {
  align-self: flex-start;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.5rem;

  color: var(--text);
  padding: 0 0 1rem 0;
}

.link {
  color: var(--accent);
  text-decoration: underline;
  margin: 0 0 0 0.25rem;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    color: var(--accent-d30);
  }
}

.btn {
  align-self: flex-start;
  min-width: 100%;
  margin: 1.25rem 0 0 0;

  @include media-sm {
    min-width: 8rem;
  }
}
