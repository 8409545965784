@import "app/assets/stylesheets/_mixins.scss";

.wrapLabel {
  @include inputWrapLabel;
}

.label {
  @include inputLabel;
}

// editor styles
.richEditor {
  // font-family: Arial, Helvetica, sans-serif;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  border: 1px solid var(--input-border);
  border-radius: var(--radius-4);
  line-height: 1.5;

  p {
    margin: 0;
  }

  .disabled & {
    opacity: 0.5;
    pointer-events: none;
  }
}

.editor {
  flex-grow: 1;
  max-width: 100%;
  padding: 0.5rem;
  @include overflowY;

  img {
    max-width: 100%;
  }

  &:focus {
    outline: none;
  }
}

// inline image size
.editor-inline-image {
  max-width: 100%;
}

// editor body reset/styles
:global {
  #editor-body {
    font-family: sans-serif;
    font-size: 14px;

    a:hover {
      cursor: pointer;
      background: var(--yellow);
      border-bottom: 2px solid var(--yellow);
    }
  }
}

// Dialog modal
.editorModalOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-top);
  background: var(--overlay);
  border-radius: var(--radius-4);
}

.editorModal {
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  background: var(--bg-body);
  box-shadow: var(--shadow-dp16);
  border-radius: var(--radius-8);
}

.editorModalHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.editorModalTitle {
  font-weight: var(--semi-bold);
  font-size: var(--font-lg);
  line-height: 1.35;
  color: var(--text);
}

.editorModalClose {
  cursor: pointer;
}

.editorModalBody {
  flex: 1;
  padding: 0 1rem;
}

.editorModalInput {
  margin: 0 0 1rem 0;
}

.editorModalFooter {
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem;

  button {
    min-width: calc(50% - 0.5rem);

    @include media-sm {
      min-width: 6rem;
    }

    &:last-of-type {
      margin: 0 0 0 1rem;
    }
  }
}

// HTML dialog
.htmlEditor textarea {
  min-height: 11rem;
  font-family: monospace;
  white-space: pre;
  resize: none;
}
