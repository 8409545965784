@import "app/assets/stylesheets/_mixins.scss";

// Table list
.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 1rem 0;

  .disabled & {
    opacity: 0.5;
  }
}

.listHeader {
  height: 1.5rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  background: var(--bg);

  & > * {
    font-size: var(--font-xs);
    font-weight: var(--semi-bold);
    color: var(--text);
    text-transform: uppercase;
    letter-spacing: 0.065em;
    line-height: 1.5rem;
    box-sizing: border-box;
    padding: 0 0 0 0.5rem;
    @include textOverflow;
  }
}

.listRow {
  min-height: 2.5rem;
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
}

.listItem {
  min-height: 2.5rem;

  & > * {
    font-weight: var(--regular);
    font-size: var(--font-sm);
    color: var(--text);
    line-height: 1.5rem;
    box-sizing: border-box;
    padding: 0 0 0 0.5rem;
    @include textOverflow;
  }
}

.listHeader,
.listItem {
  display: grid;
  grid-template-columns: 6rem 1fr auto 4.5rem;
  align-items: center;

  @include media-sm {
    grid-template-columns: 10rem 1fr auto 4rem;
  }
}

.listPlaceholder {
  min-height: 2.5rem;
  font-weight: var(--regular);
  font-size: var(--font-sm);
  color: var(--text-a40);
  box-sizing: border-box;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
}

.listActionRight {
  box-sizing: border-box;
  padding: 0 !important; // to override default item list padding
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.listInput {
  margin: 0 0 1rem 0;
}

.listBox {
  box-sizing: border-box;
  padding: 0.5rem 0.25rem;
  overflow: hidden;

  @include media-sm {
    padding: 0.5rem;
  }
}

.listBoxContent {
  border: 1px solid var(--bg-d5);
  border-radius: 0.25rem;
  box-sizing: border-box;
  background: var(--bg-body);
  padding: 0.5rem;
  display: flex;
  flex-direction: column;

  @include media-sm {
    padding: 1rem;
  }
}

.listBoxFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding: 1.5rem 0 0 0;

  button {
    min-width: calc(50% - 0.5rem);

    @include media-sm {
      min-width: 6rem;
    }

    &:last-of-type {
      margin: 0 0 0 1rem;
    }
  }
}

.listAddBtn {
  min-width: 100%;

  @include media-sm {
    min-width: 6rem;
  }
}

// Editor
.editorLabel {
  height: 1.5rem;
  display: block;
  @include inputLabel;
}

// Tags
.tagDefault {
  padding: 0 0.375rem;
  margin: 0 0.25rem;
  background: var(--green);
  border-radius: 0.125rem;
  font-size: 0.75rem;
  color: var(--bg-body);
}

.tagCcn {
  padding: 0 0.375rem;
  margin: 0 0.25rem;
  background: var(--pink);
  border-radius: 0.125rem;
  font-size: 0.75rem;
  color: var(--bg-body);
}
