@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsSettings.scss";

.settingsDelegate {
  @include settingsSDS;
}

.wrapInfoBtn {
  @include wrapInfoBtnSDS;
}

.warnignMsg {
  @include warnignMsgSDS;
}

.infoBtnGroup {
  @include infoBtnGroupSDS;
}

.settings {
  @include sectionsSDS;
}

.wrapDomains {
  display: flex;
  flex-direction: column;
}

.folderMapper {
  margin: 1rem 0 2rem 0;
}

.box {
  height: 13.5rem;
  font-size: var(--font-sm);
  color: var(--text);
  border: 1px solid var(--input-border);
  border-radius: 1px;
  box-sizing: border-box;
  background: var(--bg);
  margin: 1rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
}

.infoText {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--text);
  @include codeAndBold;
}
