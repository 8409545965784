@import "app/assets/stylesheets/_mixins.scss";

.ownershipCheckDNS {
  width: 100%;
  padding: 1rem 0;
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-row-gap: 2rem;

  @include media-sm {
    grid-template-rows: auto auto auto 3rem;
  }

  &.loader {
    grid-template-rows: 3rem 3rem;
  }
}

.loaderText {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.5;
  color: var(--text);
  text-align: center;
}

.text {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.5;
  color: var(--text);
  @include codeAndBold;
}

.headerCode {
  height: 1.5rem;
  font-size: var(--font-xs);
  font-weight: var(--semi-bold);
  line-height: 1.5rem;
  color: var(--text);
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  background: var(--bg);
  padding: 0 0.5rem;
  display: grid;
  grid-template-columns: 2fr 3rem 1fr;
  grid-column-gap: 0.5rem;
  align-items: center;
  div:nth-child(2) {
    justify-self: center;
  }
}

.rowCode {
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: 0 0.5rem;
  display: grid;
  grid-template-columns: 2fr 3rem 1fr;
  grid-column-gap: 0.5rem;
  align-items: center;
  div {
    font-family: monospace !important;
    font-size: var(--font-sm);
    line-height: 1.5rem;
    word-break: break-all;
  }
  div:nth-child(2) {
    justify-self: center;
  }

  @include media-sm {
    height: 2.5rem;
    div {
      line-height: 2.5rem;
      word-break: normal;
    }
  }
}

.footer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 5rem;
  grid-template-areas: "skipBtn" "btnRight";
  align-items: center;

  @include media-sm {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    grid-template-areas: "skipBtn btnRight";
    align-items: end;
  }
}

.skipBtn {
  grid-area: skipBtn;
  min-width: 100%;

  @include media-sm {
    min-width: 5rem;
  }
}

.btnRight {
  grid-area: btnRight;
  display: flex;
  justify-content: space-between;

  button {
    min-width: calc(50% - 0.5rem);
  }

  @include media-sm {
    justify-self: end;
    justify-content: flex-end;

    button {
      min-width: 5rem;

      &:last-of-type {
        margin: 0 0 0 0.5rem;
      }
    }
  }
}
