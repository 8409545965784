@import "app/assets/stylesheets/_mixins.scss";

.sectionSP {
  width: 100%;
  border-top: 1px solid var(--bg-d5);
  padding: 1rem 0 2rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "infoSectionSP" "formsSectionSP";
  grid-row-gap: 1rem;
  &:first-of-type {
    border: none;
  }

  &:last-of-type {
    padding: 1rem 0 4rem 0;
  }

  @include media-sm {
    width: 32rem;
    padding: 1rem 0 2rem 0;
    grid-row-gap: 2rem;
  }

  @include media-lg {
    width: 48rem;
    grid-template-columns: 16rem 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "infoSectionSP formsSectionSP";
    grid-column-gap: 2rem;
  }

  @include media-xl {
    width: 52rem;
  }
}

.infoSectionSP {
  grid-area: infoSectionSP;
}

.formsSectionSP {
  grid-area: formsSectionSP;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  align-content: flex-start;

  @include media-sm {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
  }
}

.boxSP {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  align-content: flex-start;

  @include media-sm {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
  }
}

.notAvailableSP {
  border: 1px solid var(--bg-d5);
  border-radius: 0.5rem;
  box-sizing: border-box;
  // background: var(--accent-l35);
  padding: 0.5rem;
  margin: 0 -0.5rem;

  @include media-sm {
    padding: 1rem;
    margin: 0 -1rem;
  }
}

// Inline label + button
.wrapInlineBtnSP {
  display: flex;
  flex-direction: column;

  button {
    min-width: 100%;
    margin: 0.5rem 0 0 0;
  }

  @include media-sm {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > *:first-child {
      flex: 1;
    }

    button {
      min-width: 6rem;
      white-space: nowrap;
      margin: 0 0 0 1rem;
    }
  }
}

.labelInlineBtnSP {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text);

  &.disabled {
    opacity: 0.5;
  }
}

// Table in info section
.infoTableSP {
  width: 100%;

  & > * {
    min-height: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 0.75rem 1fr;
    grid-column-gap: 0.25rem;
    align-items: center;
    padding: 0.25rem;
    box-sizing: border-box;
    font-family: monospace;
    font-size: var(--font-xs);
    color: var(--text-a60);

    & > *:last-child {
      grid-column: 3/4;
    }

    @include media-sm {
      grid-template-columns: 1fr 1rem 1fr;
      grid-column-gap: 0.5rem;
    }
  }

  & > *:nth-child(odd) {
    background: var(--bg);
  }

  .iconTableSP {
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
