@import "app/assets/stylesheets/_mixins.scss";

.bill {
  display: flex;
  flex-direction: column;
}

.headerService {
  background: var(--bg);
}

.rowService {
  height: 2rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--text);
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: 0 0.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: "nameService dataService";
  grid-column-gap: 0.5rem;
  align-items: center;
}

.nameService {
  grid-area: nameService;

  .headerService & {
    font-weight: var(--semi-bold);
  }
}

.dataService {
  grid-area: dataService;
  display: flex;
  justify-content: flex-end;
}

.numberService {
  font-weight: var(--semi-bold);
  text-transform: capitalize;
}

.priceService {
  min-width: 4rem;
  color: var(--text-a40);
  text-align: right;
  &:before {
    content: "x";
    margin: 0 0.25rem;
  }

  &:after {
    content: "€";
    margin: 0 0 0 0.25rem;
  }
}

.rowMinimuOrder {
  background: var(--yellow);
}

.rowBill {
  background: var(--bg);
  padding: 0.5rem 0.5rem 0 0.5rem;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
}

.fee {
  font-size: var(--font-lg);
  font-weight: var(--semi-bold);
  margin: 0 0 0.5rem 0;
  display: flex;
  justify-content: space-between;
}

.priceFee {
  align-self: end;
  &:after {
    content: "€";
    margin: 0 0 0 0.25rem;
  }
}

.infoFee {
  font-size: var(--font-sm);
  color: var(--text-a40);
  margin: 0 0 0.5rem 0;

  b {
    font-weight: var(--semi-bold);
  }
}

.infoRecap {
  display: grid;
  grid-template-columns: 1fr;
}

.rowInfoRecap {
  min-height: 2rem;
  border-bottom: 1px solid var(--bg-d5);
  display: grid;
  grid-template-columns: [labelInfoRecap]1fr [dataInfoRecap]3fr;
  grid-column-gap: 0.5rem;
  align-items: center;
  &:last-of-type {
    border-bottom: none;
  }
}

.labelInfoRecap {
  grid-column: labelInfoRecap;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.5rem;
  color: var(--text-a40);
}

.dataInfoRecap {
  grid-column: dataInfoRecap;
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  line-height: 1.5rem;
  color: var(--text);
}

.wrapTextArea {
  display: grid;
  grid-template-rows: [textArea]7.75rem [wrapRadioBtn]2rem;
  grid-row-gap: 0.5rem;
}

.textArea {
  grid-row: textArea;
  textarea {
    min-height: 6rem;
    line-height: 1rem;
  }
}
.wrapRadioBtn {
  grid-row: wrapRadioBtn;
  display: grid;
  grid-template-columns: [radioBtn]1fr [errorMsg]auto;
  grid-column-gap: 0.5rem;
  align-items: center;
}

.radioBtn {
  grid-column: radioBtn;
}

.errorMsg {
  grid-column: errorMsg;
  font-size: var(--font-sm);
  color: var(--red);
}

// modal
.iconModal {
  svg * {
    fill: var(--red);
  }
}
