@import "app/assets/stylesheets/_mixins.scss";

.ownershipCheckHTML {
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 3rem;
  grid-template-areas: "introText" "footer";
  grid-row-gap: 2rem;

  &.fileGenerated {
    grid-template-rows: auto auto 3rem;
    grid-template-areas: "introText" "wrapStep" "footer";
  }
  &.withErrors {
    grid-template-rows: auto 1.5rem auto 3rem;
    grid-template-areas: "introText" "warningMsg" "wrapStep" "footer";
  }
}

.warningMsg {
  grid-area: warningMsg;
}

.introText {
  grid-area: introText;
}

.text {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.5;
  color: var(--text);
  @include codeAndBold;
}

.wrapStep {
  grid-area: wrapStep;
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-row-gap: 1rem;
}

.step {
  display: inline-flex;
  flex-direction: column;
}

.labelStep {
  height: 2rem;
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  color: var(--text);
  line-height: 1.5rem;
}

.downloadFile {
  height: 2rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  display: flex;
  align-items: center;
  .icon {
    margin: 0 0.5rem 0 0;
    svg * {
      stroke: var(--accent);
    }
  }
  &:hover {
    color: var(--accent-d30);
    .icon svg * {
      stroke: var(--accent-d30);
    }
  }
}

.fileRoot {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 2rem;
  margin: 0.5rem 0;
  &:hover {
    color: var(--accent-d30);
  }
}

.footer {
  grid-area: footer;
  display: grid;
  grid-template-columns: 1fr [btnRight]2fr;
  align-items: end;
}

.btnRight {
  grid-column: btnRight;
  justify-self: end;
  display: flex;
  justify-content: flex-end;

  button {
    min-width: calc(50% - 0.5rem);
  }

  @include media-sm {
    button {
      min-width: 5rem;

      &:last-of-type {
        margin: 0 0 0 0.5rem;
      }
    }
  }
}
