@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsList.scss";

.listMigrations {
  @include listMSS;
}

// header
.headerList {
  @include headerListMSS;
}

.titleList {
  @include titleListMSS;
}

.descList {
  @include descListMSS;
}

.actionsList {
  @include actionsListMSS;
}

// content
.contentList {
  @include contentList;
}

// filters
.filters {
  @include filtersMSS;
}

// table
.table {
  @include tableMSS;
}

.executionStatus {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}
