.green {
	color: var(--green);
}

.lightYellow {
	color: var(--yellow);
}
.mediumYellow {
	color: var(--yellow-d30);
}
.mediumRed {
	color: var(--red);
}
.highRed {
	color: var(--red-d10);
}
