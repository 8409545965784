@import "app/assets/stylesheets/_mixins.scss";

.sectionRecap {
  border-bottom: 1px solid var(--bg-d5);
  padding: 0 0 1rem 0;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 0.5rem;
  &:last-of-type {
    border: none;
  }
}

.labelRecapt {
  font-size: var(--font-sm);
  line-height: 1.5rem;
  color: var(--text-a40);
}

.dataRecapt {
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  color: var(--text);
  line-height: 1.5rem;
}

.wrapDomain {
  width: 100%;
  margin: 0 0 2rem 0;
  display: flex;
  flex-direction: column;
}

.nameDomain {
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  color: var(--text);
  line-height: 1.5rem;
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  background: var(--bg);
  padding: 0 0.25rem;
}

.rowDomain {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--text);
  line-height: 1.5rem;
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: 0 0.25rem;
  display: grid;
  grid-template-columns: 1fr 5rem;
}

.visualization {
  font-size: var(--font-xs);
  text-transform: uppercase;
}
