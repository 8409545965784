@import "app/assets/stylesheets/_mixins.scss";

.paginationControls {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include media-md {
    flex-direction: row;
  }
}

.statusFilter {
  min-width: 130px;

  @include media-sm {
    max-width: 400px;
  }
}

.customerSelect {
  @include media-sm {
    max-width: 400px;
  }
}

.accountFilter {
  min-width: 200px;

  @include media-sm {
    max-width: 400px;
  }
}

.searchFilter {
  @include media-sm {
    max-width: 400px;
  }
}

.tools {
  display: flex;
  gap: 20px;

  @include media-md {
    margin-left: auto;
  }
}

.paginationSelection {
  min-width: 70px;
}

.iconRefresh {
  grid-area: iconRefresh;
}
