@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsList.scss";

.listCustomers {
  @include listMSS;
}

// header
.headerList {
  @include headerListMSS;
}

.titleList {
  @include titleListMSS;
}

.descList {
  @include descListMSS;
}

.actionsList {
  @include actionsListMSS;
}

// content
.contentList {
  @include contentList;
}

// filters
.filters {
  @include filtersMSS;
  display: flex;
  flex-direction: column;

  @include media-sm {
    flex-direction: row;
    align-items: center;
  }
}

.statusFilter {
  width: 100%;
  margin: 0 0 1rem 0;

  @include media-sm {
    width: 12rem;
    margin: 0 1rem 0 0;
  }
}

.fullPaginationControls {
  width: 100%;
}

// table
.table {
  @include tableMSS;
}
