@import "app/assets/stylesheets/_mixins.scss";

.listAliasEmailAccounts {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto minmax(0px, 1fr);
  grid-template-areas: "wrapInfoBtn" "filters" "table";
  @include overflowY;

  @include media-sm {
    grid-template-rows: auto 4rem minmax(0px, 1fr);
  }
}

.wrapInfoBtn {
  grid-area: wrapInfoBtn;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;

  @include media-sm {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-xxl {
    padding: 1rem 4rem;
  }
}

.infoText {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--text-a40);
}

.addBtn {
  min-width: 100%;
  @include media-sm {
    justify-self: end;
    min-width: inherit;
  }
}

.filters {
  grid-area: filters;
  align-self: center;
  padding: 1rem;

  @include media-xxl {
    padding: 1rem 4rem;
  }
}

.table {
  grid-area: table;
  padding: 0 1rem;

  @include media-sm {
    @include overflowY;
  }

  @include media-xxl {
    padding: 0 4rem;
  }
}
