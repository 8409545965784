@import "app/assets/stylesheets/_mixins.scss";

.inputContainer {
  @include inputContainer;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  // &.disabled {
  //   opacity: 0.5;
  //   cursor: default;
  // }

  :global {
    .react-toggle-track {
      width: 3rem;
      height: 1.5rem;
      border: 1px solid var(--bg-d10);
      border-radius: 1.25rem;
      background-color: var(--bg-d5);
      box-sizing: border-box;
    }

    .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
      background-color: var(--bg-d5);
    }

    .react-toggle-thumb,
    .react-toggle--focus .react-toggle-thumb {
      width: 1.25rem;
      height: 1.25rem;
      border: none;
      background-color: var(--white);
      box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.05), 0 3px 1px 0 rgba(0, 0, 0, 0.05),
        0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.05) !important;
      top: 2px;
      left: 2px;
    }

    .react-toggle--checked .react-toggle-track,
    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
      border-color: var(--accent);
      background: var(--accent);
    }

    .react-toggle--checked .react-toggle-thumb {
      left: 26px;
      border: none;
      background-color: var(--white);
      outline: none;
    }

    .react-toggle--disabled {
      cursor: default;
    }
  }
}

.wrapLabel {
  @include inputWrapLabel;
  flex: 1;
}

.label {
  @include inputLabel;
  padding: 0.25rem 0 0 0;

  .disabled & {
    opacity: 0.5;
  }
}

.hint {
  @include inputHint;
}
