@import "app/assets/stylesheets/_mixins.scss";

.wrapModal {
  width: 100%;
  height: 100vh; // fallback
  height: var(--fullVh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay {
  background: var(--overlay);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-index-top);
}

.modal {
  width: 100%;
  height: 100vh; // fallback
  height: var(--fullVh);
  background: var(--white);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: calc(2.5rem + #{var(--safe-top)}) 1fr;
  grid-template-areas: "headerModal" "bodyModal";

  @include media-sm {
    width: 30rem;
    height: auto;
    max-height: 80vh;
    min-height: 15rem;
    box-shadow: var(--shadow-dp8);
    margin: -10vh 0 0 0;
  }

  .largeModal & {
    width: 60rem;
  }
}

.headerModal {
  grid-area: headerModal;
  background: var(--bg);
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: var(--safe-top) 1rem 0 1rem;
  display: flex;
  align-items: center;
}

.headerModalWarning {
  background-color: var(--yellow-l35);
}

.iconModal {
  margin: 0 0.5rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: var(--font-lg);
  font-weight: var(--semi-bold);
  line-height: 2rem;
  color: var(--text);
}

.bodyModal {
  grid-area: bodyModal;
  display: grid;
  grid-template-rows: [content]minmax(5rem, auto) [footerModal]4rem;
  font-size: var(--font-sm);
  line-height: 1.25rem;
  color: var(--text);
  @include overflowY;
  @include codeAndBold;

  @include media-md {
    grid-template-rows: [content]minmax(5rem, auto) [footerModal]3rem;
  }

  .withForm & {
    grid-template-rows: [content]1fr;
  }
}

.bodyModal > div:nth-child(1) {
  grid-row: content;
  padding: 0.5rem 1rem 1rem 1rem;
  overflow-y: auto;

  @include media-sm {
    padding: 0.5rem 1rem 0 1rem;
  }

  p {
    margin: 0 0 1rem 0;
  }

  :global {
    .tableList {
      margin: 0 0 1rem 0;
      li {
        padding: 0 0.375rem;
        font-family: monospace;
        font-size: var(--font-sm);
        line-height: 1.75;
        color: var(--text-a60);
      }

      li:nth-child(odd) {
        background: var(--bg);
      }
    }
  }
}

.bodyModal > div:nth-child(2) {
  grid-row: footerModal;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    min-width: calc(50% - 0.5rem);
  }

  @include media-sm {
    justify-self: end;
    justify-content: flex-end;

    button {
      min-width: 5rem;
      &:last-of-type {
        margin: 0 0 0 0.5rem;
      }
    }
  }
}
