@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsSettings.scss";

.general {
  @include settingsSDS;
}
.options {
  @include settingsSDS;
}
.wrapInfoBtn {
  @include wrapInfoBtnSDS;
}

.warnignMsg {
  @include warnignMsgSDS;
}

.infoBtnGroup {
  @include infoBtnGroupSDS;
}

.settings {
  @include sectionsSDS;
}

.storagePreview {
  display: flex;
  align-items: center;
  font-size: var(--font-sm);
  color: var(--text-a60);
  gap: 4px;

  pre {
    background-color: var(--bg-d5);
    border-radius: 2px;
    color: black;
  }
}

.deliveryWaitlistQuestionsTitle {
  margin-top: 2rem;
  color: var(--accent);
}

.deliveryWaitlistQuestions {
  font-size: var(--font-sm);
  margin-bottom: 2rem;

  li {
    padding-bottom: 0.25rem;
  }
}

.notOnWaitingList {
  color: var(--red);
  border-color: var(--red);
}

.onWaitingList {
  color: var(--yellow-d30);
  background: var(--yellow-l35);
  border-color: var(--yellow);
}

.accepted {
  color: var(--accent);
  background: var(--accent-l35);
  border-color: var(--accent);
}
