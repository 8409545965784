@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsSettings.scss";

.dkim {
  @include settingsSDS;
}

.wrapInfoBtn {
  @include wrapInfoBtnSDS;
}

.warnignMsg {
  @include warnignMsgSDS;
}

.docsBtn {
  @include docsBtnSDS;
}

.saveBtn {
  @include saveBtnDSD;
}

.settings {
  @include sectionsSDS;
}

.infoText {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--text);
}

.txtRecord {
  font-family: monospace !important;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--text);
  line-height: 200%;
  padding: 0.5rem;
  background-color: var(--bg);
  word-break: break-all;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
}

.wrapBtn {
  display: flex;

  & > * + * {
    margin: 0 0 0 0.5rem;
  }
}
