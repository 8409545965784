@import "app/assets/stylesheets/_mixins.scss";

.serversTable {
  max-width: 480px;

  td {
    position: relative;
  }
}

.overviewTable {
  max-width: 480px;
}

.page {
  padding: 1rem 1rem;
  overflow-y: auto;
  padding-bottom: 3rem;
}

.filters {
  display: flex;
  // grid-template-columns: repeat(2, 1fr);

  margin-bottom: 2rem;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.filtersBlock {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.statusFilter {
  width: 11rem;
}

.searchList {
  width: 12rem;
}

.pageShow {
  width: 5rem;
}

.header {
  margin-bottom: 3rem;
}

.lastUpdate {
  font-size: var(--font-sm);
  color: var(--text-a40);
}

.loader {
  margin: auto;
  margin-top: 4rem;
}

.card {
  display: flex;
  width: max-content;
  min-width: 320px;
  flex-direction: column;
  border-radius: var(--radius-2);
  border: 1px solid var(--bg);
  position: relative;
}

.cardSection {
  padding: 0.5rem;
  font-size: var(--font-sm);
  border-bottom: 1px solid var(--bg);
}

.cardSection > span {
  opacity: 0.5;
}

.cardSection:last-child {
  border-bottom: none;
}

.runningCounters {
  padding-bottom: 1.25rem;
}

// .disabled {
//   .cardHead,
//   .cardSection {
//     opacity: 0.5;
//   }
// }

.disabledTag {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: var(--red-l35);
  color: var(--red-d10);
  padding: 0.25rem 1rem;
  border-radius: 2px;
  font-weight: var(--semi-bold);
  font-size: var(--font-sm);
}

.cardHead {
  border-top-left-radius: var(--radius-2);
  border-top-right-radius: var(--radius-2);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  font-weight: var(--semi-bold);
  font-size: var(--font-sm);
  background-color: var(--bg);
}

.counterBig {
  font-size: var(--font-xxl);
  font-weight: var(--semi-bold);
}

.topArea {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  padding-bottom: 3rem;
}

.runCard {
  border: 1px solid var(--bg);
  border-radius: var(--radius-4);
}

.runList {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.accentArrow {
  color: var(--accent);
}

.runHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--bg);
  background-color: var(--bg);
}

.runDetail {
  padding: 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.runCardHost {
  font-size: var(--font-sm);
  color: var(--text-a40);
}

.runValue > pre {
  font-size: var(--font-xs);
  white-space: pre-wrap;
}

.runCardSection {
  border-top: 1px solid var(--bg);
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.runCardSectionVertical {
  flex-direction: column;
  align-items: start;

  .runLabel {
    margin-bottom: 1rem;
  }

  .runValue {
    text-align: left;
  }
}

.operationTypeCode {
  color: var(--text-a40);
  margin-left: 0.5rem;
}

.activeFilter {
  background-color: var(--accent-l35);
  color: var(--accent);
  border-radius: var(--radius-4);
  height: 2rem;
  padding: 0.5rem;
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.activeFilterClose {
  cursor: pointer;

  path {
    fill: var(--accent) !important;
  }
}
