@import "app/assets/stylesheets/_mixins.scss";

$formsWidth: 30rem;

.screenContainer {
  height: 100%;
  padding: 16px 16px 0px 16px;
  display: flex;
  flex-direction: column;
}

.screenOverflow {
  overflow-y: scroll;
}

.formContainer {
  width: 100%;
  height: 100%;
  max-width: $formsWidth;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.showPageContent {
  flex: 1;
  overflow-y: scroll;
  margin-bottom: 50px;

  @include media-md {
    margin-bottom: 0px;
  }
}

.expand {
  width: 100%;
}

.marginRight80 {
  margin-right: 80px;
}

.marginLeft12 {
  margin-left: 12px;
}

.marginLeft28 {
  margin-left: 28px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
}

.flexAlignCenter {
  align-items: center;
}

.gap20 {
  gap: 20px;
}

.formSection {
  margin-top: 36px;
}

.formSectionInput {
  margin-top: 6px;
}

.flexContainer {
  display: flex;
}

.flexColumnContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formFooter {
  margin-top: 20px;
  height: 5.5rem;
  border-top: 1px solid var(--bg-d5);
  padding: 0 0 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  width: 100%;

  button {
    min-width: calc(50% - 0.5rem);
  }

  @include media-sm {
    width: $formsWhidth;
    padding: 0 0 2rem 0;
    justify-content: flex-end;

    button {
      min-width: 5rem;

      &:last-of-type {
        margin: 0 0 0 0.5rem;
      }
    }
  }

  @include media-md {
    height: 4.5rem;
  }
}

.section {
  margin-top: 16px;
}
