@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsList.scss";

.listYearlyOrder {
  @include listMSS;
}

// header
.headerList {
  @include headerListMSS;
}

.titleList {
  @include titleListMSS;
}

.descList {
  @include descListMSS;
}

.actionsList {
  @include actionsListMSS;
}

// content
.contentList {
  // @include contentList;
  align-content: flex-start;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1.5rem;
  grid-column-gap: 1.5rem;
  align-items: start;
  @include overflowY;

  @include media-sm {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-md {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-xxl {
    padding: 0 4rem;
  }
}

.headerDC {
  background: var(--bg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.5rem;
  min-height: 32px;

  &.waitingPay {
    background: var(--yellow);
  }
}

.titleDC {
  font-size: var(--font-sm);
  font-weight: var(--bold);
  line-height: 1.125;
}

.wrapTitleDC {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.labelWaitingPay {
  font-size: var(--font-xs);
  font-weight: var(--regular);
}

.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-sm);
  padding: 0.5rem;

  &:not(:last-child) {
    border-bottom: 1px solid var(--bg-d5);
  }
}

.fieldWarning {
  background: var(--yellow);
}

.price {
  > b {
    margin-right: 20px;
  }
}

.upgradeArrow {
  margin: 0 5px;
}

.payBtn {
  font-weight: 600;
  cursor: pointer;
}
