@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsShow.scss";

.showEmailAccounts {
  @include listDS;
}

// navigation
.navigation {
  @include navigationDS;
}

.viewSectionLabel {
  @include viewSection;
}

//header
.header {
  @include headerDS;
}

.wrapTitle {
  @include wrapTitleDS;
}

.subtitle {
  @include subtitleDS;
}

.info {
  @include infoDS;
}

.settings {
  @include settingsDS;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(0px, 1fr);
}
