@import "app/assets/stylesheets/_mixins.scss";
//
// .listFilter {
//   width: 12rem;
// }

.option {
  :global {
    .select__option--is-focused {
      background: none !important;
    }

    .select__option--is-selected {
      background: none !important;
    }
    .select__option {
      padding: 0 !important;
    }
  }
}

.optionSelected {
  line-height: 1.125rem;
  display: grid;
  grid-template-columns: 1rem 1fr;
  grid-column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;

  .notIcon & {
    grid-template-columns: 1fr;
  }
}

.option {
  padding: 0 0.5rem;
  display: grid;
  grid-template-columns: 1rem 1fr;
  grid-column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;

  .notIcon & {
    grid-template-columns: 1fr;
  }

  &:hover {
    background: var(--bg-hover);
  }
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
}
