@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsList.scss";

.listEtlive {
  @include listMSS;
}

// header
.headerList {
  @include headerListMSS;
}

.titleList {
  @include titleListMSS;
}

.descList {
  @include descListMSS;
}

.actionsList {
  @include actionsListMSS;
}

.contentList {
  grid-area: contentList;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 4.5rem 1fr;
  grid-template-areas: "etliveFilters" "filters" "table";

  @include overflowY;

  @include media-sm {
    overflow-y: inherit;
  }
}

// filters
.etliveFilters {
  grid-area: etliveFilters;
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: 1rem 1rem 0.5rem 1rem;

  @include media-xxl {
    padding: 1rem 4rem;
  }
}

// filters
.filters {
  @include filtersMSS;
  display: grid;
  grid-template-columns: 1fr 2rem;
  grid-template-areas: "statusFilter iconRefresh";
  grid-column-gap: 1rem;
  align-items: center;

  @include media-sm {
    grid-template-columns: 1fr 2rem auto 3.5rem auto;
    grid-template-areas: "statusFilter iconRefresh numbeResults pageShow btnExport";

    .isMobileDevice & {
      grid-template-columns: 1fr 2rem auto 3.5rem;
      grid-template-areas: "statusFilter iconRefresh numbeResults pageShow";
    }
  }
}

.statusFilter {
  grid-area: statusFilter;
  @include media-sm {
    width: 11rem;
  }
}

.iconRefresh {
  grid-area: iconRefresh;
}

.btnExport {
  grid-area: btnExport;
  justify-self: end;
}

.pageShow {
  grid-area: pageShow;
}

.numbeResults {
  grid-area: numbeResults;
  display: flex;
  align-items: center;
}

.labelNumbeResults {
  font-size: var(--font-sm);
  color: var(--text-a40);

  &:after {
    content: ":";
    margin: 0 0.25rem 0 0;
  }
}

.dataNumbeResults {
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  color: var(--text);
}

// table
.table {
  @include tableMSS;
}

.wrapIllustration {
  @include wrapIllustration;
}

.msgIllustration {
  @include msgIllustration;
}

.descIllustration {
  @include descIllustration;
}

.wrapLoadMore {
  height: 2rem;
  margin: 0 0 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
