@import "app/assets/stylesheets/_mixins.scss";

// @import "../input/input.scss";

.inputContainer {
  @include inputContainer;

  &.labelInRow {
    @include inputLabelinrow;
  }
}

.label {
  @include inputLabel;
  height: 1.5rem;
}

.input {
  @include input;

  $placeholder-declarations-list: "::-webkit-input-placeholder" "::-moz-placeholder"
    ":-ms-input-placeholder" ":-moz-placeholder";

  @each $declaration in $placeholder-declarations-list {
    &#{$declaration} {
      /* Chrome/Opera/Safari */
      font-weight: 500;
      text-transform: capitalize;
    }
  }
}

.menu {
  width: 100%;
  max-height: 250px;
  font-size: var(--font-sm);
  color: var(--text);
  background: var(--white);
  box-sizing: border-box;
  box-shadow: var(--shadow-dp8);
  overflow-y: scroll;
  position: absolute;
  z-index: 1000;
}

.elementHeader {
  font-size: var(--font-xs);
  font-weight: var(--semi-bold);
  background: var(--bg);
  border-bottom: 1px solid var(--bg-d5);
  padding: 0.5rem 0.35rem;
  pointer-events: none;
}

.element {
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: 0.25rem 0.35rem;

  &.highlighted {
    background: var(--bg-hover);
  }
}

.itemElement {
  font-size: var(--font-sm);
  line-height: 1.125rem;
  color: var(--text);
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.mainInfo {
  font-weight: var(--semi-bold);
  line-height: 1.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  span {
    font-weight: var(--regular);
    &:before {
      content: "\2013";
      margin: 0 0.25rem;
    }
  }
}

.secondaryInfo {
  font-size: var(--font-xs);
  color: var(--text-a40);
}

.searchMsg {
  min-height: 1.5rem;
  font-size: var(--font-xs);
  line-height: 1.125rem;
  color: var(--text-a40);
  padding: 0.25rem 0.35rem;
}

.errorMsg {
  @include inputErrorMsg;
}
