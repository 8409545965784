@import "app/assets/stylesheets/_mixins.scss";

.inputContainer {
  @include inputContainer;

  --react-international-phone-height: 30px;
}

.wrapLabel {
  @include inputWrapLabel;
}

.label {
  @include inputLabel;
}

.errorMsg {
  @include inputErrorMsg;
}

:global {
  .react-international-phone-input-container {
    width: 100% !important;
    border: none;
    border: 1px solid var(--input-border) !important;
    border-radius: 0.25rem !important;
  }

  .withErrors .react-international-phone-input-container {
    border-color: var(--red) !important;
  }

  .react-international-phone-input {
    width: 100% !important;
    border: none !important;
    outline: none !important;
  }

  .react-international-phone-country-selector-button {
    border: none !important;
    outline: none !important;
    border-right: 1px solid var(--input-border) !important;
  }

  .react-international-phone-country-selector-dropdown {
    border: 1px solid var(--input-border) !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
    outline: none;
    box-shadow: none !important;
  }
}
