@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsShow.scss";

.showDomains {
  @include listDS;

  &.notHealty {
    grid-template-rows: auto 2rem auto minmax(0px, 1fr);
    grid-template-areas: "healthyMsg" "navigationDS" "headerDS" "settingsDS";

    @include media-sm {
      grid-template-rows: auto 2rem 6rem minmax(0px, 1fr);
      grid-template-areas: "healthyMsg" "navigationDS" "headerDS" "settingsDS";
    }
  }

  &.hideHeader {
    grid-template-rows: minmax(0px, 1fr);
    grid-template-areas: "settingsDS";
  }
}

// healty message
.healthyMsg {
  grid-area: healthyMsg;
}

.warningHMsg {
  width: 100%;
  min-height: 2.5rem;
  background: var(--yellow);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-sm {
    padding: 0.75rem 1rem;
    flex-direction: row;
  }
}

.wrapHMsg {
  margin: 0 0 1rem 0;

  @include media-sm {
    margin: 0;
  }
}

.textHMsg {
  font-size: var(--font-base);
  font-weight: var(--semi-bold);
  line-height: 1rem;
  color: var(--text);
  margin: 0 0 0.25rem 0;
}

.descHMsg {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--text-a60);

  & > a {
    font-weight: var(--semi-bold);
    color: var(--text);
    text-decoration: underline;
  }
}

.docsHMsg {
  margin-top: 4px;
}

.docsHLink {
  margin-left: 4px;
}

.btnHMsg {
  min-width: 50%;
  white-space: nowrap;

  @include media-sm {
    min-width: inherit;
    margin: 0 0 0 2rem;
  }
}

// navigation
.navigation {
  @include navigationDS;
}

.viewSectionLabel {
  @include viewSection;
}

// header
.header {
  @include headerDS;

  .hideHeader & {
    display: none;
  }

  .isPostmaster & {
    border-bottom: 1px solid var(--bg-d5);
    box-sizing: border-box;
    padding: 1rem 0 0 0;
    margin: 0 1rem;
    @include media-xxl {
      padding: 1rem 0 0 0;
      margin: 0 4rem;
    }
  }
}

.wrapTitle {
  @include wrapTitleDS;
  justify-content: space-between;
}

.showInfo {
  font-size: var(--font-sm);
}

.subtitle {
  @include subtitleDS;
}

.info {
  @include infoDS;
}

// settings
.settings {
  @include settingsDS;
  // padding: 0 !important;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3.5rem minmax(0px, 1fr);
  grid-template-areas: "tabs" "content";
  // position: relative;

  @include media-sm {
    grid-template-rows: 2.5rem minmax(0px, 1fr);
    position: relative;
  }

  .hideHeader & {
    grid-template-rows: minmax(0px, 1fr);
    grid-template-areas: "content";

    @include media-sm {
      padding: 2rem 0 0 0;
    }
  }

  .showSettings & {
    grid-template-rows: minmax(0px, 1fr);
    grid-template-areas: "tabs";
  }

  .isPostmaster & {
    grid-template-rows: minmax(0px, 1fr);
    grid-template-areas: "content";
    padding: 0;
  }
}

.scrollTabs {
  grid-area: tabs;
  margin: 0 1rem;
  overflow-x: auto;
  //-webkit-overflow-scrolling: touch;

  @include media-xxl {
    margin: 0 4rem;
  }
}

.tabs {
  height: 3rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .hideHeader & {
    display: none;
  }

  @include media-sm {
    height: 2.5rem;
    border-bottom: 1px solid var(--bg-d5);
    justify-content: flex-start;
  }
}

.itemTab {
  height: 3rem;
  font-size: var(--font-base);
  line-height: 3rem;
  white-space: nowrap;
  color: var(--text);
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: 0 1rem;
  display: flex;
  align-items: center;

  @include media-sm {
    height: 2.5rem;
    font-size: var(--font-sm);
    line-height: 2.5rem;
    border-bottom: 2px solid transparent;
    padding: 0;
    margin: 0 1rem 0 0;

    cursor: pointer;

    &:hover {
      color: var(--accent);
      .iconTabs svg * {
        stroke: var(--accent);
      }
    }
  }

  &.active {
    color: var(--accent);
    border-bottom: 2px solid var(--accent);
  }
}

.settingsTab {
  justify-self: flex-end;
}

.iconTabs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0.25rem 0 0;
  .active & {
    svg * {
      stroke: var(--accent);
    }
  }
}

.settings > div:nth-of-type(2) {
  grid-area: content;
  justify-self: stretch;
  display: grid;

  & > div:first-of-type {
    justify-self: stretch;
  }
}

.wrapFulldMsg {
  grid-area: content;
  background: rgba(255, 255, 255, 0.85);
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  z-index: var(--z-index-2);
}

.wrapIllustration {
  @include wrapIllustration;
}

.msgIllustration {
  @include msgIllustration;
}
