@import "app/assets/stylesheets/_mixins.scss";

// new stepper style
.stepper {
  width: 100%;
  height: 2rem;
  padding: 0 1rem 0 0.5rem;
  display: grid;
  grid-template-columns: 4.5rem 1fr 4rem;
  grid-template-areas: "stepBtnBack wrapSteps .";
  align-items: center;
  @include media-sm {
    width: auto;
    padding: 0;
  }
}

.stepBtnBack {
  grid-area: stepBtnBack;
}

.wrapSteps {
  grid-area: wrapSteps;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step {
  width: 2rem;
  height: 0.125rem;
  background: var(--bg-d5);
  margin: 0 0.25rem 0 0;
  &:last-of-type {
    margin: 0;
  }

  &.active {
    background: var(--accent);
  }

  @include media-sm {
    width: 3.5rem;
  }
}
