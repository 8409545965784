.errorsLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0;
  background-color: var(--red-l35);
  border-radius: var(--radius-4);
}

.errorList {
  padding: 0rem 1rem;
  font-size: var(--font-sm);
  max-height: 300px;
  overflow-y: auto;
}

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0;
  background-color: var(--bg);
  border-radius: var(--radius-4);
  height: 100px;
}

.dropzone.reject {
  background-color: var(--red-l35) !important;
  color: var(--red) !important;
}

.dropzone.accept {
  background-color: var(--green-l35) !important;
  color: var(--green) !important;
}

.acceptedContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  .filePreview {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
  }
}
