@import "app/assets/stylesheets/_mixins.scss";

.radioGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.radioChildren {
  width: 100%;
  display: flex;
  flex-direction: column;
  .childreninrow & {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.groupLabel {
  width: 100%;
  height: 1.75rem;
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  line-height: 1.25rem;
  color: var(--text);
  opacity: 1;

  .required & {
    &:after {
      content: "\002A";
      font-size: var(--font-base);
      color: var(--accent);
      margin: 0 0 0 0.125rem;
    }
  }

  .labelinrow & {
    line-height: 2rem;
  }

  .disabled & {
    opacity: 0.5;
  }

  .withErrors & {
    color: var(--red);
  }
}

.radioBtn {
  width: 100%;
  min-height: 1.5rem;
  padding: 0.5rem 0;
  display: grid;
  grid-template-columns: 1.25rem 1fr;
  grid-template-areas: "radioInput wrapLabel";
  grid-column-gap: 0.5rem;
  align-items: start;
  position: relative;

  .childreninrow & {
    width: auto;
    margin: 0 1rem 0 0;
  }

  &.best::before {
    content: "";
    border-radius: 0.5rem;
    background: var(--accent-l35);
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 0;
    right: -0.5rem;
    bottom: 0;
    left: -0.5rem;
    z-index: var(--z-index-1);

    @include media-sm {
      right: -1rem;
      left: -1rem;
    }
  }
}

.radioInput {
  grid-area: radioInput;
  width: 1.25rem;
  height: 1.25rem;
  background: var(--white);
  border: 1px solid var(--input-border-hover);
  box-sizing: border-box;
  border-radius: 50%;
  margin: 0.05rem 0 0 0;
  position: relative;
  outline: none;
  cursor: pointer;
  appearance: none;
  z-index: var(--z-index-2);

  .disabled & {
    opacity: 0.5;
    cursor: default;
  }
}

.radioInput:checked {
  border: 1px solid var(--accent);

  &::after {
    content: "";
    width: 0.75rem;
    height: 0.75rem;
    background: var(--accent);
    border-radius: 100%;
    position: absolute;
    top: 3px;
    left: 3px;
    animation: checking 0.15s linear;
  }
}

.wrapLabel {
  grid-area: wrapLabel;
  display: flex;
  flex-direction: column;
  z-index: var(--z-index-2);
}

.radioLabel {
  font-size: var(--font-sm);
  line-height: 1.25rem;
  color: var(--text);
  @include codeAndBold;

  .disabled & {
    opacity: 0.5;
  }
}

.bestLabel {
  font-size: var(--font-xs);
  color: var(--text);
  background: var(--yellow);
  border-radius: 1rem;
  padding: 0 0.375rem;
  margin: 0 0 0 0.5rem;
  text-transform: uppercase;
}

.description {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text-a40);
  padding: 0 0 0.25rem 0;
  @include codeAndBold;

  .disabled & {
    opacity: 0.5;
  }
}

@keyframes checking {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
