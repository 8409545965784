@import "app/assets/stylesheets/_mixins.scss";

.helpContainer {
  display: inline-block;

  @include media-sm {
    position: relative;
  }
}

.iconBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg * {
    fill: var(--accent);
  }
}

.helpWrap {
  // border-radius: 1px;
  display: none;
  position: absolute;
  top: 4rem;
  left: 1rem;
  z-index: 10;
  transform-origin: top left;

  @include media-sm {
    top: 1.25rem;
    left: 1.25rem;
  }
}

.showWrap {
  width: calc(100% - 2rem);
  border-radius: 2px;
  background: var(--white);
  box-shadow: var(--shadow-dp8);
  display: grid;
  grid-template-columns: [icon]2rem [helpContent]1fr;
  overflow: hidden;
  animation: scale 0.25s ease-in-out forwards;

  @include media-sm {
    width: 18rem;
  }
}

.icon {
  grid-area: icon;
  align-self: start;
  overflow: hidden;
  padding: 0.5rem 0 0 0;
  margin: 0 0 0 -1rem;
  svg * {
    fill: var(--bg-d5);
  }
}

.helpContent {
  grid-area: helpContent;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.helpTitle {
  font-size: var(--font-sm);
  color: var(--text);
  font-weight: var(--semi-bold);
  line-height: 1.25rem;
}

.helpDesciption {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--text);
  line-height: 1.125rem;
  margin: 0 0 0.5rem 0;
  code {
    font-family: monospace !important;
    color: var(--black);
    background: var(--bg-d5);
    padding: 0 0.25rem;
  }
  b,
  strong {
    font-weight: var(--semi-bold);
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1, 1);
  }
}
