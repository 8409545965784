@import "app/assets/stylesheets/_mixins.scss";

.content {
  @include overflowY;
}

.folderMapper {
  margin: 2rem 0 1rem 0;
}

.footer {
  button {
    min-width: calc(50% - 0.5rem);
  }

  @include media-sm {
    button {
      min-width: 6rem;

      &:last-of-type {
        margin: 0 0 0 0.5rem;
      }
    }
  }
}
