@import "app/assets/stylesheets/_mixins.scss";

.appContainer {
  width: 100%;
  height: calc(100vh - #{var(--safe-top)}); // fallback
  height: calc(#{var(--fullVh)} - #{var(--safe-top)});
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem minmax(0px, 1fr);
  grid-template-areas: "navbar" "content";

  @include media-sm {
    grid-template-columns: 15rem 1fr;
    grid-template-areas: "navbar navbar" "sidebar content";
  }

  @include media-md {
    grid-template-columns: 15rem 1fr;
    grid-template-areas: "navbar navbar" "sidebar content";

    &.compactSidebar {
      grid-template-columns: 3rem 1fr;
      grid-template-areas: "navbar navbar" "sidebar content";
    }
  }
}

.navbar {
  grid-area: navbar;
}

.sidebar {
  grid-area: content;
  height: calc(100vh - 3rem - #{var(--safe-top)} - #{var(--safe-bottom)}); // fallback
  height: calc(#{var(--fullVh)} - 3rem - #{var(--safe-top)} - #{var(--safe-bottom)});
  border-right: 1px solid var(--bg-d5);
  box-sizing: border-box;
  background: var(--bg);
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas: "sidebarItems" "sidebarFooter";
  z-index: var(--z-index-3);

  @include overflowY;
  @include media-sm {
    grid-area: sidebar;
    display: inherit;
  }
}

.sidebarItems {
  grid-area: sidebarItems;
  padding: 1rem 0 2rem 0;
  @include media-sm {
    @include overflowY;
  }
}

.sidebarFooter {
  grid-area: sidebarFooter;
  align-self: center;
  padding: 0.5rem;
}

.content {
  grid-area: content;
  height: calc(100vh - 3rem - #{var(--safe-top)} - #{var(--safe-bottom)}); // fallback
  height: calc(#{var(--fullVh)} - 3rem - #{var(--safe-top)} - #{var(--safe-bottom)});
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(0px, 1fr);
  justify-content: center;

  @include media-sm {
    grid-area: sidebar / sidebar / content / content;
  }

  @include media-md {
    grid-area: content;
  }
}

// trial modal

.bodyTrailModal {
  align-self: center;
  padding: 1.5rem 1rem 1.5rem 1.5rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-sm {
    flex-direction: row;
  }
}

.wrapDaysTrial {
  min-width: 8rem;
  height: 7.5rem;
  border: 1px solid var(--bg-d5);
  box-sizing: border-box;
  background: var(--bg);
  margin: 0 0 2rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media-sm {
    margin: 0 1rem 0 0;
  }
}

.daysTrial {
  font-size: 80px;
  font-weight: var(--semi-bold);
  color: var(--accent);
  line-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  .wranExpire & {
    color: var(--yellow);
  }
  .expire & {
    color: var(--red);
  }
}

.daysTextTrial {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.5rem;
  color: var(--accent);
  text-align: center;
  text-transform: uppercase;
  .wranExpire & {
    color: var(--yellow);
  }
  .expire & {
    color: var(--red);
  }
}

.msgTtrial {
  text-align: center;
  @include media-sm {
    text-align: left;
  }
}

.footerTrial {
  button {
    min-width: calc(50% - 0.5rem);
  }

  @include media-sm {
    justify-self: end;

    button {
      min-width: 5rem;

      &:last-of-type {
        margin: 0 0 0 0.5rem;
      }
    }
  }
}
