@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsList.scss";

.dashboardContainer {
  overflow-y: scroll;
  width: 100%;
  padding: 20px 16px;

  @include media-md {
    padding: 20px 32px;
  }
}

.dashboard {
  margin: auto;
  max-width: 1280px;
}

// header
.headerList {
  padding: 0 1rem;
  margin: 0 0 1rem 0;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 2.5rem;
  grid-template-areas: "titleList actionsList";
  align-items: center;

  @include media-sm {
    padding: 0 2rem;
    margin: 0;
    grid-template-columns: 25rem 1fr;
    grid-template-areas: "titleList actionsList";
  }

  @include media-lg {
    grid-template-columns: 30rem minmax(0px, 1fr);
  }
}

.titleList {
  @include titleListMSS;
}

.descList {
  @include descListMSS;
}

.actionsList {
  @include actionsListMSS;
}

.btnList {
  // to hide docs button from dashboard page
  display: none;
}

// content
.content {
  grid-area: contentList;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "introSection" "titleSection" "numberSection" "chartSection";
  grid-row-gap: 1rem;
  @include overflowY;
  z-index: var(--z-index-0);
}

.introSection {
  grid-area: introSection;
}

.titleSection {
  grid-area: titleSection;
  padding: 0.5rem 0rem;
}

//numbers info section
.numberSection {
  // grid-area: numberSection;
  // background: var(--bg);
  // border-top: 1px solid var(--bg-d5);
  // border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: 0.5rem 0px;

  @include media-sm {
    padding: 1rem 0px;
  }
}

// charts section
.chartSection {
  grid-area: chartSection;
  padding: 0 0px 2rem 0px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "maxQuotaChart" "domainChart" "emailAccountChart" "domainAliasChart" "emailAliasChart" "topDomainChart";
  grid-row-gap: 2rem;

  @include media-sm {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-template-areas: "maxQuotaChart domainChart" "emailAccountChart domainAliasChart" "emailAliasChart topDomainChart";
    grid-column-gap: 2rem;
  }
}

.maxQuotaChart {
  grid-area: maxQuotaChart;
}

.domainChart {
  grid-area: domainChart;
}

.emailAccountChart {
  grid-area: emailAccountChart;
}

.domainAliasChart {
  grid-area: domainAliasChart;
}

.emailAliasChart {
  grid-area: emailAliasChart;
}

.topDomainChart {
  grid-area: topDomainChart;
}

// single chart
.wrapChart {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2rem 1fr;
  grid-template-areas: "headerChart" "dataChart";
  align-items: center;
}

.chartCanvasContainer {
  position: relative;
  min-width: 0px;
  height: 100%;
}

.headerChart {
  grid-area: headerChart;
  font-size: var(--font-sm);
  color: var(--text);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.daySwitch {
  height: 1.5rem;
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.itemSwitch {
  height: 1.5rem;
  font-size: var(--font-sm);
  color: var(--text-a40);
  border-bottom: 2px solid transparent;
  box-sizing: border-box;
  padding: 0 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-out;
  cursor: pointer;

  &.active {
    color: var(--accent);
    border-bottom-color: var(--accent);
  }

  span {
    font-size: var(--font-xs);
    margin: 0 0 0 0.125rem;
  }
}

.dataChart {
  grid-area: dataChart;
  height: 100%;
  width: 100%;
}

.chartContainer {
  height: 200px;
  width: 100%;
  position: relative;
}
