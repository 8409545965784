@import "app/assets/stylesheets/_mixins.scss";

.box {
  width: 14rem;
  height: 5.5rem;
  border-radius: 1px;
  background: var(--accent-l35);
  overflow: hidden;
  padding: 0.375rem 0.5rem 0.25rem 0.5rem;
  display: grid;
  grid-template-rows: 1rem 1rem 1.5rem 1rem;
  position: relative;
}

.question {
  font-size: var(--font-xs);
  font-weight: var(--regular);
  line-height: 1rem;
  color: var(--text);
}

.cta {
  font-size: var(--font-xs);
  font-weight: var(--semi-bold);
  line-height: 1rem;
  color: var(--text);
}

.phone {
  align-self: end;
  font-size: var(--font-base);
  font-weight: var(--regular);
  text-decoration: none;
  line-height: 1.25rem;
  color: var(--accent);
  &:hover {
    text-decoration: underline;
    color: var(--accent-d30);
  }
}

.schedule {
  font-size: var(--font-xs);
  font-weight: var(--regular);
  line-height: 1rem;
  color: var(--text-a40);
}

.icon {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  svg * {
    stroke: var(--white);
    opacity: 0.75;
  }
}
