@import "app/assets/stylesheets/_mixins.scss";

.wrapInfoBilling {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text-a40);

  @include codeAndBold;

  display: flex;
  flex-direction: column;

  & div {
    margin: 0 0 0.5rem 0;
  }
}

.labelInfoBilling {
  width: 100%;
  height: 1.75rem;
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  line-height: 1.25rem;
  color: var(--text);
}

// annual counter
.counter {
  display: grid;
  grid-template-columns: [labelCounter]1fr [priceCounter]auto [inputCounter]7rem;
  grid-column-gap: 1rem;
  align-items: center;
}

.labelCounter {
  grid-column: labelCounter;
  font-size: var(--font-sm);
  color: var(--text);
  @include codeAndBold;
}

.priceCounter {
  grid-column: priceCounter;
  font-size: var(--font-sm);
  color: var(--text-a40);
  span:before {
    content: "€";
    margin: 0 0.25rem 0 0;
  }
  span:after {
    content: "x";
    margin: 0 0 0 0.25rem;
  }
}

.inputCounter {
  grid-column: inputCounter;
}

// montly accounts active
.tableAccountActive {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: 1.5rem repeat(3, 2rem);
  align-items: center;
}

.headerAccountActive {
  font-size: var(--font-xs);
  font-weight: var(--semi-bold);
  line-height: 1.5rem;
  color: var(--text);
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  background: var(--bg);
  padding: 0 0.5rem;
}

.rowAccountActive {
  border-bottom: 1px solid var(--bg-d5);
  padding: 0 0.5rem;
  display: grid;
  grid-template-columns: 1fr 3rem;
  align-items: center;

  div {
    font-size: var(--font-sm);
    font-weight: var(--regular);
    color: var(--text);
    line-height: 2rem;
  }
  div:last-of-type {
    justify-self: center;
    font-weight: var(--semi-bold);
  }
}

.infoMontlyorder {
}

//  estimate
.estimate {
  background: var(--bg);
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1.5rem auto auto 2rem;
  grid-template-areas: "labelFee priceFee" "labelDiscount priceDiscount" "billing billing" "priceVat priceVat";
  grid-column-gap: 0.5rem;
  align-items: center;
}

.labelFee {
  grid-area: labelFee;
  font-size: var(--font-sm);
  font-weight: var(--bold);
  color: var(--text);
}

.priceFee {
  grid-area: priceFee;
  font-size: var(--font-sm);
  font-weight: var(--bold);
  color: var(--text);
  span:before {
    content: "€";
    width: 1.25rem;
    text-align: right;
    margin: 0 0.25rem 0 0;
    display: inline-block;
  }
}

.labelDiscount {
  grid-area: labelDiscount;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.5rem;
  color: var(--text);
}

.priceDiscount {
  grid-area: priceDiscount;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.5rem;
  color: var(--text);
  span:before {
    content: "– €";
    width: 1.25rem;
    text-align: right;
    margin: 0 0.25rem 0 0;
    display: inline-block;
  }
}

.billing {
  grid-area: billing;
  padding: 0.5rem 0 0 0;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.125rem;
  color: var(--text-a40);
}

.priceVat {
  grid-area: priceVat;
  align-self: end;
  font-size: var(--font-xs);
  color: var(--text);
  code {
    font-family: monospace !important;
    color: var(--black);
    background: var(--bg-d5);
    padding: 0 0.25rem;
  }
  b,
  strong {
    font-weight: var(--semi-bold);
  }
}
