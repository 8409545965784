@import "app/assets/stylesheets/_mixins.scss";

.etliveFilters {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: "type" "domain" "emailAccount" "dayPicker" "showMore";
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: flex-start;

  &.showAdvanced {
    grid-template-areas: "type" "domain" "emailAccount" "dayPicker" "hourPicker" "dynamicSelect" "showMore";
  }

  &.isPostmaster {
    grid-template-areas: "type" "emailAccount" "dayPicker" "showMore";
  }

  &.isPostmaster.showAdvanced {
    grid-template-areas: "type" "emailAccount" "dayPicker" "hourPicker" "dynamicSelect" "showMore";
  }

  &.isEmailAccount {
    grid-template-areas: "type" "dayPicker" "showMore";
  }

  &.isEmailAccount.showAdvanced {
    grid-template-areas: "type" "dayPicker" "hourPicker" "dynamicSelect" "showMore";
  }

  @include media-sm {
    grid-template-columns: repeat(2, 1fr) 5.5rem;
    grid-template-rows: auto auto;
    grid-template-areas: "type domain showMore" "emailAccount dayPicker .";

    &.showAdvanced {
      grid-template-rows: auto auto auto;
      grid-template-areas: "type domain showMore" "emailAccount dayPicker ." "hourPicker dynamicSelect .";
    }

    &.isPostmaster {
      grid-template-areas: "type emailAccount showMore" "dayPicker  . .";
    }

    &.isPostmaster.showAdvanced {
      grid-template-rows: auto auto auto;
      grid-template-areas: "type emailAccount showMore " "dayPicker hourPicker ." "dynamicSelect . .";
    }

    &.isEmailAccount {
      grid-template-rows: auto;
      grid-template-areas: "type dayPicker showMore";
    }

    &.isEmailAccount.showAdvanced {
      grid-template-rows: auto auto;
      grid-template-areas: "type dayPicker showMore" " hourPicker dynamicSelect .";
    }
  }

  @include media-lg {
    grid-template-columns: repeat(4, 1fr) 5.5rem;
    grid-template-rows: auto;
    grid-template-areas: "type domain emailAccount dayPicker showMore";

    &.showAdvanced {
      grid-template-rows: auto auto;
      grid-template-areas: "type domain emailAccount dayPicker showMore" "hourPicker dynamicSelect . . .";
    }

    &.isPostmaster {
      grid-template-rows: auto;
      grid-template-areas: "type emailAccount dayPicker  . showMore";
    }

    &.isPostmaster.showAdvanced {
      grid-template-rows: auto auto;
      grid-template-areas: "type emailAccount dayPicker . showMore" "hourPicker dynamicSelect . . .";
    }

    &.isEmailAccount {
      grid-template-rows: auto;
      grid-template-areas: "type dayPicker . . showMore";
    }

    &.isEmailAccount.showAdvanced {
      grid-template-rows: auto;
      grid-template-areas: "type dayPicker hourPicker dynamicSelect showMore";
    }
  }
}

.type {
  grid-area: type;
}

.domain {
  grid-area: domain;
}

.emailAccount {
  grid-area: emailAccount;
}

.dayPicker {
  grid-area: dayPicker;
}

.hourPicker {
  grid-area: hourPicker;
  display: flex;
  align-items: flex-start;
  & .hourPickerFrom {
    margin: 0 0.5rem 0 0;
  }
}

.dynamicSelect {
  grid-area: dynamicSelect;
}

.showMore {
  grid-area: showMore;
  justify-self: end;
  align-self: end;

  font-size: var(--font-sm);
  text-decoration: underline;
  line-height: 2rem;
  color: var(--accent);
  cursor: pointer;
}
