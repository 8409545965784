@import "app/assets/stylesheets/_mixins.scss";

.congratulation {
  justify-self: center;
  padding: 1rem 1rem 0 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 2rem auto 4rem;
  grid-template-areas: "illustration" "title" "text" "btn";
  align-content: flex-start;

  @include media-sm {
    width: 26rem;
    padding: 3rem 0 0 0;
  }
}

.illustration {
  grid-area: illustration;
  justify-self: center;
}

.title {
  grid-area: title;
}

.text {
  grid-area: text;
  font-size: var(--font-sm);
  line-height: 1.25rem;
  color: var(--text);
  padding: 0 0 1rem 0;
  @include codeAndBold;
}

.btn {
  grid-area: btn;
  align-self: end;
}
