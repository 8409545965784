@import "app/assets/stylesheets/_mixins.scss";

.checkboxInput {
  width: 100%;
  min-height: 1.5rem;
  padding: 0.5rem 0;
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  grid-template-areas: "checkbox label";
  grid-column-gap: 0.5rem;

  @include media-md {
    grid-template-columns: 1.25rem 1fr;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.vianovaCheckbox {
    padding: 0;
  }
}

.checkbox {
  grid-area: checkbox;
  width: 1.5rem;
  height: 1.5rem;
  // background: var(--white);
  border: 1px solid var(--input-border-hover);
  border-radius: 0.125rem;
  box-sizing: border-box;

  appearance: none;
  position: relative;
  cursor: pointer;

  @include media-md {
    width: 1.25rem;
    height: 1.25rem;
    margin: 0.125rem 0 0 0;
  }

  &:checked {
    background: var(--accent);
    border-color: var(--accent);

    &:after {
      content: "";
      background: var(--white);
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 var(--white), 4px 0 0 var(--white), 4px -2px 0 var(--white), 4px -4px 0 var(--white),
        4px -6px 0 var(--white), 4px -8px 0 var(--white);
      transform: rotate(45deg);
      position: absolute;
      left: 6px;
      top: 10px;

      @include media-md {
        left: 4px;
        top: 8px;
      }
    }
  }

  .indeterminate & {
    background: var(--accent);
    border-color: var(--accent);

    &:after {
      content: "";
      background: var(--white);
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 var(--white), 4px 0 0 var(--white);
      position: absolute;
      left: 6px;
      top: 10px;

      @include media-md {
        left: 4px;
        top: 8px;
      }
    }
  }

  &:focus {
    outline: none !important;
  }

  &:-webkit-autofill {
    box-shadow: inset 0 0 0px 9999px var(--accent-l35);
  }

  .withErrors & {
    border-color: var(--red);
  }

  .disabled & {
    cursor: default;
  }
}

.label {
  grid-area: label;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.5rem;
  color: var(--text);
  .withErrors & {
    color: var(--red);
  }
}
.fontRegular {
  font-size: var(--font-base);
}
