@import "app/assets/stylesheets/_mixins.scss";

.detailStatus {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  box-sizing: border-box;
  background: var(--bg);

  &.enabled {
    background: var(--green);
  }

  &.disabled {
    background: var(--yellow);
  }
}
