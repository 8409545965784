@import "app/assets/stylesheets/_mixins.scss";

.responsiveTable {
  width: 100%;
  font-size: var(--font-base);
  font-weight: var(--regular);
  color: var(--text);
  border-collapse: collapse;
  box-sizing: border-box;
  border: none;
  display: table;

  @include media-sm {
    font-size: var(--font-sm);
  }

  thead {
    height: 1.75rem;
    display: table-header-group;
  }

  thead tr {
    background: var(--bg);
    border-bottom: 1px solid var(--bg-d5);
    box-sizing: border-box;
  }

  thead th {
    font-size: var(--font-sm);
    font-weight: var(--semi-bold);
    color: var(--text);
    line-height: 1.75rem;
    text-align: left;
    padding: 0 0.25rem;

    &.hideOnMobile {
      display: none;

      @include media-sm {
        display: table-cell;
      }
    }

    &.justifyCenter {
      text-align: center;
    }

    &.justifyEnd {
      text-align: right;
    }

    &.sortable:hover {
      background: var(--bg-hover);
      cursor: pointer;
    }
  }

  tbody {
    display: table-row-group;

    :global {
      td.hideOnMobile {
        display: none;
        @include media-sm {
          display: table-cell;
        }
      }

      td.center {
        text-align: center;
      }

      td.textOverflow {
        max-width: 0;
        @include textOverflow;
      }
    }
  }

  tbody tr {
    height: 3rem;

    @include media-sm {
      height: 2.5rem;
    }

    @include media-md {
      &:hover {
        background: var(--bg);
      }
    }

    &.loading,
    &.noFoundMsg {
      &:hover {
        background: none;
      }
    }
  }

  tbody td {
    height: 3rem;
    border: none;
    border-bottom: 1px solid var(--bg-d5);
    box-sizing: border-box;
    padding: 0 0.25rem;

    @include media-sm {
      height: 2.5rem;
    }
  }

  &.verticalOnMobile {
    display: block;

    @include media-sm {
      border: none;
      display: table;
    }

    thead {
      display: none;
      @include media-sm {
        display: table-header-group;
      }
    }

    thead tr {
      background: none;
      border: none;
      @include media-sm {
        background: var(--bg);
        border-bottom: 1px solid var(--bg-d5);
        box-sizing: border-box;
      }
    }

    tbody {
      display: flex;
      flex-direction: column;

      @include media-sm {
        display: table-row-group;
      }
    }

    tbody tr {
      height: auto;
      border-top: 1px solid var(--bg-d5);
      border-right: 1px solid var(--bg-d5);
      border-left: 1px solid var(--bg-d5);
      box-sizing: border-box;
      margin: 0 0 1rem 0;
      display: block;

      @include media-sm {
        height: 2.5rem;
        border: none;
        margin: 0;
        display: table-row;
        &:hover {
          background: var(--bg);
        }
      }

      &.loading,
      &.noFoundMsg {
        &:hover {
          background: none;
        }
      }
    }

    tbody td {
      height: 2.5rem;
      border: none;
      border-bottom: 1px solid var(--bg-d5);
      box-sizing: border-box;
      padding: 0;
      display: flex;
      align-items: center;
      @include media-sm {
        padding: 0 0.25rem;
        display: table-cell;
      }
    }

    tbody td:before {
      content: attr(data-label);
      min-width: 5rem;
      max-width: 5rem;
      height: 2.5rem;
      line-height: 2.5rem;
      font-size: var(--font-sm);
      font-weight: var(--semi-bold);
      color: var(--text);
      border-bottom: 1px solid var(--bg-d5);
      box-sizing: border-box;
      background: var(--bg);
      padding: 0 0.25rem 0 0.5rem;
      margin: 0 0.5rem 0 0;
      display: block;
      align-items: center;
      @include textOverflow;

      // @include media-sm {
      //   width: 8rem;
      // }
      @include media-sm {
        display: none;
      }
    }
  }
}

.wrapIllustration {
  @include wrapIllustration;
}

.msgIllustration {
  @include msgIllustration;
}

.descIllustration {
  @include descIllustration;
}

.loading {
  border: none !important;
  td {
    height: 11rem !important;
    border: none !important;
    justify-content: center;
    &:before {
      display: none !important;
    }
  }
}

.noFoundMsg {
  border: none !important;
  td {
    height: 11rem !important;
    border: none !important;
    justify-content: center;
    &:before {
      display: none !important;
    }
  }
}

.iconSort {
  vertical-align: middle;
  margin: -0.125rem 0 0 0.25rem;
}

.iconSortActive {
  margin: 0 0 0 0.25rem;
  svg * {
    fill: var(--accent);
  }
}

.iconPadding {
  padding: 16px;
}
