@import "app/assets/stylesheets/_mixins.scss";
// @import "../components/input/input.scss";

.container {
  display: flex;
  justify-content: center;
  @include overflowY;
  padding: 1rem;

  @media (min-width: 768px) {
  }
}

.content {
  padding-bottom: 4rem;
}

.header {
  font-size: var(--font-xs);
  font-weight: var(--semi-bold);
  line-height: 1.5rem;
  color: var(--text);
  background: var(--bg);
  border-bottom: 1px solid var(--bg-d5);
  padding: 0 0.5rem;
  align-items: center;
}

.request {
  border: 1px solid var(--bg);
  font-size: var(--font-sm);
  margin-bottom: 1rem;
}

.body {
  padding: 1rem 0.5rem;
}

.request label {
  color: var(--text-a40);
}

.meta {
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding-top: 2rem;
}

.denied {
  color: var(--red);
  font-weight: var(--bold);
}

.completed {
  color: var(--green);
  font-weight: var(--bold);
}

.runningMsg {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.expirationDate {
  margin-top: 1rem;
}

.noRequests {
  font-size: var(--font-sm);
  line-height: 1.5rem;
  color: var(--text-a40);
}

.docsBtnContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 1.5rem;
}
