@import "app/assets/stylesheets/_mixins.scss";

.justifyCenter {
  text-align: center !important;
}

.disabled td {
  opacity: 0.5;
  pointer-events: none;
}

tr td:first-child,
tr td:last-child {
  opacity: 1;
  pointer-events: all;
}

.domainName {
  overflow-x: scroll;
}
