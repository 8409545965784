@import "app/assets/stylesheets/_mixins.scss";

// style used for details sections strucutre like domains/Show

@mixin listDS {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2rem auto minmax(0px, 1fr);
  grid-template-areas: "navigationDS" "headerDS" "settingsDS";

  @include media-sm {
    padding: 0 0 1rem 0;
    grid-template-rows: 2rem 6rem minmax(0px, 1fr);
  }
}

// navigation
@mixin navigationDS {
  grid-area: navigationDS;
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  margin: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @include media-xxl {
    margin: 0 4rem;
  }
}

@mixin viewSection {
  font-size: var(--font-xs);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 1.5rem;
  color: var(--text-a40);
}

// header
@mixin headerDS {
  grid-area: headerDS;
  padding: 1rem 1rem 0 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2rem auto auto;
  grid-template-areas: "wrapTitleDS" "subtitle" "info";
  align-items: center;

  @include media-sm {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 2rem 1.5rem;
    grid-template-areas: "wrapTitleDS info" "subtitle info";
  }

  @include media-lg {
    grid-template-columns: 1fr 1fr;
  }

  @include media-xxl {
    padding: 1rem 4rem 0 4rem;
  }
}

@mixin wrapTitleDS {
  grid-area: wrapTitleDS;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin subtitleDS {
  grid-area: subtitle;
  align-self: center;
  height: 2rem;
  display: flex;
  align-items: center;

  div:first-child {
    margin: 0 0.25rem 0 0;
  }
}

@mixin infoDS {
  grid-area: info;
  align-self: start;
  background: var(--bg);
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include media-sm {
    justify-self: end;
    background: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0.5rem 0 0 0;

    & > div {
      margin: 0 0 0 1rem;
    }
  }
}

@mixin otherInfoDS {
  grid-area: otherInfo;
  padding: 0 0 0 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  & > div {
    margin: 0 1rem 0 0;
  }

  @include media-sm {
    padding: 0 0 0 2rem;
  }

  @include media-md {
    justify-self: end;
    padding: 0;
    flex-wrap: nowrap;
    align-items: center;

    & > div {
      margin: 0 0 0 1rem;
    }
  }
}

// settings
@mixin settingsDS {
  grid-area: settingsDS;
  // padding: 0 1rem;
  //
  // @include media-sm {
  //   padding: 0 2rem;
  // }
}
