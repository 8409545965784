@import "app/assets/stylesheets/_mixins.scss";

.form {
  padding: 1rem;
  display: grid;
  grid-template-rows: [repeater]auto [footerBtn]3rem;
}

.repeater {
  grid-row: repeater;
  align-self: start;
}

.footerBtn {
  grid-row: footerBtn;
  align-self: center;
  display: flex;
  justify-content: space-between;

  button {
    min-width: calc(50% - 0.5rem);
  }

  @include media-sm {
    justify-self: end;
    align-self: end;
    justify-content: flex-end;

    button {
      min-width: 6rem;

      &:last-of-type {
        margin: 0 0 0 0.5rem;
      }
    }
  }
}
