@import "app/assets/stylesheets/_mixins.scss";

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  width: 100%;
  height: 1.75rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  text-align: center;
  line-height: 1.25rem;
  color: var(--text);
}

.pin {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-sm {
    width: 22rem;
  }

  input {
    @include input;
    font-size: 2.5rem !important;
    height: 4rem !important;
    text-align: center;
    -moz-appearance: textfield !important;
    position: relative;

    .withErrors & {
      color: var(--red) !important;
      animation: shake 0.62s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
  }
}

.resetLink {
  height: 2rem;
  font-size: var(--font-sm);
  text-decoration: underline;
  color: var(--accent);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
