@import "app/assets/stylesheets/_mixins.scss";

.resourcesCounter {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  @include media-sm {
    grid-template-columns: repeat(4, 1fr);
  }

  @include media-lg {
    grid-template-columns: repeat(6, 1fr);
  }

  @include media-xxl {
    grid-template-columns: repeat(8, 1fr);
  }
}

.infoBox {
  border: 1px solid var(--bg-d5);
  background: var(--bg);
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleBox {
  font-size: var(--font-sm);
  color: var(--text-a40);
  line-height: 1rem;
  text-align: center;
}

.numberBox {
  font-size: var(--font-xl);
  font-weight: var(--semi-bold);
  color: var(--text);
  line-height: 2rem;
}
