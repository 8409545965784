.resourcesSelector {
  width: 100%;
  margin: 0 0 2rem 0;
  display: flex;
  flex-direction: column;
}

.wrapSelectionBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0 0;
}

.labelSelectionBox {
  height: 1.75rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text);
}

.selectionBox {
  width: 100%;
  max-height: 15rem;
  border: 1px solid var(--input-border);
  border-radius: 0.25rem;
  padding: 0.5rem;
  overflow-y: scroll;
}

.disabledCheckbox {
  label:after {
    content: "\002A";
  }
}

.disabledMsg {
  width: 100%;
  height: 1.5rem;
  font-size: var(--font-sm);
  line-height: 1.5rem;
  color: var(--accent-d30);
  background: var(--accent-l35);
  border-radius: 0.25rem;
  padding: 0 0.5rem;
  margin: 0.5rem 0 0 0;
  display: flex;
  align-items: center;

  &:before {
    content: "\2217";
    font-size: var(--font-xxl);
    font-weight: var(--bold);
    color: var(--accent);
    margin: 0 0.25rem 0.125rem 0;
  }
}
