@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsList.scss";

.listCustomizations {
  @include listMSS;
}

// header
.headerList {
  @include headerListMSS;
}

.titleList {
  @include titleListMSS;
}

.descList {
  @include descListMSS;
}

.actionsList {
  @include actionsListMSS;
}

// content
.contentList {
  @include contentList;
  grid-template-rows: 3rem auto minmax(0px, 1fr);
  grid-template-areas: "tabs" "wrapInfoBtn" "table";

  @include media-sm {
    grid-template-rows: 2.5rem auto minmax(0px, 1fr);
    overflow-y: hidden;
  }

  @include media-lg {
    grid-template-rows: 2.5rem auto minmax(0px, 1fr);
  }
}

// tabs

.scrollTabs {
  grid-area: tabs;
  align-self: center;
  margin: 0 1rem;
  overflow-x: auto;
  overflow-x: overlay;
  -webkit-overflow-scrolling: touch;

  @include media-xxl {
    margin: 0 4rem;
  }
}

.tabs {
  height: 3rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-sm {
    height: 2.5rem;
    border-bottom: 1px solid var(--bg-d5);
    justify-content: flex-start;
  }
}

.itemTab {
  flex: 1;
  height: 3rem;
  font-size: var(--font-base);
  line-height: 3rem;
  white-space: nowrap;
  color: var(--text);
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-sm {
    flex: initial;
    height: 2.5rem;
    font-size: var(--font-sm);
    line-height: 2.5rem;
    border-bottom: 2px solid transparent;
    padding: 0;
    margin: 0 1rem 0 0;
    justify-content: flex-start;
    cursor: pointer;

    &:hover {
      color: var(--accent);
      .iconTabs svg * {
        stroke: var(--accent);
      }
    }
  }

  &.active {
    color: var(--accent);
    border-bottom-color: var(--accent);
  }
}

// wrap info and add button
.wrapInfoBtn {
  grid-area: wrapInfoBtn;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;

  @include media-sm {
    grid-template-columns: 1fr 1fr;
  }

  @include media-xxl {
    padding: 1rem 4rem;
  }
}

// filters
// .filters {
//   @include filtersMSS;
//   align-self: center;
//   padding: 0 1rem;
//
//   @include media-xxl {
//     padding: 0 4rem;
//   }
// }

.lorem {
  grid-area: wrapInfoBtn;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  padding: 0 1rem;

  @include media-xxl {
    padding: 0 4rem;
  }
}

.btnList {
  justify-self: center;
  min-width: 100%;
  margin: 1rem 0;
  @include media-sm {
    justify-self: end;
    min-width: 6rem;
    margin: 0;
  }
}

// table
.table {
  @include tableMSS;
}
