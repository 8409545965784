@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsSettings.scss";

.orders {
  @include settingsSDS;
}

.wrapInfoBtn {
  @include wrapInfoBtnSDS;
}

.infoBtnGroup {
  @include infoBtnGroupSDS;
}

.settings {
  @include sectionsSDS;
}

.orderRow {
  background: var(--bg);
  padding: 0.5rem 1rem;
  margin: 0 0 1rem 0;
  // border: 1px solid var(--bg-d5);
  // padding: 0.5rem;
}

.sectionRecap {
  border-bottom: 1px solid var(--bg-d5);
  padding: 0.5rem 0;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 0.5rem;
  align-items: center;
  &:last-of-type {
    border: none;
  }
}

.labelRecapt {
  font-size: var(--font-sm);
  line-height: 1.5rem;
  color: var(--text-a40);
}

.dataRecapt {
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  line-height: 1.5rem;
  color: var(--text);
}

.quantity {
  width: 100%;
  padding: 0.25rem 0;
}

.itemQuantity {
  padding: 0 0.25rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1.5rem;
  align-items: center;
  &:nth-of-type(odd) {
    background: var(--bg);
  }
}

.labelQuantity {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.5rem;
  color: var(--text);
}

.dataQuantity {
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  line-height: 1.5rem;
  color: var(--text);
}

.btnRecap {
  padding: 1rem 0 0.5rem 0;
  display: flex;
  justify-content: flex-end;
}

.noOrder {
  height: 4rem;
  font-size: var(--font-sm);
  line-height: 4rem;
  color: var(--text-a40);
  text-align: center;
  background: var(--bg);
}
