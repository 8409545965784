@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsFirstView.scss";

.firstArchive {
  @include wrapFV;
}

.titleFA {
  @include titleFV;
}

.descFA {
  @include descFv;
  font-size: var(--font-sm);
  line-height: 1.25rem;
  color: var(--text);
  padding: 0 0 2rem 0;
  @include codeAndBold;
}

.infoFA {
  @include infoFv;
}

.btnCreate {
  @include btnCreateFV;
}
