@import "app/assets/stylesheets/_mixins.scss";

.titleStep {
  font-weight: var(--semi-bold);
  font-size: var(--font-base);
  line-height: 1.5rem;
  margin: 0 0 0.5rem 0;
}

.wrapStep {
  &Info {
    border-radius: 0.5rem;
    background: var(--accent-l35);
  }
}

.step {
  border-left: 1px solid var(--bg-d10);
  box-sizing: border-box;
  padding: 1.5rem 0 2rem 1.5rem;
  margin: 0 0 0 1rem;
  position: relative;

  &::before {
    content: "";
    width: 0.75rem;
    height: 0.75rem;
    border: 1px solid var(--bg-d10);
    border-radius: 100%;
    box-sizing: border-box;
    background: var(--accent);
    display: block;
    position: absolute;
    top: 1.875rem;
    left: -0.375rem;
  }

  .wrapStepInfo & {
    padding: 1rem 2rem 1.5rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::before {
      top: 1.375rem;
      left: -0.375rem;
    }
  }
}

.invalidFileLabel {
  font-weight: var(--semi-bold);
  font-size: var(--font-xs);
  text-transform: uppercase;
  line-height: 1.5rem;
  color: var(--red-d10);
  text-align: left;
}

.labelStep {
  width: fit-content;
  font-weight: var(--regular);
  font-size: var(--font-xs);
  text-transform: uppercase;
  line-height: 1.5rem;
  color: var(--text);
  border-radius: 0.25rem;
  background: var(--bg);
  box-sizing: border-box;
  padding: 0 0.375rem;
  margin: 0 0 0.5rem 0;

  .wrapStepInfo & {
    font-weight: var(--semi-bold);
    color: var(--accent-d30);
    background: none;
    padding: 0;
    margin: 0;
  }
}

.textStep {
  margin: 0 0 1.5rem 0;

  .wrapStepInfo & {
    color: var(--text-a60);
    margin: 0;
  }
}

.iconInfoStep {
  margin: 0 0.5rem 0 0;
}

.warningStep {
  width: fit-content;
  font-weight: var(--regular);
  font-size: var(--font-sm);
  color: var(--yellow-d30);
  border-radius: 0.25rem;
  box-sizing: border-box;
  background: var(--yellow-l35);
  padding: 0.375rem 0.5rem;
  margin: 0 0 1.5rem 0;
}

.wrapDatePicker {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.labelDatePikcer {
  width: 100%;
  height: 1.75rem;
  grid-area: label;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text);

  .withErrors & {
    color: var(--red);
  }

  &:after {
    content: "\002A";
    font-size: var(--font-base);
    color: var(--accent);
    margin: 0 0 0 0.125rem;
  }
}

.datePikcer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dayPicker {
  margin: 0 1rem 0 0;
}

.hoursSelect {
  width: 40%;
}

.required {
  &:after {
    content: "\002A";
    font-size: var(--font-base);
    color: var(--accent);
    margin: 0 0 0 0.125rem;
  }
}
