@import "app/assets/stylesheets/_mixins.scss";

.body {
  padding: 1rem 0;
}

.errorsList {
  padding-top: 0.75rem;
}

.error {
  background-color: var(--red-l35);
  color: var(--red-d10);
  padding: 0.25rem 0.5rem;
  border-radius: var(--radius-4);
  margin-bottom: 0.5rem;
  // font-size: var(--font-sm);
  // font-weight: var(--semi-bold);
  // text-transform: uppercase;
  // margin: 0.5rem 0 0 0;
}

.footer {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between !important;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }
}
