@import "app/assets/stylesheets/_mixins.scss";

.domainSelect {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.label {
  width: 100%;
  height: 1.5rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text);
  display: flex;
  flex-direction: column;
}
