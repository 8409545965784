@import "app/assets/stylesheets/_mixins.scss";

.toolbar {
	width: 100%;
	min-height: 3rem;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	border-top: 1px solid var(--input-border);

	@include media-sm {
		min-height: 2.5rem;
	}
}

.actions {
	width: 100%;
	height: 2.5rem;
	display: flex;
	align-items: center;
	position: relative;

	.isFullScreen & {
		width: auto;
	}
}

.iconsDivider {
	height: 1.75rem;
	border-right: 1px solid var(--bg-d3);
	box-sizing: border-box;
	padding: 0 0.25rem 0 0;
	margin: 0 0.25rem 0 0;
}
