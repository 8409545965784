.title {
	font-size: 1.5rem;
	color: var(--accent-d30);
	letter-spacing: 0;
	line-height: 1.35;
	margin: 0 0 0.5rem 0;
}

.subtitle {
	font-size: 0.875rem;
	line-height: 1.5;
	color: var(--text-a40);
}

.help {
	padding: 1px;
	position: relative;
	top: 3px;
	left: 4px;
}
