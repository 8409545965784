@import "app/assets/stylesheets/_mixins.scss";
// @import '../allowNets/allowNets.scss';

.apiToken {
  width: 100%;
  display: grid;
  grid-template-rows: [btn]2rem [wrapInput]2rem;
  grid-row-gap: 1rem;
}

.wrapBtn {
  grid-row: btn;
  display: flex;
}

.btn {
  min-width: 9rem;
}

.copiedMsg {
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  color: var(--green);
  line-height: 2rem;
  margin: 0 0 0 1rem;
}

.wrapInput {
  grid-row: wrapInput;
  display: flex;
}

.btnCopy {
  min-width: 3rem;
  margin: 0 0 0 0.5rem;
}

// =====

// .InputWithButton {
//   @extend .InputWithButton;
//   input {
//     font-size: 12px;
//     max-width: 480px;
//   }
//
//   button {
//     &:focus {
//       outline: 0;
//     }
//   }
// }
//
// .resetButton {
//   @extend .addButton;
//   border: 1px solid var(--red);
//   color: var(--red);
//
//   &:hover {
//     background-color: var(--red);
//     color: var(--white);
//   }
// }
