@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsList.scss";

.listArchive {
  @include listMSS;
  height: calc(100vh - 3rem - #{var(--safe-bottom)}); // fallback
  height: calc(#{var(--fullVh)} - 3rem - #{var(--safe-bottom)});
}

// header
.headerList {
  @include headerListMSS;
}

.titleList {
  @include titleListMSS;
}

.descList {
  @include descListMSS;
}

.actionsList {
  @include actionsListMSS;
}

// content
.contentList {
  @include contentList;
}

// filters
.filters {
  @include filtersMSS;
}

// table
.table {
  @include tableMSS;
}
