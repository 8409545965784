@import "app/assets/stylesheets/_mixins.scss";

.resetPasswordPage {
	width: 100%;
	height: 100vh; // fallback
	height: var(--fullVh);
	background: var(--bg);
	display: flex;
	justify-content: center;
}

.form {
	width: 100%;
	box-sizing: border-box;
	padding: 0 1rem;
	margin: 10vh 0 0 0;

	@include media-sm {
		width: 20rem;
		padding: 0;
	}
}

.title {
	width: 100%;
	font-weight: var(--semi-bold);
	font-size: var(--font-xxl);
	color: var(--text);
	margin: 0 0 0.5rem 0;
}

.text {
	font-weight: var(--regular);
	font-size: var(--font-sm);
	color: var(--text-a40);
	line-height: 1.35;
	margin: 0 0 1.5rem 0;
}

.input {
	width: 100%;
	margin: 0 0 1rem 0;
}

.footer {
	width: 100%;
	box-sizing: border-box;
	padding: 2rem 0;
	display: flex;
	justify-content: flex-end;

	button {
		min-width: 100%;
	}
}
