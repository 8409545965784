@import "app/assets/stylesheets/_mixins.scss";

.form {
  padding: 1rem;
  display: grid;
  grid-template-rows: [wrapInputs]auto [footerBtn]3rem;
  align-content: flex-start;

  @include media-sm {
    align-content: stretch;
  }
}

.wrapInputs {
  grid-row: wrapInputs;
}

.pin {
  margin: 1rem 0;
}

.footerBtn {
  grid-row: footerBtn;
  align-self: end;
  display: flex;
  justify-content: space-between;

  button {
    min-width: calc(50% - 0.5rem);
  }

  @include media-sm {
    justify-self: flex-end;
    justify-content: flex-end;

    button {
      min-width: 5rem;
      &:last-of-type {
        margin: 0 0 0 0.5rem;
      }
    }
  }
}
