@import "app/assets/stylesheets/_mixins.scss";

.filterContainer {
  display: grid;
  grid-template-columns: 2fr 2rem 1fr;
  grid-template-rows: 2.5rem;
  grid-template-areas: ". iconRefresh pagination";
  grid-column-gap: 1rem;
  align-items: center;

  &.showPaginationFilter {
    grid-template-columns: 2fr auto 1fr;
    grid-template-rows: repeat(2, 2.5rem);
    grid-template-areas: "searchList searchList searchList" ". iconRefresh pagination";
    grid-row-gap: 1rem;
  }

  &.showListFilter {
    grid-template-columns: 2fr 2rem 1fr;
    grid-template-rows: 2.5rem;
    grid-template-areas: "statusFilter iconRefresh pagination";
  }

  &.showPaginationFilter.showListFilter {
    grid-template-columns: 2fr 2rem 1fr;
    grid-template-rows: repeat(2, 2.5rem);
    grid-template-areas: "searchList searchList searchList" "statusFilter iconRefresh pagination";
  }

  @include media-sm {
    grid-template-columns: 1fr 2rem 3.5rem minmax(4rem, auto);
    grid-template-rows: 1fr;
    grid-template-areas: ". iconRefresh pageShow pagination";
    grid-row-gap: 0;

    &.showPaginationFilter {
      grid-template-columns: 1fr 2rem 3.5rem minmax(4rem, auto);
      grid-template-rows: 1fr;
      grid-template-areas: "searchList iconRefresh pageShow pagination";
      grid-row-gap: 0;
    }

    &.showListFilter {
      grid-template-columns: 1fr 2rem 3.5rem minmax(4rem, auto);
      grid-template-rows: 1fr;
      grid-template-areas: "statusFilter iconRefresh pageShow pagination";
    }

    &.showPaginationFilter.showListFilter {
      grid-template-columns: auto 1fr 2rem 3.5rem minmax(4rem, auto);
      grid-template-rows: 1fr;
      grid-template-areas: "statusFilter searchList iconRefresh pageShow pagination";
    }
  }

  @include media-md {
    &.showListFilter.showCustomer {
      grid-template-columns: auto auto 1fr 2rem 3.5rem minmax(4rem, auto);
      grid-template-areas: "statusFilter searchList customersSelect iconRefresh  pageShow pagination";
    }
  }
}

.statusFilter {
  grid-area: statusFilter;

  @include media-sm {
    width: 11rem;
  }
}

.customersSelect {
  grid-area: customersSelect;
  @include media-sm {
    min-width: 9rem;
    max-width: 15rem;
  }
}

.searchList {
  grid-area: searchList;

  @include media-sm {
    min-width: 11rem;
    max-width: 15rem;
  }
}

.iconRefresh {
  grid-area: iconRefresh;
}

.pageShow {
  grid-area: pageShow;
}

.pagination {
  grid-area: pagination;
  justify-self: end;
}
