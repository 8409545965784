@import "app/assets/stylesheets/_mixins.scss";

.form {
  padding: 1rem;
  display: grid;
  grid-template-rows: [wrapInputs]auto [footerBtn]3rem;
  align-content: flex-start;

  @include media-sm {
    align-content: stretch;
  }
}

.wrapInputs {
  grid-row: wrapInputs;
}

.input {
  margin: 1rem 0;
}

.footerBtn {
  grid-row: footerBtn;
  align-self: end;
  display: flex;
  justify-content: flex-end;
  button {
    min-width: 50%;
  }

  @include media-sm {
    justify-self: end;

    button {
      min-width: 5rem;
    }
  }
}
