:global {
  @import "app/assets/stylesheets/_mixins.scss";
  @import "app/assets/stylesheets/_mixinsList.scss";
  @import "app/assets/stylesheets/_mixinsShow.scss";
  @import "app/assets/stylesheets/_mixinsSettings.scss";
  @import "app/assets/stylesheets/_mixinsFirstView.scss";
  @import "app/assets/stylesheets/_typography.scss";
  @import "app/assets/stylesheets/_override.scss";
  @import "app/assets/stylesheets/_formsPage.scss";
  @import "app/assets/stylesheets/_settingsPage.scss";
  @import "app/assets/stylesheets/mailDelivery.scss";

  @keyframes :global(rotating) {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  html {
    /* Set border-box as the global default */
    box-sizing: border-box;
    background: var(--white);
  }

  body {
    height: 100vh; // fallback
    height: var(--fullVh);
    font-family: var(--font-family);
    font-size: 16px;
    color: var(--text);
    font-weight: 400;
    padding: var(--safe-top) 0 var(--safe-bottom) 0;
    margin: 0;
    overscroll-behavior: contain;
  }

  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  * {
    padding: 0px;
    margin: 0px;
  }

  a {
    color: var(--accent) !important;
    transition: all 0.3s ease !important;
    &:hover {
      cursor: pointer;
    }
  }

  // resfresh icon animation to add to buttonIcon className
  .isRefreshing {
    animation: rotating 2s linear infinite;
  }

  // scrollbars
  ::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.375rem;
  }

  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb:horizontal {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.15);
  }

  ::-webkit-scrollbar-track-piece {
    background-color: none;
  }

  * {
    scrollbar-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.005);
    scrollbar-width: thin;
  }
  // === to replace === //

  //reset input shadow in safari mobile
  input:not([type="checkbox"]):not([type="radio"]) {
    -webkit-appearance: none;
  }

  ul {
    list-style: none;
  }

  .label {
    color: var(--text-a40);
    display: block;
    font-size: var(--font-xs);
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .form-label {
    width: 100%;
    font-size: var(--font-sm);
    font-weight: var(--regular);
    line-height: 1.25rem;
    color: var(--text);

    .required & {
      &:after {
        content: "\002A";
        font-size: var(--font-base);
        color: var(--accent);
        margin: 0 0 0 0.125rem;
      }
    }
  }

  .icon {
    display: inline-block;
    vertical-align: middle;
  }

  *,
  *:before,
  *:after {
    /* Allow each element to override */
    box-sizing: inherit;
  }

  .info {
    display: inline-block;
    color: var(--text-a60);
    letter-spacing: 1px;

    label {
      text-transform: uppercase;
      font-size: var(--font-xs);
      color: var(--text-a40);
      font-weight: 400;
      margin-right: 0.5rem;
    }
  }

  // cookie consent styling
  #cm[role="dialog"] {
    font-family: var(--font-family);
  }

  .c-bl:last-child {
    display: none;
  }

  /* detailed dialog */
  .bns-t {
    font-family: var(--font-family);
  }

  /* detailed dialog */
  .bns-t {
    font-family: var(--font-family);
  }

  #cm {
    max-width: 700px;
  }

  .syncIcon {
    animation: rotating 1.5s linear infinite;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .syncIcon svg * {
    stroke: var(--accent);
  }

  .icon-accent {
    svg * {
      fill: var(--accent);
    }
  }

  .icon-yellow {
    svg * {
      fill: var(--yellow);
    }
  }

  .icon-white {
    svg * {
      fill: var(--white);
    }
  }

  .icon-stroke-white {
    svg * {
      stroke: var(--white);
    }
  }

  .icon-stroke-accent {
    svg * {
      stroke: var(--accent);
    }
  }

  .icon-red {
    svg * {
      fill: var(--red);
    }
  }

  .icon-green {
    svg * {
      fill: var(--green);
    }
  }

  .copy-icon-disabled g {
    stroke: var(--text-a40);
  }

  .copy-icon-disabled g polyline {
    stroke: var(--text-a40);
  }

  .form-footer-mobile-buttons {
    button {
      min-width: calc(50% - 0.5rem);
    }

    @include media-sm {
      button {
        min-width: 5rem;
      }
    }
  }
}
