@import "app/assets/stylesheets/_mixins.scss";

.poweredBy {
  font-size: var(--font-xs);
  color: var(--text-a40);
  display: none !important; // hide poweredBy for initial release

  &.isInSidebar {
    font-size: var(--font-xxs);
    color: var(--text-a40);
    line-height: 1.5;
    //display: flex; // hide poweredBy for initial release
    flex-direction: column;
    align-items: flex-end;
    display: none !important; // hide poweredBy for initial release
  }

  a {
    font-weight: var(--semi-bold);
    color: var(--accent);
    text-decoration: none;
    opacity: 1;
  }

  b {
    font-weight: var(--semi-bold);
    color: var(--text);
  }
}
