@import "app/assets/stylesheets/_mixins.scss";

.selectContainer {
  @include inputContainer;
  &.labelinrow {
    @include inputLabelinrow;
  }
}

.wrapLabel {
  @include inputWrapLabel;
}

.label {
  @include inputLabel;
}

.helpTooltip {
  @include inputHelpTooltip;
}

.hint {
  @include inputHint;
}

.wrapSelect {
  @include inputWrap;
}

.select {
  @include input;
  // background: url(/arrowDown.svg) calc(100% - 0.5rem) center no-repeat var(--white);
  background-size: 0.875rem;
  cursor: pointer;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--black);
  }

  &.placeholder {
    color: var(--text-a40);
  }

  .error & {
    color: var(--red);
  }
}

.errorMsg {
  @include inputErrorMsg;
}
