@import "app/assets/stylesheets/_mixins.scss";

.quotaSelector {
}

.wrapLabel {
  @include inputWrapLabel;
}

.label {
  @include inputLabel;
}

.helpTooltip {
  @include inputHelpTooltip;
}

.hint {
  @include inputHint;
}

.hidden {
  display: none;
}

.errorMsg {
  @include inputErrorMsg;
}

.row {
  @include media-sm {
    display: flex;
  }
}

.section {
  width: 100%;
  margin: 0 0 1.5rem 0;

  &.sectionMedium {
    width: 100%;

    @include media-sm {
      width: 50%;

      box-sizing: border-box;
      padding: 0 7px 0 0;
    }
  }

  &.sectionLarge {
    width: 100%;

    @include media-sm {
      width: calc(25% + 4px);
      border-left: 1px solid var(--bg-d5);
      padding: 0 0.5rem;
    }
  }

  &.sectionXLarge {
    width: 100%;

    @include media-sm {
      width: calc(25% - 4px);
      border-left: 1px solid var(--bg-d5);
      box-sizing: border-box;
      padding: 0 0 0 calc(0.5rem - 1px);
    }
  }
}

.sectionLabel {
  width: 100%;
  height: 2rem;
  font-size: var(--font-sm);
  line-height: 1.25rem;
  color: var(--text-a40);
  display: flex;
  justify-content: center;
}

.sectionQuote {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-content: space-between;
  align-items: center;

  @include media-sm {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 1fr;
    grid-row-gap: 0;
  }

  .sectionMedium & {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-row-gap: 0;
  }

  .sectionLarge &,
  .sectionXLarge & {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
}

.quota {
  height: 2.5rem;
  border: 1px solid var(--input-border);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 0.05s ease-in-out;
  cursor: pointer;

  &:hover,
  &:active {
    border-color: var(--input-border-hover);
  }

  &.active {
    color: var(--white);
    background: var(--accent);
    border-color: var(--accent);
  }

  &.disabled {
    background: var(--bg);
    opacity: 0.5;
    pointer-events: none;
  }
}
