@import "app/assets/stylesheets/_mixins.scss";

.inputContainer {
  @include inputContainer;

  &.labelInRow {
    @include inputLabelinrow;
  }
}

.wrapLabel {
  @include inputWrapLabel;
}

.label {
  @include inputLabel;
}

.helpTooltip {
  @include inputHelpTooltip;
}

.hint {
  @include inputHint;
}

.wrapInput {
  @include inputWrap;
  flex-direction: row;
}

.input {
  @include input;
  border-radius: 0.25rem 0 0 0.25rem;
  text-transform: uppercase;
}

.verifyBtn {
  width: 5rem;
  height: 2.5rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--white);
  border-radius: 0 0.25rem 0.25rem 0;
  background: var(--input-border);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  cursor: pointer;

  @include media-md {
    height: 2rem;
  }

  .isEditing & {
    background: var(--accent);
    opacity: 1;
  }

  .isVerified & {
    background: var(--green);
    pointer-events: none;
    opacity: 1;
  }
}

.iconCheck {
  svg * {
    stroke: var(--white);
  }
}

.errorMsg {
  @include inputErrorMsg;
}
