.label {
  background-color: var(--green);
  color: var(--white);
  line-height: normal;
  border-bottom-right-radius: var(--radius-8);
  border-top-left-radius: var(--radius-8);
  font-size: var(--font-xs);
  font-weight: var(--font-semibold);
  font-size: var(--font-xs);
  padding: 2px 5px;
}

.large {
  font-size: var(--font-lg);
  font-weight: var(--bold);
  padding: 5px 15px;
  border-bottom-right-radius: 0.8rem;
  border-top-left-radius: 0.8rem;
}
