@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsSettings.scss";

.priceTablesContainer {
  position: relative;
}

table.priceTable,
.priceTable th,
.priceTable td {
  border: 1px solid var(--bg-d5);
}

.priceHighlight {
  border: 1px solid var(--accent);
  border-radius: 0.25rem;
  position: absolute;
  top: 0;
  bottom: 0;
}

th {
  text-transform: capitalize;
}

.priceTableHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--bg);
  padding: 1rem 0.5rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  border: 1px solid var(--bg-d5);

  &.selected {
    background-color: var(--accent);
    color: white;
    font-weight: 600;
    border: 1px solid white;
  }
}

.headerTable {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  td {
    padding: 2px 1px;
  }
}

.priceTable {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  th,
  td {
    padding: 8px 10px;
    font-weight: 100 !important;
  }

  td.selected {
    background-color: var(--accent-l35);
    font-weight: 600 !important;
    color: black;
  }

  thead {
    background-color: var(--bg);

    th {
      border: none !important;
      text-align: left;
    }
  }

  tbody tr td:first-child {
    background-color: var(--bg);
    text-align: right;
  }

  td {
    text-align: center;
    color: #666666;
  }
}

.basicTable:not(:last-child) {
  margin-bottom: 0.75rem;
}

.customTierPricePlans {
  font-weight: 100 !important;
}

.customTierPricePlans .priceTableHeader {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-transform: capitalize;
}

.customTierPriceTable .amount {
  font-weight: 600 !important;
  color: black;
}
