@import "app/assets/stylesheets/_mixins.scss";

.serviceIntro {
  margin-top: 30px;
}

.content {
  display: flex;
  margin-bottom: 96px;
  justify-content: space-between;
  flex-direction: column;
  gap: 28px;

  @include media-sm {
    flex-direction: row;
    align-items: center;
  }
}

.column {
  flex: 1;
}

.title {
  line-height: 2rem;
  font-size: var(--font-xxl);
  font-weight: var(--semi-bold);
  margin-top: 16px;
}

.description {
  line-height: 1.25rem;
  font-size: var(--font-sm);
  color: var(--text-a60);
  margin-top: 4px;
}

.betaBanner {
  margin-top: 32px;
}

.imageContainer {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
}

.image {
  padding: 0px;
  flex: 0.7;
}
