@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsList.scss";

.listAudits {
  padding: 1rem 0;
  margin: 0 0 var(--safe-bottom) 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 3rem minmax(0px, 1fr);
  grid-template-areas: "headerList" "auditsFilters" "filters" "table";
  @include overflowY;

  @include media-sm {
    grid-template-rows: auto auto 4rem minmax(0px, 1fr);
    overflow-y: inherit;
  }
}

// header
.headerList {
  @include headerListMSS;
}

.titleList {
  @include titleListMSS;
}

.descList {
  @include descListMSS;
}

.actionsList {
  @include actionsListMSS;
}

.auditsFilters {
  grid-area: auditsFilters;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 1rem;

  .showFilters & {
    padding: 1rem;
    grid-template-areas: "dayPickerFrom" "dayPickerTo" "whoSelect" "actionSelect" "subject" "medium" "checbox" "wrapBtn";
  }

  .showFilters.isAdmin & {
    grid-template-areas: "viewAs" "dayPickerFrom" "dayPickerTo" "whoSelect" "actionSelect" "subject" "medium" "checbox" "wrapBtn";
  }

  .showFilters.isEmailAccount & {
    grid-template-areas: "dayPickerFrom" "dayPickerTo" "whoSelect" "actionSelect" "medium" "checbox" "wrapBtn";
  }

  @include media-sm {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;

    .showFilters & {
      grid-template-areas: "dayPickerFrom dayPickerTo whoSelect" "actionSelect subject medium" "checbox . wrapBtn";
    }

    .showFilters.isAdmin & {
      grid-template-rows: repeat(4, auto);
      grid-template-areas: "viewAs viewAs ." "dayPickerFrom dayPickerTo whoSelect" "actionSelect subject medium" "checbox . wrapBtn";
    }
    .showFilters.isEmailAccount & {
      grid-template-areas: "viewAs viewAs showMore" "dayPickerFrom dayPickerTo whoSelect" "actionSelect medium . " "checbox . wrapBtn";
    }
  }

  @include media-xxl {
    .showFilters & {
      padding: 1rem 4rem;
    }
  }
}

.viewAs {
  grid-area: viewAs;
}

.dayPickerFrom {
  grid-area: dayPickerFrom;
}

.dayPickerTo {
  grid-area: dayPickerTo;
}

.whoSelect {
  grid-area: whoSelect;
}

.actionSelect {
  grid-area: actionSelect;
}

.subject {
  grid-area: subject;
}

.medium {
  grid-area: medium;
}

.checbox {
  grid-area: checbox;
}

.wrapBtn {
  grid-area: wrapBtn;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    min-width: calc(50% - 0.5rem);
  }

  @include media-sm {
    justify-content: flex-end;
    button {
      min-width: 5rem;

      &:last-of-type {
        margin: 0 0 0 0.5rem;
      }
    }
  }
}

// filters
.filters {
  grid-row: filters;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-xxl {
    padding: 0 4rem;
  }
}

.showFilter {
  min-width: 8rem;
}

// table
.table {
  grid-area: table;
  align-content: flex-start;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(0px, 1fr);
  grid-template-areas: "contentTable";

  @include media-md {
    grid-template-rows: 1.75rem minmax(0px, 1fr);
    grid-template-areas: "headerTable" "contentTable";
  }
}

.headerTable {
  grid-area: headerTable;
  height: 1.75rem;
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  line-height: 1.75rem;
  color: var(--text);
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  background: var(--bg);
  padding: 0 0.5rem;
  display: none;

  @include media-md {
    margin: 0 1rem;
    display: grid;
    grid-template-columns: [dateHeaderTable]8rem [whoHeaderTable]1fr [contentHeaderTable]2fr [flagHeaderTable]2rem [showMoreHeaderTable]4rem;
    grid-column-gap: 0.5rem;
    align-items: center;
  }

  @include media-xxl {
    margin: 0 4rem;
  }
}

.dateHeaderTable {
  grid-column: dateHeaderTable;
}

.whoHeaderTable {
  grid-column: whoHeaderTable;
}

.contentHeaderTable {
  grid-column: contentHeaderTable;
}

.contentTable {
  grid-area: contentTable;
  padding: 0 1rem;
  @include media-sm {
    @include overflowY;
  }

  @include media-xxl {
    padding: 0 4rem;
  }
}

.wrapIllustration {
  @include wrapIllustration;
  height: 11rem;
}

.illustrationMsg {
  @include wrapIllustration;
}

.msgIllustration {
  @include msgIllustration;
}

.descIllustration {
  @include descIllustration;
}

.wrapRowTable {
  border: 1px solid var(--bg-d5);
  box-sizing: border-box;
  margin: 0 0 1rem 0;
  display: flex;
  flex-direction: column;

  @include media-md {
    border: none;
    border-bottom: 1px solid var(--bg-d5);
    margin: 0;
  }

  // @include media-lg {
  //   margin: 0 1rem;
  // }
  //
  // @include media-xl {
  //   margin: 0 4rem;
  // }
}

.rowTable {
  font-size: var(--font-sm);
  color: var(--text);
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 2.5rem) minmax(2.5rem, auto) minmax(2.5rem, auto);
  grid-template-areas: "dateRowTable" "whoRowTable" "contentRowTable" "flagRowTable" "showMoreRowTable";

  @include media-md {
    border: none;
    padding: 0 0.5rem;
    margin: 0;
    grid-template-columns: 8rem 1fr 2fr 2rem 4rem;
    grid-template-rows: minmax(2.5rem, auto) auto;
    grid-template-areas: "dateRowTable whoRowTable contentRowTable flagRowTable showMoreRowTable";

    grid-column-gap: 0.5rem;
    align-items: center;
  }

  &:hover {
    background: var(--bg);
  }
}

.itemRow {
  border: none;
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  word-wrap: break-word;
  display: flex;
  align-items: center;

  @include media-md {
    border: none;
    display: block;
  }

  &:last-of-type {
    border-bottom: none;
    &:before {
      display: none;
    }
  }

  &:before {
    content: attr(data-label);
    min-width: 5rem;
    min-height: calc(2.5rem - 1px);
    font-size: var(--font-sm);
    font-weight: var(--semi-bold);
    color: var(--text);
    box-sizing: border-box;
    background: var(--bg);
    padding: 0 0 0 0.5rem;
    margin: 0 1rem 0 0;
    display: flex;
    align-items: center;
    @include textOverflow;

    @include media-sm {
      width: 8rem;
    }
    @include media-md {
      display: none;
    }
  }
}

.dateRowTable {
  grid-area: dateRowTable;
}

.whoRowTable {
  grid-area: whoRowTable;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.contentRowTable {
  grid-area: contentRowTable;
}

.flagRowTable {
  grid-area: flagRowTable;
}

.showMoreRowTable {
  grid-area: showMoreRowTable;
  justify-self: center;
  font-size: var(--font-sm);
  text-decoration: underline;
  line-height: 2rem;
  color: var(--accent);
  cursor: pointer;

  @include media-md {
    justify-self: end;
  }
}

.detailsRowTable {
  background: var(--bg);
  padding: 0.5rem;
  overflow-x: auto;
  pre {
    font-family: monospace !important;
    font-size: var(--font-xs);
    line-height: 1.25rem;
    color: var(--text);
    white-space: pre-wrap;

    @include media-sm {
      font-size: var(--font-sm);
      white-space: pre;
    }
  }
}
