@import "app/assets/stylesheets/_mixins.scss";

.bodyMigrationLogModal {
  padding: 1rem !important;
  display: flex;
  flex-direction: column;
  overflow: auto;

  pre {
    width: 100%;
    font-family: monospace !important;
    font-size: var(--font-xs);
    line-height: 1.25rem;
    color: var(--text);
    white-space: pre-wrap;
    background: var(--bg);
    padding: 0.5rem;
    margin: 0 0 0.5rem 0;
    overflow: auto;
    display: block;

    @include media-sm {
      width: 28rem;
      font-size: var(--font-xs);
    }
  }
}

.wrapCopyToClipboard {
  height: 2rem;
  display: flex;
  align-items: center;
}

.copiedMsg {
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  color: var(--green);
  line-height: 2rem;
  margin: 0 0 0 1rem;
}

.footerMigrationLog {
  grid-row: footerBtn;
  align-self: end;
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;

  button {
    min-width: calc(50% - 0.5rem);
  }

  @include media-sm {
    justify-self: end;
    justify-content: flex-end;

    button {
      min-width: 5rem;

      &:last-of-type {
        margin: 0 0 0 0.5rem;
      }
    }
  }

  @include media-md {
    height: 3rem;
  }
}
