.responsiveButtonGroup {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  background-color: white;
}

.buttons {
  border: 1px solid var(--bg-d5);
  background-color: var(--bg);
  padding: 0.75rem 1rem;
  width: 100%;
  display: flex;
  gap: 20px;

  button {
    flex: 1;
  }
}
