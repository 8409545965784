@import "app/assets/stylesheets/_mixins.scss";

.dashboardContainer {
  padding: 20px 16px;
  overflow-y: scroll;

  @include media-md {
    padding: 20px 32px;
  }
}

.container {
  max-width: 1280px;
  margin: auto;
}

.content {
  margin-top: 20px;
}
