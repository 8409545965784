@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsSettings.scss";

.limits {
  @include settingsSDS;
  padding: 0 1rem;

  @include media-sm {
    padding: 0;
  }
}

.sectionRecap {
  border-bottom: 1px solid var(--bg-d5);
  padding: 0 0 1rem 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 0.5rem;
  &:last-of-type {
    border: none;
  }
}

.labelRecapt {
  font-size: var(--font-sm);
  line-height: 1.5rem;
  color: var(--text-a40);
}

.dataRecapt {
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  line-height: 1.5rem;
  color: var(--text);
}
