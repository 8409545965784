@import "app/assets/stylesheets/_mixins.scss";

.customerSelect {
	@include inputContainer;
}

.wrapLabel {
	@include inputWrapLabel;
}

.label {
	@include inputLabel;
}

.errorMsg {
	@include inputErrorMsg;
}
