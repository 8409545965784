@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsSettings.scss";

.registry {
  @include settingsSDS;
}

.wrapInfoBtn {
  @include wrapInfoBtnSDS;
}

.warnignMsg {
  @include warnignMsgSDS;
}

.docsBtn {
  @include docsBtnSDS;
}

.saveBtn {
  @include saveBtnDSD;
}

.settings {
  @include sectionsSDS;
}

.sectionRecap {
  border-bottom: 1px solid var(--bg-d5);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1.5rem 1fr;

  grid-column-gap: 0.5rem;
  &:last-of-type {
    border: none;
  }

  @include media-sm {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: minmax(2rem, auto);
  }
}

.labelRecapt {
  font-size: var(--font-sm);
  line-height: 1.5rem;
  color: var(--text-a40);
}

.dataRecapt {
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  line-height: 1.5rem;
  color: var(--text);
}

.wrapDomain {
  width: 100%;
  margin: 0 0 2rem 0;
  display: flex;
  flex-direction: column;
}

.nameDomain {
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  color: var(--text);
  line-height: 1.5rem;
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  background: var(--bg);
  padding: 0 0.25rem;
}

.rowDomain {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--text);
  line-height: 1.5rem;
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: 0 0.25rem;
  display: grid;
  grid-template-columns: 1fr 5rem;
}

.visualization {
  font-size: var(--font-xs);
  text-transform: uppercase;
}
