@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsList.scss";

.listDomains {
  @include listMSS;
}

// header
.headerList {
  @include headerListMSS;
}

.titleList {
  @include titleListMSS;
}

.descList {
  @include descListMSS;
}

.actionsList {
  @include actionsListMSS;
}

// content
.contentList {
  @include contentList;
}

// filters
.filters {
  @include filtersMSS;
}

// table
.table {
  @include tableMSS;
}

// modal
.modaNoAction {
  & > div:first-child {
    width: calc(100% - 1rem);
    height: auto;

    @include media-sm {
      width: 30rem;
      height: auto;
    }
  }
  .footerModaNoAction {
    button {
      min-width: 100%;
    }
    @include media-sm {
      button {
        min-width: 6rem;
      }
    }
  }
}
