// .deliveryAccountCard {
//   display: flex;
//   border: 1px solid var(--bg-d5);
//   border-radius: 5px;
//   cursor: pointer;
// }

// .statusSection {
//   margin: 16px 0px 0px 10px;
//   display: flex;
//   justify-content: center;
// }

// .contentSection {
//   margin: 16px 0px 20px 10px;
//   width: 100%;
// }

// .menuSection {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-top: 5px;
// }

// .contentTitle {
//   font-size: var(--font-lg);
//   font-weight: var(--semi-bold);
//   color: var(--text);
//   max-width: 250px;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

// .accountId {
//   margin-top: 2px;
//   font-size: var(--font-sm);
//   font-weight: var(--regular);
//   color: var(--text-a60);
// }

// .content {
//   margin-top: 20px;
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   row-gap: 18px;
//   column-gap: 30px;
//   flex-wrap: wrap;
// }

// .value {
//   font-size: var(--font-base);
//   font-weight: var(--semi-bold);
//   color: var(--text);
// }

// .valueWithWarning {
//   display: flex;
//   align-items: center;
//   color: var(--red);
//   gap: 4px;

//   svg path {
//     fill: var(--red);
//   }
// }

// .description {
//   margin-top: 4px;
//   font-size: var(--font-sm);
//   color: var(--text-a60);
// }

// .warningIconContainer {
//   height: 20px;

//   svg path {
//     fill: var(--red);
//   }
// }

.gridTile::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 187, 5, 0.3);
  left: -2px;
  right: 2px;
  top: 2px;
  bottom: -2px;
}
