@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsList.scss";

.listPostmasters {
  @include listMSS;
}

// header
.headerList {
  @include headerListMSS;
}

.titleList {
  @include titleListMSS;
}

.descList {
  @include descListMSS;
}

.actionsList {
  @include actionsListMSS;
}

// content
.contentList {
  @include contentList;
}

.filters {
  @include filtersMSS;
}

// table
.table {
  @include tableMSS;
}

// quota bar
.quota {
  display: flex;
  align-items: center;
}

.percentage {
  margin: 0 0.5rem 0 0;

  &::after {
    content: "\0025";
    font-size: var(--font-xs);
  }
}

.giga {
  margin: 0 0 0 0.5rem;
}
