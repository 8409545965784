.syncIcon {
  animation: rotating 1.5s linear infinite;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg * {
    stroke: var(--white);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
