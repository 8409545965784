@import "app/assets/stylesheets/_mixins.scss";

.confirmModal {
  & > div:first-child {
    width: calc(100% - 1rem);
    height: auto;
    // remove notch handling on confirmation modal
    grid-template-rows: 2.5rem 1fr;

    @include media-sm {
      width: 30rem;
      height: auto;
    }
  }

  // get the header of the modal to remove notch handling on confirmation modal
  & > div:first-child > div:first-child {
    padding: 0 1rem;
  }
}

.icon {
  svg * {
    fill: var(--red);
  }
}

.footer {
  button {
    min-width: calc(50% - 0.5rem);
  }

  @include media-sm {
    button {
      min-width: 6rem;

      &:last-of-type {
        margin: 0 0 0 0.5rem;
      }
    }
  }
}
