@import "app/assets/stylesheets/_mixins.scss";

.detailInfo {
  font-size: var(--font-sm);
  line-height: 1.125rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(1.5rem, auto);
  grid-template-areas: "labelDetail" "dataDetail";

  @include media-sm {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: minmax(1.5rem, auto);
    grid-template-areas: "labelDetail dataDetail";
    grid-column-gap: 0.25rem;
    justify-content: flex-start;
  }

  &.inLine {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: minmax(1.5rem, auto);
    grid-template-areas: "labelDetail dataDetail";
    grid-column-gap: 0.25rem;
    justify-content: flex-start;
  }
}

.labelDetail {
  grid-area: labelDetail;
  color: var(--text-a40);
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  padding: 0.1875rem 0;
  &:after {
    content: ":";
  }
}

.dataDetail {
  grid-area: dataDetail;
  font-weight: var(--semi-bold);
  color: var(--text);
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;

  padding: 0.1875rem 0;

  .warning & {
    font-weight: var(--regular);
    background: var(--yellow);
    border-radius: 2px;
    padding: 0 0.25rem;
    margin: 0.1875rem 0;
  }

  .error & {
    font-weight: var(--regular);
    color: var(--white);
    background: var(--red);
    border-radius: 2px;
    padding: 0 0.25rem;
    margin: 0.1875rem 0;
  }
}
