@import "app/assets/stylesheets/_mixins.scss";

.card {
	min-height: 10rem;
	//border: 1px solid var(--bg-d5);
	border-radius: 0.5rem;
	box-sizing: border-box;
	background: var(--white);
	box-shadow: var(--shadow-dp4);
	padding: 0.75rem 1rem;
	display: flex;
	flex-direction: column;
}

.disabled {
	opacity: 0.4;
	pointer-events: none;
}

.title {
	grid-area: name;
	font-weight: var(--semi-bold);
	font-size: var(--font-lg);
	color: var(--text);
	box-sizing: border-box;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	padding: 0 0 0.5rem 0;
	margin: 0;
}

.text {
	flex: 1;
	font-weight: var(--regular);
	font-size: var(--font-sm);
	color: var(--text-a60);
	box-sizing: border-box;
	padding: 0 0 1rem 0;
	margin: 0;
}

.button {
	margin: 1rem 0 0 0;
}
