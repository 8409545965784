.loadingBar {
  width: 5rem;

  height: 0.75rem;
  border-radius: 1px;
  background: var(--bg-d5);
  // margin-left: 10px;
  position: relative;
  // top: 2px;

  .quota {
    height: 0.75rem;
    border-radius: 1px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
