@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsSettings.scss";

.security {
  @include settingsSDS;
}

.wrapInfoBtn {
  @include wrapInfoBtnSDS;
}

.warnignMsg {
  @include warnignMsgSDS;
}

.infoBtnGroup {
  @include infoBtnGroupSDS;
}

.settings {
  @include sectionsSDS;
}

.wrapPasswordForcedBtn {
  display: flex;
  flex-direction: column;

  button {
    min-width: 100%;
    margin: 0.5rem 0 0 0;
  }

  @include media-sm {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    button {
      min-width: 6rem;
      white-space: nowrap;
      margin: 0 0 0 1rem;
    }
  }
}

.labelPasswordForced {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text);
}

.disabledMsg {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
