@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsSettings.scss";

.general {
  @include settingsSDS;
}

.wrapInfoBtn {
  @include wrapInfoBtnSDS;
}

.warnignMsg {
  @include warnignMsgSDS;
}

.infoBtnGroup {
  @include infoBtnGroupSDS;
}

.settings {
  @include sectionsSDS;
}

.disabledMsg {
  width: 100%;
  font-size: var(--font-sm);
  line-height: 1.5rem;
  color: var(--accent-d30);
  background: var(--accent-l35);
  padding: 0 0.5rem;
  margin: 0.5rem 0 0 0;
  display: flex;
  align-items: center;
}

.remeberDevices {
  display: flex;
  flex-direction: column;
  grid-column: 1 / 3;
}

.otpSessioneList {
  display: grid;
  grid-template-rows: [headerOtpSL]1.5rem [contentOtpSL]1fr;
}

.headerOtpSL {
  grid-row: headerOtpSL;
  font-size: var(--font-xs);
  font-weight: var(--semi-bold);
  line-height: 1.5rem;
  color: var(--text);
  background: var(--bg);
  border-bottom: 1px solid var(--bg-d5);
  padding: 0 0.5rem;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  align-items: center;
}

.emptyList {
  height: 3rem;
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
}

.contentOtpSL {
  grid-row: contentOtpSL;
}

.rowOtpSL {
  height: 3rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 2rem;
  color: var(--text);
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  padding: 0 0.5rem;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  align-items: center;
}

.browser {
  span {
    color: var(--text-a40);
    margin: 0 0 0 0.25rem;
  }
}

.current {
  font-weight: var(--semi-bold);
  color: var(--green);
}

.btnRD {
  justify-self: end;
}
