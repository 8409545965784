@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsSettings.scss";

.registry {
  @include settingsSDS;
}

.wrapInfoBtn {
  @include wrapInfoBtnSDS;
}

.warnignMsg {
  @include warnignMsgSDS;
}

.docsBtn {
  @include docsBtnSDS;
}

.saveBtn {
  @include saveBtnDSD;
}

.settings {
  @include sectionsSDS;
}

.sectionRecap {
  border-bottom: 1px solid var(--bg-d5);
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 0.5rem;
  &:last-of-type {
    border: none;
  }
}

.labelRecapt {
  font-size: var(--font-sm);
  line-height: 1.5rem;
  color: var(--text-a40);
}

.dataRecapt {
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  line-height: 1.5rem;
  color: var(--text);
}

.delegateRecap {
  display: flex;
  flex-direction: column;
}

.delegateName {
  display: flex;

  .delegateEmail {
    font-weight: var(--regular);
    text-decoration: underline;
    color: var(--accent);
    margin: 0 0 0 0.5rem;
    cursor: pointer;
    &:hover {
      color: var(--accent-d30);
    }
  }
}

.btnDocsImport {
  margin: 1rem 0 0 0;
}

.importBox {
  border-radius: 1px;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  display: grid;
  grid-template-columns: [iconImport]3rem [textImport]1fr;
  grid-column-gap: 0.75rem;
  align-items: start;
  &.warning {
    background: var(--yellow-l35);
  }
  &.success {
    background: var(--green-l35);
  }
  &.loading {
    background: var(--accent-l35);
  }
}

.iconImport {
  grid-column: iconImport;

  .warning & {
    svg * {
      fill: var(--yellow);
      opacity: 0.75;
    }
  }

  .success & {
    svg * {
      fill: var(--green);
      opacity: 0.5;
    }
  }
}

.textImport {
  grid-column: textImport;
  align-self: center;
  font-size: var(--font-sm);
  @include codeAndBold;
}

.logFiles {
  grid-column: 1 / 3;
  padding: 0.5rem 0 0 0;
  display: flex;
  flex-direction: column;
}

.titleLogFiles {
  font-size: var(--font-xs);
  font-weight: var(--semi-bold);
  line-height: 1.25rem;
}

.logFile {
  font-size: var(--font-sm);
  line-height: 1.25rem;
  padding: 0 0 0.25rem 0;
  border-top: 1px solid var(--yellow-l35);
}
