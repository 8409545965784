@import "app/assets/stylesheets/_mixins.scss";

.buttonIcon {
  padding: 0.375rem;
  margin: 0.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.loading {
    cursor: default;
  }

  .icon {
    z-index: var(--z-index-2);
  }

  &.hoverSmall {
    padding: 0.25rem;
  }
}

.active {
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--bg-d10);
    z-index: var(--z-index-1);
  }

  &.square:after {
    border-radius: var(--radius-4);
  }
}

.hover {
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--z-index-1);
  }

  &.square:after {
    border-radius: var(--radius-4);
  }

  @include media-md {
    &:hover {
      &:after {
        background: var(--bg-d5);
        @include hoverBtnIconAnimation;
      }
    }
  }

  &:active {
    &:after {
      background: var(--bg-d5);
      @include clickBtnIconAnimation;
    }
  }
}
