@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsList.scss";

.listDelegates {
  @include listMSS;
}

// header
.headerList {
  @include headerListMSS;
}

.titleList {
  @include titleListMSS;
}

.descList {
  @include descListMSS;
}

.actionsList {
  @include actionsListMSS;
}

// content
.contentList {
  @include contentList;
}

// filters
.filters {
  @include filtersMSS;
}

// table
.table {
  @include tableCardMSS;
}

.loader {
  @include tableMSS;
}

.noFoundMsg {
  @include noFoundMsg;
  align-items: flex-start;
}

.wrpaIllustration {
  @include wrapIllustration;
}

.msgIllustration {
  @include msgIllustration;
}

.descIllustration {
  @include descIllustration;
}
