.wrapIconLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iconLoader {
  max-width: 4rem;
  rect:nth-child(1) {
    transform-origin: center;
    animation: loadbars 1s ease-in-out infinite 0s;
  }

  rect:nth-child(2) {
    transform-origin: center;
    animation: loadbars 1s ease-in-out infinite -0.2s;
  }

  rect:nth-child(3) {
    transform-origin: center;
    animation: loadbars 1s ease-in-out infinite -0.4s;
  }

  rect:nth-child(4) {
    transform-origin: center;
    animation: loadbars 1s ease-in-out infinite -0.6s;
  }
}

@keyframes loadbars {
  0% {
    transform: scaleY(3);
  }
  25% {
    transform: scaleY(0.75);
  }
  50% {
    transform: scaleY(3);
  }
  75% {
    transform: scaleY(0.75);
  }
  100% {
    transform: scaleY(3);
  }
}
