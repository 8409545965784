.tableLabel {
  font-size: var(--font-sm);
  color: var(--text);
  font-weight: var(--semi-bold);
}

.table {
  width: 100%;
  margin-top: 12px;
  border-radius: 2px;
  border: 1px solid var(--bg-d15);
}

.itemLabel {
  padding: 8px;
  background-color: var(--bg);
  font-weight: var(--semi-bold);
  border-bottom: 1px solid var(--bg-d15);
  font-size: var(--font-sm);
  overflow-x: scroll;
}

.itemValue {
  padding: 6px;
  font-size: var(--font-sm);
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: scroll;
}

.itemValueActions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.iconCheck svg path {
  fill: var(--green);
}

.iconClose svg path {
  fill: var(--red) !important;
}

.hidden {
  visibility: hidden;
}

.valid .itemValue {
  color: var(--green);
}

.error .itemValue {
  color: var(--red);
}

.borderTop {
  border-top: 1px solid var(--bg-d15);
}

.buttonCopy {
  margin-left: 8px;
}
