@import "app/assets/stylesheets/_mixins.scss";

.wrapInput {
  width: 100%;
  position: relative;
}

.input {
  width: 100%;
  height: 2.5rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--text);
  line-height: 2.5rem;
  border: 1px solid var(--input-border);
  border-radius: 0.25rem;
  box-sizing: border-box !important;
  box-shadow: none !important;
  padding: 0 1.7rem 0 0.375rem;
  appearance: none;
  transition: border 0.05s ease-in-out;

  @include media-md {
    height: 2rem;
    line-height: 2rem;
  }

  &:hover {
    border-color: var(--input-border-hover);
  }

  &:focus {
    border-color: var(--accent);
    outline: none !important;
  }
  &:focus {
    outline: none;
  }

  &.active {
    color: var(--accent);
  }

  $placeholder-declarations-list: "::-webkit-input-placeholder" "::-moz-placeholder" ":-ms-input-placeholder"
    ":-moz-placeholder";

  @each $declaration in $placeholder-declarations-list {
    &#{$declaration} {
      /* Chrome/Opera/Safari */
      font-size: var(--font-sm);
      font-weight: var(--regular);
      color: var(--text);
      opacity: 0.5;
    }
  }
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  position: absolute;
  right: 4px;
  top: 4px;
  padding: 0.3rem;

  &:hover {
    background-color: var(--accent-l35);
    cursor: pointer;
  }
}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg * {
    stroke: var(--accent);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    svg * {
      stroke: var(--text);
    }
  }
}
