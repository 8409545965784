.logo {
  display: flex;
  align-items: center;
  img {
    width: auto;
    height: auto;
  }

  img[src*=".svg"] {
    width: auto;
    height: 100%;
  }
}
