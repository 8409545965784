@import "app/assets/stylesheets/_mixins.scss";

.domainLimitsInputs {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  align-content: flex-start;

  @include media-sm {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
  }
}
