@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsSettings.scss";

.settingsArchive {
  @include settingsSDS;
  @include overflowY;

  @include media-sm {
    overflow-y: inherit;
  }
}

.wrapInfoBtn {
  @include wrapInfoBtnSDS;
}

.warnignMsg {
  @include warnignMsgSDS;
}

.infoBtnGroup {
  @include infoBtnGroupSDS;
}

.settings {
  @include sectionsSDS;

  @include media-sm {
    @include overflowY;
  }
}
