@import "app/assets/stylesheets/_mixins.scss";

.otpConfig {
  // min-height: 32rem;
  padding: 0.5rem 1rem 1rem 1rem !important;
  display: grid;
  grid-template-rows: 1fr 3rem;
  align-items: start;
  align-content: flex-start;
}

.text {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text);
  @include codeAndBold;
}

.pin {
  grid-area: pin;
}

// secret
.wrapSecret {
  padding: 0 0 2rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 8rem 3rem 7rem;
  grid-template-areas: "text" "qrCode" "wrapCode" "pin";
  justify-content: center;
  grid-row-gap: 2rem;
}

.qrCpode {
  display: flex;
  justify-content: center;
}

.wrapCode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textCode {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.5rem;
  color: var(--text);
  @include codeAndBold;
}

.generateCode {
  font-size: var(--font-sm);
  font-weight: var(--regular);
  text-decoration: underline;
  line-height: 1.5rem;
  color: var(--accent);
  cursor: pointer;
  &.newCode {
    text-decoration: none;
    color: var(--text-a40);
    cursor: default;
  }
}

// disable
.wrapDisable {
  padding: 0 0 2rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 7rem;
  grid-template-areas: "text" "pin";
  justify-content: center;
  grid-row-gap: 2rem;
}

// otp application
.wrapApp {
  padding: 0 0 2rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 3rem 3rem;
  grid-template-areas: "text" "appName" "osAppLink";
  justify-content: center;
  grid-row-gap: 2rem;
}

.appName {
  grid-area: appName;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconApp {
  width: 3rem;
  img {
    width: 100%;
    height: auto;
  }
}

.textApp {
  font-size: var(--font-lg);
  font-weight: var(--regular);
  color: var(--text);
  margin: 0 0 0 0.5rem;
}

.osAppLink {
  grid-area: osAppLink;
  display: flex;
  justify-content: center;
}

.storeBadge {
  width: 8.5rem;
  height: 2.5rem;
  background: var(--text);
  border-radius: 4px;
  padding: 0 0.5rem 0 0.625rem;
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.iconSB {
  margin: 0 0.375rem 0 0;
}

.wrapTextSB {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ctaStore {
  font-size: var(--font-xxs);
  font-weight: var(--regular);
  color: var(--white);
  line-height: 0.5rem;
}

.nameStore {
  font-size: var(--font-base);
  font-weight: var(--regular);
  color: var(--white);
  line-height: 1rem;
}

// footer
.footer {
  align-self: end;
  display: flex;
  justify-content: space-between;
  align-content: flex-start;

  button {
    min-width: calc(50% - 0.5rem);
  }

  @include media-sm {
    justify-content: flex-end;
    button {
      min-width: 6rem;

      &:last-of-type {
        margin: 0 0 0 0.5rem;
      }
    }
  }
}
