@import "app/assets/stylesheets/_mixins.scss";

.selectContainer {
  @include inputContainer;
  &.labelinrow {
    @include inputLabelinrow;
  }
}

.wrapLabel {
  @include inputWrapLabel;
}

.label {
  @include inputLabel;
}

.helpTooltip {
  @include inputHelpTooltip;
}

.hint {
  @include inputHint;
}

.select {
  .labelinrow & {
    width: 50%;
  }
  .disabled & {
    opacity: 0.5;
    pointer-events: none;
  }
}

.errorMsg {
  @include inputErrorMsg;
}

.isActive {
  :global(.select__single-value) {
    color: var(--accent) !important;
  }
}
