@import "app/assets/stylesheets/_mixins.scss";

.loginForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  margin: 0 0 1rem 0;
}

.wrapErrorMsg {
  width: 100%;
  min-height: 30px;
  margin: 0;
}

.btn {
  min-width: 40%;
  height: 2.5rem;
}

.withMargin {
  margin: 1rem 0 2rem 0;
}
