@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsShow.scss";

.userSettings {
  @include listDS;
  padding: 0;
}

// navigation
.navigation {
  @include navigationDS;
}

.viewSectionLabel {
  @include viewSection;
}

// header
.header {
  grid-area: headerDS;
  padding: 1rem 0;
  margin: 0 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2rem auto auto;
  grid-template-areas: "wrapTitleDS" "subtitle" "info";

  @include media-sm {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 2rem 1.5rem;
    grid-template-areas: "wrapTitleDS info" "subtitle info";
  }

  @include media-lg {
    grid-template-columns: 1fr 1fr;
  }

  @include media-xxl {
    margin: 0 4rem;
  }

  .isAdmin & {
    border-bottom: 1px solid var(--bg-d5);
    // padding: 1rem 1rem 0 1rem;
  }
}

.wrapTitle {
  @include wrapTitleDS;
}

.subtitle {
  @include subtitleDS;
}

.info {
  @include infoDS;
}

.settings {
  @include settingsDS;
}
