@import "app/assets/stylesheets/_mixins.scss";

.hourPicker {
  display: flex;
  flex-direction: column;
}

.label {
  width: 100%;
  height: 1.5rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text);
  .withErrors & {
    color: var(--red);
  }
}

.wrapSelect {
  display: flex;
  justify-content: flex-start;
}

.select {
  width: 2.5rem;
}

.hoursDivider {
  width: 1rem;
  height: 2rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 2rem;
  color: var(--text);
  display: flex;
  justify-content: center;
  align-items: center;
}

.resetBtn {
  width: 100%;
  height: 1.5rem;
  font-size: var(--font-sm);
  text-decoration: underline;
  color: var(--accent);
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}
