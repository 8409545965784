@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsSettings.scss";
@import "app/assets/stylesheets/_mixinsList.scss";

.container {
  display: flex;
  justify-content: center;
  @include overflowY;
  padding: 1rem;

  @media (min-width: 768px) {
  }
}

.content {
  padding-bottom: 4rem;
}

.ctaContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 1rem;
  margin: 3rem 0;
}

.ctaDesc {
  font-size: var(--font-sm);
  line-height: 1.25rem;
}
