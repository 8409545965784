@import "app/assets/stylesheets/_mixins.scss";

.btn {
  min-width: 6rem;
  width: max-content;
  height: 2.5rem;
  font-size: var(--font-sm);
  font-weight: var(--semi-bold);
  color: var(--white);
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-sizing: border-box;
  background: none;
  padding: 0 1rem;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.withIcon {
    padding: 0 0.75rem 0 0.5rem;
  }

  &.onlyIcon {
    min-width: 2.5rem;
    padding: 0;
    @include media-md {
      min-width: 2rem;
    }
  }

  @include media-md {
    height: 2rem;
  }

  &:focus {
    outline: none;
  }

  &.accent {
    border-color: var(--accent);
    background: var(--accent);
    .icon svg * {
      stroke: var(--white);
    }

    &:hover {
      border-color: var(--accent-d10);
      background: var(--accent-d10);
    }
  }

  &.outline {
    color: var(--text);
    border-color: var(--text-a40);

    .icon svg * {
      stroke: var(--text);
    }

    &:hover {
      color: var(--white);
      border-color: var(--accent-d10);
      background: var(--accent-d10);

      .icon svg * {
        stroke: var(--white);
      }
    }
  }

  &.text {
    color: var(--text);

    &:hover {
      color: var(--accent-d10);
      border-color: var(--accent-l35);
      background: var(--accent-l35);
    }
  }

  &.text-accent {
    color: var(--accent);
  }

  &.text-accent-bold {
    font-weight: var(--semi-bold);
    color: var(--accent);
  }

  &.green {
    border-color: var(--green);
    background: var(--green);

    .icon svg * {
      stroke: var(--white);
    }

    &:hover {
      border-color: var(--green-d10);
      background: var(--green-d10);
    }
  }

  &.red {
    border-color: var(--red);
    background: var(--red);

    .icon svg * {
      stroke: var(--white);
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      border-color: var(--red-d10);
      background: var(--red-d10);
    }
  }

  &.red-outline {
    color: var(--red);
    border-color: var(--red);

    .icon svg * {
      stroke: var(--red);
    }

    &:hover {
      color: var(--white);
      border-color: var(--red-d10);
      background: var(--red-d10);

      .icon svg * {
        stroke: var(--white);
      }
    }
  }

  &.docs {
    color: var(--accent-d30);
    border-color: var(--accent-l35);
    background: var(--accent-l35);
    padding: 0 0.75rem 0 0.625rem;
    // display: none; // hide Docs button for initial release

    .icon svg * {
      stroke: var(--accent-d30);
    }
  }

  &.disabled {
    pointer-events: none;
  }

  &.accent.disabled,
  &.green.disabled,
  &.red.disabled,
  &.docs.disabled {
    color: var(--text-a40) !important;
    border-color: var(--bg-d5) !important;
    background: var(--bg-d5) !important;
    transition: none !important;
    pointer-events: none;
  }

  &.outline.disabled,
  &.red-outline.disables {
    color: var(--text-a40) !important;
    border-color: var(--bg-d5) !important;

    .icon svg * {
      stroke: var(--text-a40) !important;
    }
  }

  &.text.disabled,
  &.text-accent.disabled,
  &.text-accent-bold.disabled {
    color: var(--text-a40) !important;
  }
}

.hide {
  display: none;
}

.icon {
  margin: 0 0.375rem 0 0;
  .onlyIcon & {
    margin: 0;
  }
}

.sizeSmall {
  height: 1.75rem;
}
