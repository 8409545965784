@import "app/assets/stylesheets/_mixins.scss";

:global {
  .slider {
    height: 2rem;
    padding: 0 0.25rem;

    display: flex;

    align-items: center;

    .rc-slider-dot {
      display: none;
    }

    .rc-slider-mark {
      margin-top: 8px;
    }

    .labelSlider {
      height: 2rem;
      font-size: var(--font-sm);
      font-weight: var(--regular);
      line-height: 1.25rem;
      color: var(--text);
      margin: 0 0 0.5rem 0;
    }

    .hint {
      @include inputHint;
    }
  }

  .rc-slider-handle {
    border: 2px solid var(--accent);
  }

  .rc-slider-handle:hover {
    border-color: var(--accent-d10);
  }

  .rc-slider-handle-dragging {
    border-color: var(--accent-d30) !important;
    box-shadow: 0 0 5px var(--accent) !important;
  }
}
