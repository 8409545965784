@import "app/assets/stylesheets/_mixins.scss";
@import "app/assets/stylesheets/_mixinsSettings.scss";

.autoresponder {
  @include settingsSDS;
}

.wrapInfoBtn {
  @include wrapInfoBtnSDS;
}

.warnignMsg {
  @include warnignMsgSDS;
}

.infoBtnGroup {
  @include infoBtnGroupSDS;
}

.settings {
  @include sectionsSDS;
}

.wrapDatePicker {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.labelDatePikcer {
  width: 100%;
  height: 1.75rem;
  grid-area: label;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text);

  .withErrors & {
    color: var(--red);
  }
}

.datePicker {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: end;
  & > div:first-child {
    padding: 0 1.5rem 0 0;
  }
}
