@import "app/assets/stylesheets/_mixins.scss";

// style used for details sections strucutre like domains/Show

@mixin settingsSDS {
  justify-self: center;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: "sectionsSDS" "wrapInfoBtnSDS";

  @include media-sm {
    padding: 0;
    grid-template-rows: 3rem minmax(0px, 1fr);
    grid-template-areas: "wrapInfoBtnSDS" "sectionsSDS";
    justify-items: center;
  }
}

@mixin wrapInfoBtnSDS {
  grid-area: wrapInfoBtnSDS;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto calc(4rem + #{var(--safe-bottom)});
  grid-template-areas: "warnignMsgSDS" "infoBtnGroupSDS";

  @include media-sm {
    width: calc(100% - 2rem);
    padding: 0;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    grid-template-areas: "warnignMsgSDS infoBtnGroupSDS";
    align-items: center;
  }

  @include media-xxl {
    width: calc(100% - 8rem);
  }
}

@mixin warnignMsgSDS {
  grid-area: warnignMsgSDS;
  padding: 0 1rem;

  @include media-sm {
    justify-self: end;
    padding: 0;
    margin: 0 1rem 0 0;
  }
}

@mixin infoBtnGroupSDS {
  grid-area: infoBtnGroupSDS;
  padding: 0.75rem 1rem;
  background: var(--bg);
  border-top: 1px solid var(--bg-d5);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  button {
    min-width: calc(50% - 0.5rem);
  }

  @include media-sm {
    background: none;
    border: none;
    justify-content: flex-end;
    padding: 0;
    button {
      min-width: 6rem;
    }
    button:last-of-type {
      margin: 0 0 0 1rem;
    }
  }
}

@mixin docsBtnSDS {
  grid-area: docsBtnSDS;
  @include media-sm {
    justify-self: end;
  }
}

@mixin saveBtnDSD {
  grid-area: saveBtnDSD;
  @include media-sm {
    justify-self: end;
  }
}

@mixin sectionsSDS {
  grid-area: sectionsSDS;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include overflowY;

  @include media-sm {
    padding: 0;
  }
}
