.loader {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
}

.wrapLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .inLine & {
    flex-direction: row;
  }
}

.msg {
  font-size: var(--font-sm);
  line-height: 1rem;
  color: var(--text);
  margin: 1rem 0 0.25rem 0;
  .inLine & {
    margin: 0 0 0 0.5rem;
  }
}
