@import "app/assets/stylesheets/_mixins.scss";

:global {
  .tippy-tooltip.qbox-theme {
    /* Your styling here. Example: */
    font-family: var(--font-family) !important;
    font-size: var(--font-xs) !important;
    font-weight: var(--regular) !important;
    line-height: 1.5 !important;
    border-radius: 2px !important;
    padding: 0 0.5rem !important;
  }

  .tippy-backdrop {
    border-radius: 0 !important;
  }

  .tooltip-title {
    color: var(--white) !important;
    font-size: var(--font-xs);
  }
}
