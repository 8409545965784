@import "app/assets/stylesheets/_mixins.scss";

.tabBar {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem minmax(0px, 1fr);
  grid-template-areas: "wrapScroll" "content";

  @include media-sm {
    grid-template-rows: 2.5rem minmax(0px, 1fr);
  }
}

.wrapScroll {
  grid-area: wrapScroll;
  overflow-x: auto;
  overflow-x: overlay;
  -webkit-overflow-scrolling: touch;
  margin: 0 1rem;
  @include media-sm {
    overflow-x: inherit;
  }

  @include media-xxl {
    margin: 0 4rem;
  }
}

.wrapItems {
  height: 3rem;
  border-bottom: 1px solid var(--bg-d5);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-sm {
    height: 2.5rem;
    justify-content: flex-start;
  }
}

.item {
  flex: 1;
  height: 3rem;
  font-size: var(--font-base);
  line-height: 3rem;
  text-align: center;
  white-space: nowrap;
  color: var(--text);
  border-bottom: 2px solid transparent;
  box-sizing: border-box;
  padding: 0 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @include media-sm {
    flex: initial;
    height: 2.5rem;
    font-size: var(--font-sm);
    line-height: 2.5rem;
    text-align: left;
    padding: 0;
    margin: 0 1rem 0 0;
    &:hover {
      color: var(--accent);
    }
  }

  &.active {
    color: var(--accent);
    border-bottom-color: var(--accent);
  }
}

.content {
  grid-area: content;
  justify-self: center;
  width: 100%;
  margin: 0 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(0px, 1fr);
  justify-content: center;
  @include overflowY;

  @include media-sm {
    margin: 0;
    overflow-y: inherit;
  }
}
