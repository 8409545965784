@import "app/assets/stylesheets/_mixins.scss";

.navbar {
  width: 100%;
  height: 3rem;
  background: var(--white);
  box-shadow: var(--shadow-dp4-nav);
  padding: 0 1rem 0 0;
  display: grid;
  grid-template-columns: 3rem 1fr auto;
  grid-template-areas: "iconMenu app user";
  align-items: center;
  position: relative;
  z-index: var(--z-index-4);

  @include media-md {
    grid-template-columns: 3rem minmax(7.5rem, auto) 1fr auto auto auto;
    grid-template-areas: "iconMenu logo app mtm prices user";
  }
}

.iconMenu {
  grid-area: iconMenu;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  grid-area: logo;
  display: none;
  @include media-md {
    //height: 1.5rem;
    display: flex;
    justify-content: flex-start;
    img {
      max-width: 120px;
      max-height: 1.5rem;
    }
  }
}

.mtm {
  display: none;
  margin-right: 1rem;
  cursor: pointer;

  @include media-md {
    display: block;
    grid-area: mtm;
  }
}

.prices {
  display: none;
  margin-right: 1rem;
  height: 2rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  @include media-md {
    grid-area: prices;
    display: flex;
  }
}

.user {
  grid-area: user;
  height: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pricesIcon {
  margin-right: 20px;
}

.trialCountdownBanner {
  display: none;

  &:hover {
    cursor: pointer;
  }

  @include media-md {
    display: block;
    width: max-content;
    justify-self: flex-end;
    margin-right: 20px;
  }
}
.appSwitchContainer {
  grid-area: app;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.appSwitchSeparator {
  height: auto;
  width: 1px;
  background-color: var(--bg-d15);
}

.appSwitchButton {
  margin: 0px;
  color: var(--text);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: var(--font-sm);
}

.appSwitchButtonSelected {
  font-weight: var(--semi-bold);
  color: var(--accent);
}

.trialCountdownBanner {
  display: none;

  &:hover {
    cursor: pointer;
  }

  @include media-md {
    display: block;
    width: max-content;
    justify-self: flex-end;
    margin-right: 20px;
  }
}
.appSwitchContainer {
  grid-area: app;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.appSwitchSeparator {
  height: auto;
  width: 1px;
  background-color: var(--bg-d15);
}

.appSwitchButton {
  @extend .userName !optional;
  margin: 0px;
  color: var(--text);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: var(--font-sm);
}

.appSwitchButtonSelected {
  font-weight: var(--semi-bold);
  color: var(--accent);
}
