@import "app/assets/stylesheets/_mixins.scss";

.support {
  padding: 1rem 0 0 0 !important;

  @include media-sm {
    padding: 3rem 0 0 0 !important;
  }
}

.wrapBtn {
  justify-self: end;
}

.importBox {
  border-radius: 0.25rem;
  background: var(--green-l35);
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  display: grid;
  grid-template-columns: [iconImport]3rem [textImport]1fr;
  grid-column-gap: 0.75rem;
  align-items: start;
}

.iconImport {
  grid-column: iconImport;

  svg * {
    fill: var(--green);
    opacity: 0.5;
  }
}

.textImport {
  grid-column: textImport;
  align-self: center;
  font-size: var(--font-sm);
  @include codeAndBold;
}
