@import "app/assets/stylesheets/_mixins.scss";

// style used for firstView structure like archive/FirstView

@mixin wrapFV {
  justify-self: center;

  padding: 1rem 1rem 0 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: [titleFv]2rem [descFv]auto [infoFv]auto [btnCreateFV]4rem;
  align-content: flex-start;

  @include media-sm {
    width: 30rem;
    padding: 3rem 0 0 0;
  }
}

@mixin titleFV {
  grid-row: titleFv;
}

@mixin descFv {
  grid-row: descFv;
}

@mixin infoFv {
  grid-row: infoFv;
  padding: 1rem 0 0 0;
  & > div:first-child {
    margin: 0 0 1rem 0;
  }
}

@mixin warningFV {
  grid-row: warningFV;
  justify-self: start;
  align-self: center;
  padding: 1rem 0;
}

@mixin btnCreateFV {
  grid-row: btnCreateFV;
  align-self: end;
  button {
    min-width: 100%;
  }

  @include media-sm {
    button {
      min-width: 5rem;
    }
  }
}
