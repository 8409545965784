@import "app/assets/stylesheets/_mixins.scss";

// @import '../input/input.scss';

.multipleSelector {
  width: 100%;
  display: grid;
  grid-column: 1fr;
  grid-template-rows: 1.5rem 3rem auto auto;
  grid-template-areas: "label" "input" "wrapPanel" "errors";
}

.label {
  grid-area: label;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text);
}

.input {
  grid-area: input;
  width: 100%;
  height: 2rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--text);
  line-height: 2rem;
  border: 1px solid var(--input-border);
  border-radius: 0.25rem;
  padding: 0 0.375rem;
  box-sizing: border-box;
  box-shadow: none !important;
  appearance: none;
  transition: border 0.05s ease-in-out;
  &:hover {
    border: 1px solid var(--input-border-hover);
  }
  &:focus {
    border: 1px solid var(--accent);
    outline: none !important;
  }
  &::placeholder {
    font-weight: var(--regular);
    color: var(--text-a40);
  }
}

.wrapPanel {
  grid-area: wrapPanel;
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  grid-template-rows: 1.5rem auto;
  grid-template-areas: "headerLeft icon headerRight" "listLeft icon listRight";
}

.headerLeft,
.headerRight {
  font-size: var(--font-xs);
  font-weight: var(--semi-bold);
  line-height: 1.5rem;
  color: var(--text);
  border: 1px solid var(--bg-d5);
  border-radius: 0.25rem 0.25rem 0 0;
  box-sizing: border-box;
  background: var(--bg);
  padding: 0 0.5rem;
}

.headerLeft {
  grid-area: headerLeft;
}

.headerRight {
  grid-area: headerRight;
}

.listLeft {
  grid-area: listLeft;
  display: flex;
  flex-direction: column;
}

.listRight {
  grid-area: listRight;

  display: flex;
  flex-direction: column;
}

.listItem {
  min-height: 2rem;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  color: var(--text);
  line-height: 1.125rem;
  word-break: break-all;
  border: 1px solid var(--bg-d5);
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
  box-sizing: border-box;
  padding: 0.4375rem 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: var(--bg-hover);
  }
}

.icon {
  grid-area: icon;
  justify-self: center;
  align-self: center;
}

.errors {
  grid-area: errors;
}
