@import "app/assets/stylesheets/_mixins.scss";

// style used for main sections strucutre like domains/listDomains

@mixin listMSS {
  padding: 1rem 0;
  margin: 0 0 var(--safe-bottom) 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto minmax(0px, 1fr);
  grid-template-areas: "headerList" "contentList";

  // @include media-md {
  //   align-self: start;
  // }

  @include media-lg {
    grid-template-rows: 5rem minmax(0px, 1fr);
  }
}

@mixin headerListMSS {
  grid-area: headerList;
  padding: 0 1rem 0.5rem 1rem;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 2.5rem;
  grid-template-areas: "titleList actionsList";
  align-items: center;
  @include media-sm {
    grid-template-columns: 25rem 1fr;
    grid-template-rows: 2.5rem auto;
    grid-template-areas: "titleList actionsList" "descList .";
  }

  @include media-md {
    grid-template-columns: 28rem 1fr;
    grid-template-rows: 2rem auto;
  }

  @include media-lg {
    grid-template-columns: 30rem 1fr;
  }

  @include media-xxl {
    padding: 0 4rem;
  }
}

@mixin titleListMSS {
  grid-area: titleList;
}

@mixin descListMSS {
  grid-area: descList;
  align-self: start;
  @include media-md {
    min-height: 2.5rem;
  }

  @include media-lg {
    padding: 0;
  }
}

@mixin actionsListMSS {
  grid-area: actionsList;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button:nth-child(even) {
    margin: 0 0 0 1rem;
  }
}

@mixin contentList {
  grid-area: contentList;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto minmax(0px, 1fr);
  grid-template-areas: "filters" "table";
  @include overflowY;

  @include media-sm {
    grid-template-rows: 4rem minmax(0px, 1fr);
    overflow-y: hidden;
  }

  @include media-lg {
    grid-template-rows: 4rem minmax(0px, 1fr);
  }
}

@mixin filtersMSS {
  grid-area: filters;
  padding: 1rem;

  @include media-xxl {
    padding: 0 4rem;
  }
}

@mixin tableMSS {
  grid-area: table;
  align-content: flex-start;
  padding: 0 1rem;

  @include media-sm {
    @include overflowY;
  }

  @include media-xxl {
    padding: 0 4rem;
  }
}

@mixin tableCardMSS {
  grid-area: table;
  align-content: flex-start;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  @include media-sm {
    grid-template-columns: repeat(2, 1fr);
    @include overflowY;
  }

  @include media-lg {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-xl {
    grid-template-columns: repeat(4, 1fr);
  }

  @include media-xxl {
    padding: 0 4rem;
  }
}

@mixin loaderMSS {
  grid-row: table;
}

@mixin noFoundMsg {
  grid-row: table;
  display: flex;
  justify-content: center;
  align-items: center;
}
