@import "app/assets/stylesheets/_mixins.scss";

.dayPickerContainer {
  @include inputContainer;
}

.wrapLabel {
  @include inputWrapLabel;
}

.label {
  @include inputLabel;
}

.wrapDatePicker {
  position: relative;
  input {
    @include input;
    padding: 0 1.7rem 0 0.375rem;
  }
}

.icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  opacity: 0.5;

  .disabled & {
    opacity: 0.25;
  }
}

.errorMsg {
  @include inputErrorMsg;
}
